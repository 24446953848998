import React, { useMemo } from 'react'
import { useLocale } from '../../locales'
import Seo from '../../components/Head/Seo'

import Banner from './components/Banner'
import Article from '../../components/Article/Index'
import Breadcrumb from '../../components/Breadcrumb/Index'
import Divider from '../../components/Divider/Index'
import SwiperInstance from 'components/Swiper/Index'

import Gird from '../Innovation/components/Grid'

import classes from './PeopleFirst.module.scss'

function Index() {
  const locale = useLocale()
  const page = useMemo(
    () => locale.pages.ESG.humanity,
    [locale.pages.ESG.humanity]
  )
  return (
    <div className={classes.Index}>
      <Seo seo={locale.pages.ESG.humanity.seo} />
      <Banner />
      <Breadcrumb
        title={page.breadcrumb}
        className={classes.breadcrumb}
      />
      <div className={classes.sectionA}>
        <Article title={page.humanity.title}>
          {page.humanity.content}
        </Article>
        <Divider className={classes.divider} />
        <Article
          title={page.employees.title}
          containerClassName={classes.article}
        >
          <p className={classes.content}>
            {page.employees.content}
          </p>
          <Gird
            data={page.employees.grid}
            titleClassName={classes['care-grid-title']}
          />
        </Article>
        <Divider className={classes.divider} />
        <strong className={classes.title}>{page.health.title}</strong>
      </div>
      <div className={classes.sectionB}>
        <div className={classes.box}>
          <Article
            title={page.health.article.title}
            images={[page.health.article.image]}
            imageClassName={classes.image}
          >
            {page.health.article.content}
          </Article>
        </div>
      </div>
      <div className={classes.sectionA}>
        <Article
          title={page.care.title}
          containerClassName={classes.article}
        >
          <p className={classes.content}>
            {page.care.content}
          </p>
          <Gird
            data={page.care.grid}
            titleClassName={classes['care-grid-title']}
            contentClassName={classes['care-grid-content']}
          />
        </Article>
        <strong className={classes.title}>{page.build.title}</strong>
        <SwiperInstance onChange={() => { }}>
          {
            page.build.images.map(item => (
              <SwiperInstance.Item key={item.value}>
                <img className={classes.build} src={item.value} alt='' />
              </SwiperInstance.Item>
            ))
          }

        </SwiperInstance>
        <Divider className={classes.divider} />
      </div>
    </div>
  )
}

export default Index
