import React, {useMemo} from 'react'
import {useLocale} from '../../locales'
import Banner from './components/Banner'
import Breadcrumb from 'components/Breadcrumb/Index'
import About from './components/About'
import Section from '../../components/Section/Index'
import Seo from '../../components/Head/Seo'

import classes from './Global.module.scss'

interface RegionProps {
  areaName: string
  cities: string[]
}

function RegionItem(props: RegionProps) {
  const {code} = useLocale()
  const {areaName, cities} = props
  const separator = useMemo(() => code === 'zh-cn' ? '、 ' : ', ', [code])
  const text = useMemo(
    () => {
      return `${areaName}: ${cities.join(separator)}`
    },
    [
      cities,
      areaName,
      separator,
    ]
  )

  return (
    <div>
      <div key={areaName} className={classes.regionItem}>
        { text }
      </div>
    </div>
  )
}

export default function Global() {
  const locale = useLocale()
  const global = useMemo(
    () => locale.pages.Business.Global,
    [locale.pages.Business.Global]
  )

  return (
    <div className={classes.Global}>
      <Seo seo={global.seo} />
      <Banner
        {...global.banner}
        background='var(--theme-gradient)'
      />
      <Breadcrumb title={global.breadcrumb}/>
      <About data={global.about as any}/>
      <Section name={global.process.sectionName}>
        <div className={classes.global}>
          <img src={global.process.cover} alt={global.process.sectionName}/>
        </div>
      </Section>
      <Section name={global.regions.sectionName} className={classes.Section}>
        <div className={classes.main}>
          <div className={classes.info}>
            {
              global.regions.areas.map((area) => (
                <RegionItem {...area}/>
              ))
            }
          </div>
          <img
            src={global.regions.cover}
            alt={global.regions.sectionName}
            className={classes.cover}
          />
        </div>
      </Section>
    </div>
  )
}
