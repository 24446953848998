import React from 'react'
import classNames from 'classnames'

import classes from './Index.module.scss'

interface DividerProps {
  className?: string
}

function Index(props: DividerProps) {
  const { className } = props
  return (
    <div className={classNames(classes.divider, className)} />
  )
}

export default Index