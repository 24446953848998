import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import classes from './Tabs.module.scss'
import { isSmall, scrollIntoView } from '../../../utils/view'
import Icon from 'components/Icon/Index'

interface TabItemProps {
  children: ReactNode
  title?: string
  code?: string
}

function TabItem(props: TabItemProps) {
  const {
    children,
    title: _title,
    code: _code,
  } = props
  return (
    <div>
      {children}
    </div>
  )
}

type Children = ReturnType<typeof TabItem>

interface TabsProps {
  defaultActiveKey?: string
  children?: Children | Array<Children>
}

function Tabs(props: TabsProps) {
  const { defaultActiveKey } = props
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined)
  const children = useMemo(() => {
    if (Array.isArray(props.children)) return props.children
    return [props.children]
  }, [props.children])

  const availableChildren = useMemo(() => {
    return children.filter((child) => child?.type === TabItem)
  }, [children])

  const tabs = useMemo(
    () => {
      if (!availableChildren) return []
      return availableChildren.map(ele => ({
        name: ele!.props!.title,
        key: ele!.props!.code!
      }))
    },
    [availableChildren]
  )
  useEffect(
    () => {
      if (defaultActiveKey) setActiveKey(defaultActiveKey)
    },
    [defaultActiveKey]
  )

  const onChange = useCallback(
    (eventKey: string) => {
      if (eventKey === activeKey) {
        if (isSmall()) {
          setActiveKey(undefined)
        }
        return
      }
      if (isSmall()) scrollIntoView(`tabs_${eventKey}`)
      setActiveKey(eventKey)
    },
    [activeKey]
  )

  return (
    <div className={classes.tabContainer}>
      <div className='hidden-small'>
        <div className={classes.tabs}>
          {
            tabs.map(ele => (
              <div
                className={classNames(
                  classes.tabItem,
                  { [classes.active]: ele.key === activeKey }
                )}
                onClick={() => onChange(ele.key)}
                key={ele.key}
              >
                <div
                  dangerouslySetInnerHTML={
                    { __html: ele.name }
                  }
                />
              </div>
            ))
          }
        </div>
        <div className={classes.main}>
          <div className={classes.box}>
            {
              availableChildren.map((ele, i) => (
                <div className={classNames(
                  classes.tabPanel,
                  { [classes.active]: tabs[i].key === activeKey }
                )}
                >
                  {ele}
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className='hidden-large'>
        {
          tabs.map((ele, index) => (
            <div key={ele.key}>
              <div
                className={classNames(
                  classes.tabItem,
                  { [classes.active]: ele.key === activeKey }
                )}
                id={`tabs_${ele.key}`}
                onClick={() => onChange(ele.key)}
              >
                <div
                  dangerouslySetInnerHTML={
                    { __html: ele.name }
                  }
                />
                <div className={classes.icon}>
                  <Icon icon={ele.key === activeKey ? 'Reduce' : 'Add'} scale={0.8} />
                </div>
              </div>
              {
                ele.key === activeKey
                  ? (
                    <div className={classes.main}>
                      <div className={classes.box}>
                        <div className={classes.tabPanel}>
                          {availableChildren[index]}
                        </div>
                      </div>
                    </div>
                  )
                  : null
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

Tabs.Item = TabItem

export default Tabs
