import React from 'react'
import classNames from 'classnames'

import classes from './IconItems.module.scss'

interface Item {
  name: string
  desc?: string
  image: string
}

interface ServiceItemsProps {
  className?: string
  data: Item[]
  iconClassName?: string
  itemClassName?: string
  titleClassName?: string
  descClassName?: string
}

export default function ServiceItems(props: ServiceItemsProps) {
  const {
    className,
    data,
    iconClassName,
    itemClassName,
    titleClassName,
    descClassName,
  } = props
  return (
    <div
      className={
        classNames(classes.items, className)
      }
    >
      {
        data.map(item => (
          <div
            key={item.name}
            className={classNames(classes.item, itemClassName)}
          >
            <div className={classNames(classes.icon, iconClassName)}>
              <img src={item.image} alt='' />
            </div>
            <div className={classes.right}>
              <strong className={titleClassName}>{item.name}</strong>
              <p className={descClassName}>{item.desc}</p>
            </div>
          </div>
        ))
      }
    </div>
  )
}
