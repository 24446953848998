import React, {useMemo} from 'react'
import {useLocale} from '../../locales'
import Banner from './components/Banner'
import About from './components/About'
import Steps from './components/Ahs/Steps'
import Block from './components/Ahs/Block'
import Channel from './components/Ahs/Channel'
import Breadcrumb from 'components/Breadcrumb/Index'
import Seo from '../../components/Head/Seo'

import classes from './Ahs.module.scss'

export default function Ahs() {
  const locale = useLocale()
  const ahs = useMemo(
    () => locale.pages.Business.Ahs,
    [locale.pages.Business.Ahs]
  )

  return (
    <div className={classes.Ahs}>
      <Seo seo={ahs.seo} />
      <Banner
        logo={ahs.banner.logo}
        name={ahs.banner.name}
        position={ahs.banner.position}
        introduction={ahs.banner.introduction}
        background='linear-gradient(180deg, #FDF557 0%, #F9E52C 100%)'
      />
      <Breadcrumb title={ahs.breadcrumb} />
      <About data={ahs.about as any} />
      <Steps />
      <Block />
      <Channel />
    </div>
  )
}
