import React from 'react'

import { useLocale } from '../../../locales'

import classes from './Banner.module.scss'

export default function Banner() {
  const locale = useLocale()

  return (
    <div className={classes.Banner}>
      <img src={locale.pages.ESG.environment.banner} alt='' />
    </div>
  )
}
