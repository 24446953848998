import React, {useMemo} from 'react'
import {useParams} from 'react-router'
import Breadcrumb from '../../components/Breadcrumb/Index'
import {useLocale} from '../../locales'

import classes from './BlogDetail.module.scss'
import Seo from '../../components/Head/Seo'

interface Section {
  title: string
  list: Array<{
    subTitle: string
    text: string
  }>
}

interface Article {
  banner: string
  articleTitle: string
  articleTips: string
  sections: Array<Section>
}

export default function BlogDetail() {
  const {articleId} = useParams<{ articleId: string }>()

  const locale = useLocale()
  const blog = useMemo(
    () => locale.pages.Blog,
    [locale.pages.Blog]
  )

  const articles = useMemo(
    () => blog.articles,
    [blog]
  )


  const article: Article | null = useMemo(
    // @ts-ignore
    () => articles[articleId] || null,
    [articles, articleId]
  )

  if (!article) return null
  return (
    <div className={classes.BlogDetail}>
      <Seo seo={
        {
          title: article.articleTitle,
          description: blog.seo.description,
          keywords: blog.seo.keywords,
        }
      } />
      <Breadcrumb title={blog.breadcrumb}/>
      <div className={classes.main}>
        <div className={classes.pageTitle}>
          {article.articleTitle}
        </div>
        <div className={classes.pageTips}>
          {article.articleTips}
        </div>
        <div className={classes.sections}>
          {
            article.sections.map((ele) => (
              <div className={classes.section}>
                <div className={classes.sectionTitle}>
                  {ele.title}
                </div>
                <div className={classes.sectionMain}>
                  {
                    ele.list.map(item => (
                      <div className={classes.item}>
                        {
                          item.subTitle
                            ? (
                              <div 
                                className={classes.subTitle} 
                                dangerouslySetInnerHTML={
                                  {__html: item.subTitle}
                                }
                              >
                              </div>
                            )
                            : null
                        }
                        <div
                          className={classes.text}
                          dangerouslySetInnerHTML={
                            {__html: item.text}
                          }
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
