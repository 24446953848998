import React, { useEffect, useState } from 'react'


function NotFound() {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [])

  return (
    ready
      ?
      (
        <div >
          Page NotFound
        </div>
      )
      : null
  )
}

export default NotFound