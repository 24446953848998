import React, { Fragment, useMemo } from 'react'
import { useLocale } from '../../locales'

import Seo from '../../components/Head/Seo'
import Article from '../../components/Article/Index'
import Breadcrumb from '../../components/Breadcrumb/Index'
import Divider from '../../components/Divider/Index'
import Units from './components/Units'
import Grid from '../Innovation/components/Grid'
import Banner from './components/Banner'

import classes from './Environment.module.scss'

function Index() {
  const locale = useLocale()
  const page = useMemo(
    () => locale.pages.ESG.environment,
    [locale.pages.ESG.environment]
  )
  return (
    <div className={classes.Index}>
      <Seo seo={locale.pages.ESG.environment.seo} />
      <Banner />
      <Breadcrumb
        title={page.breadcrumb}
        className={classes.breadcrumb}
      />
      <div className={classes.section}>
        {
          page.paragraphs.map(paragraph => (
            <Fragment key={paragraph.title}>
              <Article
                title={paragraph.title}
                containerClassName={classes.article}
              >
                { paragraph.content.map((item, index) => (
                  <p key={item}>
                    {
                      paragraph.content.length > 1 && index === 0 
                        ? <strong>{item}</strong>
                        : item
                    }
                  </p>
                )) }
                {
                  paragraph.subTitle 
                    ? (
                      <p className={classes.subTitle}>
                        <strong>{paragraph.subTitle}</strong>
                      </p>
                    )
                    : null
                }
                {paragraph.units?.length
                ?(
                  <div className={classes.units}>
                    <Units data={paragraph.units} />
                  </div>
                )
                :null}
              </Article>
              <Divider className={classes.divider} />
            </Fragment>
            
          ))
        }
        <Article
          title={page.operation.title}
          containerClassName={classes.operation}
        >
          <p>{page.operation.content}</p>
          <Grid data={page.operation.grid} />
        </Article>
      </div>
    </div>
  )
}

export default Index
