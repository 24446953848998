import { useContext } from 'react'
/// <reference path="../typings/global.d.ts" />
import { createContext } from 'react'

import zhCN from './zh-cn'
import enUS from './en-us'

export type LocaleConfig = ReturnType<typeof zhCN>

const defaultValue: {
  locales: Array<{
    label: string
    code: Locale
  }>
  locale: Locale
  localeConfig: LocaleConfig
  switchLocale: (locale: Locale) => void
} = {
  locales: [],
  locale: window.LANGUAGE,
  localeConfig: enUS(),
  switchLocale: () => { }
}

export const LocaleContext = createContext(defaultValue)

const LocaleProvider = LocaleContext.Provider

export function useLocaler() {
  return useContext(LocaleContext)
}

export function useLocale() {
  const locale = useContext(LocaleContext)
  return locale.localeConfig
}

export default LocaleProvider