import { ossImg } from "utils/oss"
import { getValue } from '../../../utils/view'

const CN = () => ({
  seo: {
    title: 'ESG概览 - 万物新生',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网',
    description: '万物新生ESG管理体系及ESG概况,将ESG与商业模式及治理体系融合.',
  },
  banner: ossImg('/esg/overall-banner.png'),
  breadcrumb: '首页 / ESG / ESG概览',
  about: {
    sectionName: '万物新生集团ESG概况',
    sectionBadge: [{
      image: require('assets/Endorser.jpg'),
      title: 'United Nations Global Compact'
    }, {
      image: require('assets/sustainalytics-badge.png'),
      title: 'Sustainalytics Badge'
    }],
    links: [{
      name: '万物新生2023年环境、社会、管治报告',
      url: ossImg('/downloads/%E4%B8%87%E7%89%A9%E6%96%B0%E7%94%9F2023%E5%B9%B4%E5%BA%A6%E7%8E%AF%E5%A2%83%E3%80%81%E7%A4%BE%E4%BC%9A%E5%8F%8A%E7%AE%A1%E6%B2%BB%E6%8A%A5%E5%91%8A.pdf')
    }, {
      name: '万物新生2022年环境、社会、管治报告',
      url: ossImg('/downloads/2022_ESG_Report_CN.pdf')
    }, {
      name: '万物新生2021年环境、社会、管治报告',
      url: ossImg('/downloads/2021_ESG_Report_CN_2.pdf')
    }, {
      name: '万物新生2020年环境、社会、管治报告',
      url: ossImg('/downloads/%E4%B8%87%E7%89%A9%E6%96%B0%E7%94%9F2020%E5%B9%B4%E5%BA%A6%E7%8E%AF%E5%A2%83%E3%80%81%E7%A4%BE%E4%BC%9A%E5%8F%8A%E7%AE%A1%E6%B2%BB%E6%8A%A5%E5%91%8A.pdf')
    }],
    sections: [
      {
        subTitle: '',
        description: '万物新生通过二手电子产品回收领域C2B+B2B+B2C全产业链的完整闭环，将自身业务模式与循环经济完美融合。集团同时将ESG与企业内在的商业模式融合，融入企业管理的各个方面，立足自身业务和发展，不断加强与利益相关方的沟通，增强本集团在经济、社会和环境方面的影响，积极探索可持续发展的道路，致力于成为“中国科技公司的ESG第一股”。'
      },
      {
        subTitle: '',
        description: '万物新生自2023年加入联合国全球契约组织（United Nations Global Compact），承诺支持企业责任倡议及关于人权、劳工、环境和反腐败四个领域的原则。',
      },
      {
        subTitle: '',
        description: '2022年，万物新生获得 Sustainalytics ESG Risk Rating 17.6/100分 “低风险”评级（分数越低，代表表现越好），在全球“在线和直接营销零售”板块的公司中位列第4名（4/85）。'
      },
      {
        subTitle: '',
        description: '2021年度ESG报告首次引入气候相关财务信息披露工作组（TCFD）框架，即金融稳定理事会（FSB）在回应二十国集团（G20）气候变化对全球金融市场稳定性的影响时所提出的建议，从管治、策略、风险管理、指标与目标四个方面评估了在公司运营与二手3C回收再利用中面临的气候变化风险与机遇。报告同时加强在环境绩效方面的披露，范畴三的碳排放增加4项指标：资本货物、运营废弃物、员工通勤、售出产品末端处置排放，更主动充分地评估了集团运营对环境的影响。'
      },
    ],
  },
  system: {
    sectionName: 'ESG管理体系',
    description: '万物新生集团设立了三级的ESG管理委员会架构，在董事会下，专项成立了 ESG委员会，负责推进 ESG 管理体系建设工作，履行相关的顶层决策职能，监督落实 ESG 战略及优化目标的落实工作。',
    cover: getValue({
      small: ossImg('/esg/overall/manage-m-cn.png'),
      large: ossImg('/esg/overall/manage-cn.png'),
    }),
    section: {
      title: '基于本集团的业务和管理现状、战略发展目标和外部第三方最佳实践，现阶段我们将侧重于开展以下领域的 ESG 管理工作：',
      list: [
        '1. 环境方面：气候变化管理、循环利用；',
        '2. 社会方面：行业引领、产品治理、员工发展、社会公益；',
        '3. 企业治理：顶层治理、信息安全、隐私保护。',
      ],
      footer: [
        {
          content: [
            '万物新生集团意识到与其营运相关的环境影响，致力于通过管理其自身运营与影响产业链的参与者以共建可持续发展的市场环境，助力我国实现“碳达峰、碳中和”目标。为此，万物新生集团制定《环境政策》，并于2022年11月21日经董事会审批通过后正式实施。'
          ],
        },
        {
          content: [
            '万物新生遵守《中华人民共和国反不正当竞争法》《防止贿赂条例》及运营地与上市地区的法律法规要求，高度重视廉洁合规工作，制定了《万物新生集团廉洁自律准则》《万物新生集团举报人保护与奖励制度》等，加强运营过程的规范化和制度化，杜绝商业腐败、贿赂行为，对贪腐问题采取零容忍态度，确保公司合规健康发展。'
          ],
        },
      ]
    }
  },
  communication: {
    sectionName: '利益相关方沟通',
    description: '万物新生高度重视利益相关方的沟通工作，本集团持续加强与消费者、平台商家、 员工、股东 / 投资者、政府及监管机构、社区和公众、供应商和合作伙伴等广泛利益相关方的沟通与交流。本集团通过建立多样化的沟通方式，及时有效的了解各方的意见和期望，以融入本集团的运营和管治中，力求多方共同的可持续发展。',
    covers: [
      ossImg('/esg/overall/communication-1.png'),
      ossImg('/esg/overall/communication-2.png'),
      ossImg('/esg/overall/communication-3.png'),
    ],
  },
  article: {
    sectionName: '重要性议题识别与判定',
    description: '结合GRI 标准与气候相关财务信息披露工作组（TCFD）建议，梳理参考MSCI、Sustainalytics等资本市场指数评级所关注的行业ESG重点议题，融入“产品治理”、“公司治理”等新的议题，共梳理万物新生ESG议题20项，囊括了公司管治、运营、研发、供应链、客户服务、员工等价值链环节，在全生命周期识别并抵御风险，推动可持续发展。',
    cover: ossImg('/esg/overall/issue.png'),
  }
})

const US = () => ({
  seo: {
    title: 'ESG Overview - ATRenew',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai',
    description: 'ATRenew is committed to integrating ESG into its business model and corporate management. ',
  },
  banner: ossImg('/esg/overall-banner.png'),
  breadcrumb: 'Home / ESG / ESG Overview',
  about: {
    sectionName: 'ATRenew ESG Overview',
    sectionBadge: [{
      image: require('assets/Endorser.jpg'),
      title: 'United Nations Global Compact'
    }, {
      image: require('assets/sustainalytics-badge.png'),
      title: 'Sustainalytics Badge'
    }],
    links: [{
      name: 'Download ATRenew 2023 ESG Report',
      url: ossImg('/downloads/ATRenew_2023_Environmental_Social_and_Governance_Report-vr.pdf')
    }, {
      name: 'Download ATRenew 2022 ESG Report',
      url: ossImg('/downloads/2022_ESG_Report_EN.pdf')
    }, {
      name: 'Download ATRenew 2021 ESG Report',
      url: ossImg('/downloads/2021_ESG_Report_EN_3.pdf')
    }, {
      name: 'Download ATRenew 2020 ESG Report',
      url: ossImg('/downloads/ATRenew_2020_Environmental_Social_and_Governance_Report.pdf')
    }],
    sections: [
      {
        subTitle: '',
        description: 'We aspire to integrate our operations into the circular economy through a C2B+B2B+B2C closed loop across the entire pre-owned consumer electronics recycling industrial chain. ATRenew is committed to integrating ESG into our business model and corporate management. As we strengthen our stakeholder communications and enhance our economic, social and environmental impact, we remain committed to our business and development values and actively seek sustainability opportunities as we grow and strive to become the first “ESG-related, technology-driven China Concept Stock”.'
      },
      {
        subTitle: '',
        description: 'Since 2023, ATRenew has been committed to the UN Global Compact corporate responsibility initiative and its principles in the areas of human rights, labor, environment, and anti-corruption.'
      },
      {
        subTitle: '',
        description: 'ATRenew has achieved an ESG Risk Rating from Sustainalytics in the “Low Risk” category (17.6/100, lower score is better), ranking 4th of all companies in the Online and Direct Marketing Retail sector (4/85).'
      },
      {
        subTitle: '',
        description: 'ATRenew’s 2021 ESG Report incorporates the Task Force on Climate-related Financial Disclosures (TCFD) recommendations, a framework set by the G20’s Financial Stability Board, for the first time. It explores the climate-change-related opportunities and challenges it faces in its operation and reuse of pre-owned electronic devices through the perspectives of governance, strategy, risk management, and metrics and targets. In addition, on the front of scope 3 emission, ATRenew added four indicators to improve the comprehensiveness of disclosure when measuring the climate impacts of its value chain. These indicators are capital goods, waste generated in operation, employee commuting and end-disposal emissions of sold products.',
      }
    ],
  },
  system: {
    sectionName: 'Our ESG Management Framework',
    description: 'ATRenew established a three-tier structure for ESG management. Under the Board of Directors, the ESG Committee ("ESGC") was founded to promote the construction of our ESG framework, make top-level decisions, and supervise ESG strategy implementation and optimization efforts.',
    cover: getValue({
      small: ossImg('/esg/overall/manage-m-en.png'),
      large: ossImg('/esg/overall/manage-en.png'),
    }),
    section: {
      title: 'Based on the current state of our operation and management, strategic development goals, and third-party best practices, we have identified three ESG management priorities:',
      list: [
        '1. Environmental: Climate change management, recycling and reuse',
        '2. Social: Industry leadership, product governance, employee development and social welfare',
        '3. Governance: Top-level governance, information security and privacy protection',
      ],
      footer: [{
        content: [
          'ATRenew is aware of the environmental impacts related to its operations and is committed to building a sustainable market environment by managing its operations and influencing participants in the industrial chain, contributing to the path of accomplishing “carbon peaking and carbon neutrality” goals of China. To this end, ATRenew has formulated the “Environmental Policy”. The Policy took effect after being approved by the Company’s board of directors on November 21, 2022.'
        ],
      }, {
        content: [
          'Complying with the Anti-Unfair Competition Law of the People’s Republic of China, Prevention of Bribery Ordinance, and the laws and regulations of the localities where our business is operated or based, ATRenew has formulated ATRenew Integrity and Self-discipline Guideline and ATRenew Whistleblower Protection and Reward System to standardize and institutionalize its operation process. ATRenew has adopted a zero-tolerance attitude towards bribery and corruption to ensure the Company’s compliance and healthy development.'
        ],
      }]
    }
  },
  communication: {
    sectionName: 'Communication with Stakeholders',
    description: 'We are deeply committed to stakeholder communication. We have established diverse engagement channels to strengthen dialogues with consumers, merchants, employees, shareholders/investors, governments and regulators, communities and the general public, suppliers, and partners. By integrating these mechanisms into our operation and governance, we promote shared and sustainable involvement from all of our stakeholders.',
    covers: [
      ossImg('/esg/overall/communication-1.png'),
      ossImg('/esg/overall/communication-2.png'),
      ossImg('/esg/overall/communication-3.png'),
    ],
  },
  article: {
    sectionName: 'Identification and Determination of Focus Areas',
    description: 'ATRenew incorporates the GRI Standards, recommendations of the Task Force on Climate-related Financial Disclosures (TCFD) as well as priority ESG topics of MSCI and Sustainalytics in its 2021 ESG report. The report also provides updates on 20 material ESG issues covering governance, operations, research and development, supply chain management, customer service and human resource management while including “product governance” and “corporate governance” in its portfolio of high materiality ESG issues to further address sustainable development.',
    cover: ossImg('/esg/overall/issue.png'),
  }
})

const data = {
  CN,
  US
}
export default data
