import React, {useMemo} from 'react'

import {useLocale} from 'locales'
import Section from 'components/Section/Index'
import Dots from 'components/Dots/Index'
import SwiperInstance from 'components/Swiper/Index'

import classes from './Channel.module.scss'

function Channel() {
  const locale = useLocale()
  const channel = useMemo(
    () => locale.pages.Business.Ahs.channel,
    [locale.pages.Business.Ahs.channel]
  )

  return (
    <Section.Full name={channel.sectionName} background='#F5F6FA'>
      <SwiperInstance onChange={() => {}}>
        {
          channel.list.map((ele, index) => (
            <SwiperInstance.Item>
              <div className={classes.main}>
                <div className={classes.info}>
                  <div className={classes.title}>
                    {ele.title}
                  </div>
                  <div className={classes.subTitle}>
                    {ele.subTitle}
                  </div>
                  <div className={classes.description}>
                    {ele.description}
                  </div>
                  <Dots
                    total={channel.list.length}
                    activeIndex={index}
                    className='hidden-small'
                  />
                </div>
                <img
                  className={classes.cover}
                  src={ele.cover}
                  alt={ele.title}
                />
                <Dots
                  total={channel.list.length}
                  activeIndex={index}
                  className='hidden-large'
                />
              </div>
            </SwiperInstance.Item>
          ))
        }
      </SwiperInstance>
    </Section.Full>
  )
}

export default Channel
