import Home from './pages/Home'
import BusinessAhs from './pages/Business/Ahs'
import BusinessPaipai from './pages/Business/Paipai'
import BusinessPaijitang from './pages/Business/Paijitang'
import BusinessGlobal from './pages/Business/Global'
import Innovation from './pages/Innovation/Index'
import ESGOverall from './pages/ESG/overall'
import ESGSocial from './pages/ESG/social'
import ESGHumanity from './pages/ESG/people'
import ESGEnvironment from './pages/ESG/environment'
import ESGProduct from './pages/ESG/product'
import ESGReports from './pages/ESG/reports'
import Blog from './pages/News/Blog'
import Media from './pages/News/Media'

function getLocale() {
  return {
    code: 'en-us',
    logo: require('../assets/logo-dark.png'),
    label: 'EN',
    title: 'All Things Renew',
    footer: {
      groups: [{
        title: 'Business',
        links: [{
          text: 'AHS Recycle',
          link: 'https://www.aihuishou.com/',
          bold: false,
          block: false,
          scrollTop: false,
        }, {
          text: 'PJT Marketplace',
          link: 'https://sj.aihuishou.com/',
          bold: false,
          block: false,
          scrollTop: false,
        }, {
          text: 'Paipai Marketplace',
          link: 'https://www.paipai.com/',
          bold: false,
          block: false,
          scrollTop: false,
        }, {
          text: 'AHS DEVICE',
          link: 'https://www.ahsdevice.com/',
          bold: false,
          block: false,
          scrollTop: false,
        }]
      }, {
        title: 'News',
        links: [
          {
            text: 'Corporate Blog',
            link: '/news/blog',
            bold: false,
            block: false,
            scrollTop: true,
          },
          {
            text: 'News Coverage',
            link: '/news/media',
            bold: false,
            block: false,
            scrollTop: true,
          },
          {
            text: 'Technology',
            link: '/innovation',
            bold: true,
            block: false,
            scrollTop: true,
          },
          {
            text: 'Investors',
            link: 'http://ir.atrenew.com', // TODO: http ? https ?
            bold: true,
            block: false,
            scrollTop: false,
          }
        ]
      }, {
        title: 'ESG',
        links: [{
          text: 'ESG Overview',
          link: '/esg/overall',
          bold: false,
          block: false,
          scrollTop: true,
        }, {
          text: 'Products and Services',
          link: '/esg/product',
          bold: false,
          block: false,
          scrollTop: true,
        }, {
          text: 'Green Development',
          link: '/esg/environment',
          bold: false,
          block: false,
          scrollTop: true,
        }, {
          text: 'Our People',
          link: '/esg/humanity',
          bold: false,
          block: false,
          scrollTop: true,
        }, {
          text: 'Social Responsibilities',
          link: '/esg/responsibility',
          bold: false,
          block: false,
          scrollTop: true,
        }, {
          text: 'ESG Reports and Policies',
          link: '/esg/reports',
          bold: false,
          block: false,
          scrollTop: true,
        }]
      }, {
        title: 'Contact us',
        links: [{
          text: 'Headquarter: 12th Floor, Building 6, No.433 Songhu Road, Yangpu District, Shanghai, China',
          block: true,
          bold: false,
          link: '',
          scrollTop: false,
        }, {
          text: 'Phone: +86 21 5290-7031',
          block: true,
          bold: false,
          link: '',
          scrollTop: false,
        }, {
          text: 'Investor Relations: ir@atrenew.com',
          block: true,
          bold: false,
          link: '',
          scrollTop: false,
        }, {
          text: 'Public Relations: pr@atrenew.com',
          block: true,
          bold: false,
          link: '',
          scrollTop: false,
        }, {
          text: 'Brand Partner: brand@atrenew.com',
          block: true,
          bold: false,
          link: '',
          scrollTop: false,
        }]
      }],
      copyRights: [{
        text: 'Copyright 2010 - %YEAR% 上海万物新生环保科技集团有限公司',
        link: 'https://www.atrenew.com/resources/home/atrenew.com.certificate.png'
      }],
      securityLinks: [{
        text: '沪ICP备10043802号-13',
        link: 'https://beian.miit.gov.cn/'
      }, {
        icon: require('./assets/icons/icon-beian.png'),
        text: '沪公网安备 31011002006975 号',
        link: 'https://beian.mps.gov.cn/#/query/webSearch?code=31011002006975'
      }, {
        text: '违法不良信息举报电话：0519-88236605',
        link: undefined
      }, {
        text: '上海市互联网举报中心',
        link: 'http://www.shjbzx.cn'
      }, {
        text: '网上有害信息举报专区',
        link: 'https://www.12377.cn'
      }]
    },
    pages: {
      Business: {
        title: 'Business',
        Ahs: BusinessAhs.US(),
        Paipai: BusinessPaipai.US(),
        Paijitang: BusinessPaijitang.US(),
        Global: BusinessGlobal.US(),
        business: [
          {
            name: 'AHS Recycle',
            link: '/business/aihuishou',
          },
          {
            name: 'PJT Marketplace',
            link: '/business/paijitang',
          },
          {
            name: 'Paipai Marketplace',
            link: '/business/paipai',
          },
          {
            name: 'AHS DEVICE',
            link: '/business/global',
          },
        ],
      },
      Innovation: Innovation.US(),
      News: {
        title: 'News',
        menus: [
          {
            name: 'Corporate Blog',
            link: '/news/blog'
          },
          {
            name: 'News Coverage',
            link: '/news/media'
          }
        ],
      },
      Investors: {
        title: 'Investors',

        menus: [
          {
            name: 'Investor Home',
            link: 'https://ir.atrenew.com/',
          },
          {
            name: 'News Releases',
            link: 'https://ir.atrenew.com/news-releases',
          },
          {
            name: 'Financial Information ',
            link: '',
            subMenu: [
              {
                name: 'SEC Filings',
                link: 'https://ir.atrenew.com/financial-information/sec-filings',
              },
              {
                name: 'Quarterly Results',
                link: 'https://ir.atrenew.com/financial-information/quarterly-results',
              },
              {
                name: 'Annual Reports',
                link: 'https://ir.atrenew.com/financial-information/annual-reports',
              },
              {
                name: 'Analyst Coverage',
                link: 'https://ir.atrenew.com/financial-information/analyst-coverage',
              },
            ],
          },
          {
            name: 'Stock Information',
            link: 'https://ir.atrenew.com/stock-information/stock-quote',
          },
          {
            name: 'Investor Events',
            link: '',
            subMenu: [
              {
                name: 'Event Calendar',
                link: 'https://ir.atrenew.com/investor-events/event-calendar',
              },
              {
                name: 'Webcasts and Presentations',
                link: 'https://ir.atrenew.com/investor-events/webcasts-presentations',
              },
            ],
          },
          {
            name: 'Corporate Governance',
            link: '',
            subMenu: [
              {
                name: 'Letter from CEO',
                link: 'https://ir.atrenew.com/corporate-governance/letter-from-ceo',
              },
              {
                name: 'Management',
                link: 'https://ir.atrenew.com/corporate-governance/management',
              },
              {
                name: 'Board of Directors',
                link: 'https://ir.atrenew.com/corporate-governance/board-of-directors',
              },
            ],
          },
          {
            name: 'Resources',
            link: '',
            subMenu: [
              {
                name: 'Investor FAQs',
                link: 'https://ir.atrenew.com/resources/investor-faqs',
              },
              {
                name: 'Email Alerts',
                link: 'https://ir.atrenew.com/resources/email-alerts',
              },
              {
                name: 'IR Contacts',
                link: 'https://ir.atrenew.com/resources/ir-contacts',
              },
            ],
          },
        ],
      },
      ESG: {
        title: 'ESG',
        link: '/esg',
        menus: [
          {
            name: 'ESG Overview',
            link: '/esg/overall'
          },
          {
            name: 'Products and Services',
            link: '/esg/product'
          },
          {
            name: 'Green Development',
            link: '/esg/environment'
          },
          {
            name: 'Our People',
            link: '/esg/humanity'
          },
          {
            name: 'Social Responsibilities',
            link: '/esg/responsibility'
          },
          {
            name: 'ESG Reports and Policies',
            link: '/esg/reports'
          }
        ],
        overall: ESGOverall.US(),
        social: ESGSocial.US(),
        humanity: ESGHumanity.US(),
        environment: ESGEnvironment.US(),
        product: ESGProduct.US(),
        reports: ESGReports.US(),
      },
      Home: Home.US(),
      Blog: Blog.US(),
      Media: Media.US()
    }
  }
}

export default getLocale
