import React, {useCallback, useMemo, useState} from 'react'
import {useLocale} from '../../locales'
import Pagination from 'components/Pagination/Index'
import Breadcrumb from 'components/Breadcrumb/Index'

import classes from './Media.module.scss'
import Seo from '../../components/Head/Seo'

const PAGE_SIZE = 10
export default function Media() {
  const locale = useLocale()
  const [paginationIndex, setPaginationIndex] = useState(0)
  const media = useMemo(
    () => locale.pages.Media,
    [locale.pages.Media]
  )
  const list = useMemo(
    () => {
      return media.news.slice(
        PAGE_SIZE * paginationIndex,
        PAGE_SIZE * (paginationIndex + 1)
      )
    },
    [media.news, paginationIndex]
  )
  const onPageChange = useCallback(
    (index) => {
      if (paginationIndex === index) return
      setPaginationIndex(index)
      document.getElementsByTagName('html')[0]!.scrollIntoView({behavior: 'smooth'})
    },
    [paginationIndex]
  )
  return (
    <div className={classes.Media}>
      <Seo seo={media.seo} />
      <Breadcrumb title={media.breadcrumb}/>

      <div className={classes.main}>
        <div className={classes.pageTitle}>
          {media.pageTitle}
        </div>
        <div className={classes.list}>
          {
            list.map(ele => (
              <a className={classes.item}  href={ele.url} target='_blank' rel='noreferrer'>
                <div className={classes.title}>
                  <div>
                    {ele.title}
                  </div>
                </div>
                <div className={classes.description}>
                  {ele.description}
                </div>
                <div className={classes.date}>
                  {ele.date}
                </div>
                <div className={classes.more}>
                  <div>
                    {media.more}
                  </div>
                </div>
              </a>
            ))
          }
        </div>
      </div>

      <Pagination
        total={media.news.length}
        current={paginationIndex}
        size={PAGE_SIZE}
        onChange={(index) => onPageChange(index)}
      />
    </div>
  )
}
