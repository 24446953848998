import React, {useMemo} from 'react'

import classes from './Steps.module.scss'
import {useLocale} from '../../../../locales'

function Steps() {
  const locale = useLocale()
  const steps = useMemo(
    () => locale.pages.Business.Ahs.steps,
    [locale.pages.Business.Ahs.steps]
  )

  return (
    <div className={classes.Steps}>
      <div className={classes.main}>
        {
          steps.map(ele => (
            <div className={classes.item}>
              <img
                src={ele.icon}
                alt={ele.name}
              />
              <div className={classes.name}>
                {ele.name}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Steps
