import React, { useState } from 'react'

import Video from 'components/Video/Index'
import Icon from 'components/Icon/Index'
import Responsive from 'components/Responsive/Index'

import classes from './Article.module.scss'

export interface ArticleModel {
  title: string
  createdOn: string
  content: Array<string>
  image: string
  video?: string
}

function Article(props: { data: ArticleModel }) {
  const { data } = props
  const [playingVideo, setPlayingVideo] = useState(false)
  return (
    <div className={classes.Article}>
      <div className={classes.avatar}>
        <img src={data.image} alt={data.title} />
        {data.video
          ? (
            <div className={classes.playBtn}>
              <Responsive>
                <Responsive.Large>
                  <Icon
                    icon='Play'
                    scale={3}
                    color='#fff'
                    onClick={() => setPlayingVideo(true)}
                  />
                </Responsive.Large>
                <Responsive.Small>
                  <Icon
                    icon='Play'
                    scale={2}
                    color='#fff'
                    onClick={() => setPlayingVideo(true)}
                  />
                </Responsive.Small>
              </Responsive>
            </div>
          )
          : null}
      </div>
      <div className={classes.detail}>
        <p className={classes.title}>
          {data.title}
        </p>
        <p className={classes.createdOn}>
          {data.createdOn}
        </p>
        {
          data.content.map(p => (
            <p key={p} className={classes.content}>{p}</p>)
          )
        }
      </div>
      {playingVideo
        ? (
          <Video
            onClose={() => setPlayingVideo(false)}
            url={data.video!}
            visible
          />
        )
        : null}
    </div>
  )
}

export default Article