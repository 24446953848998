import {ossImg} from '../../../utils/oss'

const CN = () => ({
  seo: {
    title: '拍拍 - 万物新生',
    description: '拍拍:万物新生旗下二手优品B2C零售平台, 供应链能力扩展到toC零售,提供卓越品质保障与售后服务.',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网'
  },
  banner: {
    logo: require('../../../assets/logo-business-paipai.png'),
    name: '',
    position: '二手优品B2C零售平台',
    introduction: '供应链能力扩展到to C零售消费者，卓越的品质保障'
  },
  breadcrumb: '首页/品牌业务/拍拍',
  about: {
    cover: ossImg('/business/paipai/paipai.png'),
    infos: [
      [
        {
          type: 'h1',
          text: '以供应链为基础，建立品牌与购买信任'
        },
        {
          type: 'subTitle',
          text: '传统行业面临的问题：消费者缺少值得信赖的二手购买渠道和质量保障'
        },
        {
          type: 'description',
          text: '中国的低线城市和巨大的低收入人群，对有质量保障的二手电子产品有旺盛的需求。\n' +
            '而二手电子产品传统产业链复杂，层级多、缺少质量标准、平均加价空间大。万物新生借助积累的中心化质检定价能力，实现在回收获得一手货源后让优质的二手电子产品直达个人消费者，并提供完善的质量售后保障。如此，实现产业链的最短路径和产业价值的最大化。'
        },
      ],
      [
        {
          type: 'h3',
          text: '拍拍全面承接京东商城的二手销售业务，同时销售来自万物新生自营、代卖体系的二手数码电子产品。'
        },
        {
          type: 'subTitle',
          text: '拍拍专注于二手信任心智的打造，通过一年质保、7天无理由退货、定级检测等服务为用户购买二手商品保驾护航，提供优质的用户体验。'
        }
      ],
    ]
  },
  steps: [
    {
      title: '一年质保',
      subTitle: '官方365天免费质保服务',
    },
    {
      title: '7天无理由退货',
      subTitle: '放心选，放心买',
    },
    {
      title: '真机实拍',
      subTitle: '360度无死角拍摄，放心淘好货',
    },
    {
      title: '极速发货',
      subTitle: '万余商品全国包邮，极速发货',
    },
  ],
  article: {
    sectionName: '拍拍平台 - 京东的海量二手优品',
    cover: ossImg('/business/paipai/paipai-article.png'),
    description: '背靠京东巨大的电子产品消费群体和精准的流量，拍拍建立品牌与购买信任，让消费者买得放心、用得省心，实现消费者回收端到消费者购买端的覆盖。拍拍质检提供”一机一图“验机报告，确保二手电子产品的品质保证。',
    icons: [
      {
        icon: require('../../assets/business/Paipai/icon-group-1.1.png'),
        name: '全品类'
      },
      {
        icon: require('../../assets/business/Paipai/icon-group-1.2.png'),
        name: '一元起拍'
      },
      {
        icon: require('../../assets/business/Paipai/icon-group-1.3.png'),
        name: '内购权益'
      },
      {
        icon: require('../../assets/business/Paipai/icon-group-1.4.png'),
        name: '大牌特卖'
      },
      {
        icon: require('../../assets/business/Paipai/icon-group-1.5.png'),
        name: '低至7折'
      },

    ]
  },
  comparison: {
    left: {
      title: '低成本、大规模、可持续获客',
      subTitle: '基于京东、抖音、快手实现低成本大规模获客匹配平台规则，实现最优LBS用户分发将公域用户充分引流私域',
      icons: [
        require('../../assets/business/Paipai/icon-group-2.1.png'),
        require('../../assets/business/Paipai/icon-group-2.2.png'),
        require('../../assets/business/Paipai/icon-group-2.3.png')
      ]
    },
    separate: require('../../assets/business/Paipai/icon-group-2.5.png'),
    right: {
      title: '高转化、便捷售后、有效留存',
      subTitle: '已有全国门店体系的到店服务能力构建拍拍上门交付能力，有效承接公域用户的最优履约体验，同时沉淀用户至私域长期转化运营',
      icons: [
        require('../../assets/business/Paipai/icon-group-2.4.png'),
      ]
    }
  },
  service: {
    sectionName: '拍拍代卖 - 省心之选',
    title: '拍拍提供入仓质检与代卖服务',
    description: [
      '中小商家将自己回收的货源通过商品送检、平台验机后，即可收到预付款；平台运营流量进行商品代卖，让货源卖出价值、让卖家更省心。'
    ]
  },
  advantages: [
    {
      icon: require('../../assets/business/Paipai/icon-group-3.1.png'),
      title: '合作门槛低',
      subTitle: ['万物新生客户有货即可提供'],
    },
    {
      icon: require('../../assets/business/Paipai/icon-group-3.2.png'),
      title: '不占资金',
      subTitle: ['质检完成即付100%预付款'],
    },
    {
      icon: require('../../assets/business/Paipai/icon-group-3.3.png'),
      title: '不怕跌价',
      subTitle: ['保底价兜底无需担心跌价'],
    },
    {
      icon: require('../../assets/business/Paipai/icon-group-3.4.png'),
      title: '操作简单',
      subTitle: [
        '拍拍专业团队操盘',
        '商家无需专业电商团队',
        '无需操心上架定价',
      ],
    },
    {
      icon: require('../../assets/business/Paipai/icon-group-3.5.png'),
      title: '服务贴心',
      subTitle: [
        '全国专属销售PD',
        '全程一对一贴心服务',
      ],
    },
  ],
})

const US = () => ({
  seo: {
    title: 'Paipai - ATRenew',
    description: 'Paipai - ATRenew\'s B2C retail platform for premium pre-owned consumer devices..',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai'
  },
  banner: {
    logo: require('../../../assets/logo-business-paipai.png'),
    name: 'Paipai Marketplace',
    position: 'B2C retail platform for premium pre-owned consumer devices',
    introduction: 'An online extension to the retail businesses with premium quality assurance'
  },
  breadcrumb: 'Home / Business / Paipai Marketplace',
  about: {
    cover: ossImg('/business/paipai/paipai.png'),
    infos: [
      [
        {
          type: 'h1',
          text: 'A trustworthy marketplace backed by supply chain capabilities'
        },
        {
          type: 'subTitle',
          text: 'Satisfying consumer demand for a trustworthy quality assurance purchasing platform for pre-owned devices'
        },
        {
          type: 'description',
          text: 'In China, many consumers in middle and lower-tier cities prefer quality, value-for-money pre-owned consumer electronics over new products. The traditional pre-owned electronics industry was complex, fragmented, and lacked transparency. By leveraging our proprietary technologies, we at ATRenew have created the most direct path within the value chain to provide consumers with premium pre-owned goods and all-round after-sales services.'
        },
      ],
      [
        {
          type: 'h3',
          text: 'Paipai Marketplace distributes pre-owned goods sourced from JD.com, AHS Recycle, and consignment.'
        },
        {
          type: 'subTitle',
          text: 'Paipai Marketplace builds brand trust by offering a 1-year warranty, 7-day free return policy, and self-operated inspection system for consumers.'
        }
      ],
    ]
  },
  steps: [
    {
      title: '1-year warranty',
      subTitle: 'Provided by ATRenew',
    },
    {
      title: '7-day free return',
      subTitle: 'A trustworthy marketplace',
    },
    {
      title: 'Verified image of goods',
      subTitle: '360-degree product display',
    },
    {
      title: 'Instant shipping',
      subTitle: 'Free shipping nationwide',
    },
  ],
  article: {
    sectionName: 'Paipai Marketplace – high-volume sourcing of premium pre-owned goods from JD.com',
    cover: ossImg('/business/paipai/paipai-article.png'),
    description: 'Backed by JD.com\'s vast electronic products user base and user traffic data, PaipaiMarketplace has built a trustworthy brand that provides seamless shopping and transactions to consumers. Additionally, Paipai provides a quality inspection report for every single device to increase consumer trust. By integrating Paipai Marketplace into our operational model, we have created a closed loop of recycling, processing and distribution of pre-owned consumer electronics.',
    icons: [
      {
        icon: require('../../assets/business/Paipai/icon-group-1.1.png'),
        name: 'All categories'
      },
      {
        icon: require('../../assets/business/Paipai/icon-group-1.2.png'),
        name: 'Auction from RMB1'
      },
      {
        icon: require('../../assets/business/Paipai/icon-group-1.3.png'),
        name: 'In-app sales'
      },
      {
        icon: require('../../assets/business/Paipai/icon-group-1.4.png'),
        name: 'Premium brands sales'
      },
      {
        icon: require('../../assets/business/Paipai/icon-group-1.5.png'),
        name: '30% off'
      },

    ]
  },
  comparison: {
    left: {
      title: 'Cost-efficient, scalable and sustainable customer acquisition',
      subTitle: 'We further reduce customer acquisition costs by leveraging JD.com, Douyin and Kuaishou to generate traffic.',
      icons: [
        require('../../assets/business/Paipai/icon-group-2.1.png'),
        require('../../assets/business/Paipai/icon-group-2.2.png'),
        require('../../assets/business/Paipai/icon-group-2.3.png')
      ]
    },
    separate: require('../../assets/business/Paipai/icon-group-2.5.png'),
    right: {
      title: 'Effective conversion and retention backed by convenient after-sales services',
      subTitle: 'In-store service capabilities and door-to-door delivery shifts the retail experience to the buyer’s doorstep.',
      icons: [
        require('../../assets/business/Paipai/icon-group-2.4.png'),
      ]
    }
  },
  service: {
    sectionName: 'Paipai Consignment – sell with ease',
    title: 'Paipai offers sellers a consignment model and inspection services',
    description: [
      'Small and medium-sized merchants may receive upfront payment for their goods by sending them to Paipai for inspection and consignment, removing distribution concerns.',
    ]
  },
  advantages: [
    {
      icon: require('../../assets/business/Paipai/icon-group-3.1.png'),
      title: 'Low barriers for cooperation',
      subTitle: ['Flexible services for ATRenew clients'],
    },
    {
      icon: require('../../assets/business/Paipai/icon-group-3.2.png'),
      title: 'Immediate payment',
      subTitle: ['100% upfront payment upon completion of quality inspection'],
    },
    {
      icon: require('../../assets/business/Paipai/icon-group-3.3.png'),
      title: 'Sales price guarantee',
      subTitle: ['Not impacted by falling prices'],
    },
    {
      icon: require('../../assets/business/Paipai/icon-group-3.4.png'),
      title: 'User-friendly',
      subTitle: [
        'Specialist team operates inventory, shelving, and transactions to ease seller burden',
      ],
    },
    {
      icon: require('../../assets/business/Paipai/icon-group-3.5.png'),
      title: 'Professional customer service',
      subTitle: [
        'Dedicated sales for premium one-on-one services',
      ],
    },
  ],
})

const data = {
  CN,
  US
}
export default data
