import React, { useMemo } from 'react'
import {useLocale} from '../../../locales'
import Section from '../../../components/Section/Index'

import classes from './Issue.module.scss'

export default function Issue() {
  const locale = useLocale()
  const article = useMemo(
    () => locale.pages.ESG.overall.article,
    [locale.pages.ESG.overall.article]
  )

  return (
    <Section
      name={article.sectionName}
      className={classes.Section}
    >
      <div className={classes.Issue}>
        <div className={classes.cover}>
          <img src={article.cover} alt={article.sectionName} />
        </div>
        <div className={classes.description}>
          { article.description }
        </div>
      </div>
    </Section>

  )
}
