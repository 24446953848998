import React, {ReactNode} from 'react'
import classNames from 'classnames'
import classes from './Section.module.scss'

interface SectionProps {
  name: string
  border?: boolean
  header?: ReactNode
  children?: ReactNode
  className?: string
  mainClassName?: string
}

function Section(props: SectionProps) {
  const {
    name,
    header,
    border = true,
    children,
    className = '',
    mainClassName = ''
  } = props

  return (
    <div className={classNames(classes.Section, className)}>
      <div className={classes.name}>
        {name}
      </div>
      <div className={classNames(
        classes.main,
        mainClassName,
        {[classes.border]: border},
      )}>
        <div>
          {header}
        </div>
        {children}
      </div>
    </div>
  )
}

export default Section
