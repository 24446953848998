const CN = () => ({
  seo: {
    title: '媒体报道 - 万物新生',
    description: '万物新生 - 媒体报道汇聚来自主流媒体的专访报道.',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网'
  },
  pageTitle: '',
  breadcrumb: '首页 / 新闻动态 / 媒体报道',
  banner: 'https://fakeimg.pl/1200x360/F9E52C?text=Hello World&font=lobster',
  more: '了解更多 →',
  news: [
    {
      title: '世界银行集团国际金融公司（IFC）代表团到访万物新生',
      description: '近期，世界银行集团成员机构国际金融公司（International Finance Corporation，以下简称IFC）代表团到访万物新生（爱回收）集团，双方围绕循环经济发展、二手消费电子产品交易与服务、生活可回收物回收等话题进行了深度交流。',
      date: '2024年3月15日',
      url: 'https://caijing.chinadaily.com.cn/a/202403/15/WS65f3f603a3109f7860dd599a.html'
    },
    {
      title: '万物新生（爱回收）2023年营收近130亿元 同比增长31.4%',
      description: '3月12日，二手消费电子产品交易和服务平台万物新生（爱回收）集团发布2023财年第四季度和全年业绩报告。',
      date: '2024年3月12日',
      url: 'https://company.cnstock.com/company/scp_gsxw/202403/5203916.htm'
    },
    {
      title: '《师想者来了》第四集|循环生活，双碳时代何以做地球的朋友？',
      description: '微纪录片《师想者来了》第四期，中欧国际工商学院金融与会计学教授芮萌与万物新生（爱回收）集团执行总裁王永良走进身边的循环经济，畅谈我们如何拥有更健康的、可持续的未来。',
      date: '2024年1月30日',
      url: 'https://www.sh.chinanews.com.cn/tpxw/2024-01-30/121037.shtml'
    },
    {
      title: '逆向低碳联盟发布二手3C产品企业联合标准',
      description: '近日，逆向物流与低碳供应链产业联盟（简称“逆向低碳联盟”）2024年年会在上海召开，会上发布了《二手3C产品检测定级规范 手机》和《二手3C产品检测服务规范》两大企业联合标准。万物新生（爱回收）集团作为联盟理事长单位参与会议，并作为两大标准的主要起草方对标准内容进行了详细介绍和解读。',
      date: '2024年1月16日',
      url: 'https://tech.gmw.cn/2024-01/16/content_37092485.htm'
    },
    {
      title: '旧手机，如何物尽其用？',
      description: '我国既是手机生产大国，也是手机消费大国。旧手机的综合利用对于节约资源、保护环境、促进绿色低碳循环发展具有重要意义。如何实现海量旧手机的规范处置、高效回收和循环利用？记者对此进行了采访。',
      date: '2024年1月5日',
      url: 'http://kpzg.people.com.cn/n1/2024/0105/c404214-40153070.html'
    },
    {
      title: '请回答2023丨爱回收执行总裁王永良：市场对交易效率和服务质量要求不断提升 行业发展进入新阶段',
      description: '近两年，闲置物品流入市场带来的增量已经被验证，越来越多年轻消费者对二手交易的认同，促使平台将线下3C数码品类的生意搬到了线上，还给困于线下中古店、典当行的一些稀缺品类的交易带来了生机。2023年，循环经济、绿色发展成为消费关键词。二手电商平台在涌动的市场暖流中，是否迎来了新的春天。2024年，二手电商的热度会否持续飙升。12月25日，封面新闻记者专访了万物新生（爱回收）集团执行总裁王永良，谈谈他对循环经济的看法。',
      date: '2023年12月25日',
      url: 'https://www.thecover.cn/news/WWE45/ATqhaH90qSdq8Jkw=='
    },
    {
      title: '万物新生（爱回收）：Q3营收32.6亿元，同比增长28.4%，实现non-GAAP经营利润7381万元',
      description: '11月22日，万物新生（爱回收）集团发布了2023年第三季度业绩报告。财报显示，2023年第三季度，万物新生集团总收入32.6亿元，同比增长28.4%，突破业绩指引高端。本季度集团实现non-GAAP经营利润7381万元，创历史新高，连续五个季度实现non-GAAP口径下的经营利润。此外，集团non-GAAP履约费用率从2021年一季度起呈降低趋势。本季度集团non-GAAP履约费用率从去年同期的10.7%下降至8.7%。',
      date: '2023年11月22日',
      url: 'https://caijing.chinadaily.com.cn/a/202311/22/WS655dd6e5a310d5acd876ffe8.html'
    },
    {
      title: '万物新生（爱回收）获路透社2023年“全球商业责任奖”认可',
      description: '11月1日，路透社（Reuters）公布了2023年“全球商业责任奖”（Responsible Business Awards 2023）评选结果。万物新生（爱回收）在“循环过渡奖”（Circular Transition Award）评选中获得评委会高度赞扬（highly commended）。评委会指出：“万物新生在循环经济的影响力和可扩展性方面表现突出，在创新、技术发展和业务转型上的探索令人印象深刻。万物新生在中国和日本的业务发展证明了循环经济模式的可扩展性。”',
      date: '2023年11月3日',
      url: 'https://ex.chinadaily.com.cn/exchange/partners/82/rss/channel/cn/columns/sz8srm/stories/WS65449eb1a310d5acd876d2b2.html'
    },
    {
      title: '万物新生（爱回收）集团加入联合国全球契约组织',
      description: '近日，万物新生（爱回收）集团宣布加入联合国全球契约组织（United Nations Global Compact，简称UNGC）。万物新生成为国内二手消费电子行业首家加入该国际组织的成员，这不仅标志着万物新生加入全球绿色低碳阵营，也是联合国全球契约组织对万物新生绿色循环可持续发展实践的认可。',
      date: '2023年9月20日',
      url: 'http://dzb.xfrb.com.cn/Html/2023-09-25/56238.html'
    },
    {
      title: '万物新生（爱回收）最新财报：营收29.6亿，non-GAAP经营利润创新高',
      description: '8月23日，万物新生（爱回收）集团发布了2023年第二季度业绩报告。财报显示，2023年第二季度，万物新生集团总收入29.6亿元，同比增长38.1%，突破业绩指引高端。本季度实现non-GAAP经营利润5198万元，单季创历史新高，连续四个季度实现non-GAAP口径下的运营盈利。此外，万物新生经营管理效率进一步提升，二季度non-GAAP履约费用率持续走低，从今年一季度的9.1%下降至本季度的8.8%。',
      date: '2023年8月23日',
      url: 'https://news.pedaily.cn/202308/520340.shtml'
    },
    {
      title: '万物新生（爱回收）集团发布2022年度ESG报告 碳排放强度连续三年下降',
      description: '6月20日，万物新生（爱回收）集团发布《2022环境、社会及管治报告》。报告披露，2022年内，万物新生全平台二手商品交易量为3200万台，其中万物新生回收并监督绿色处置的废旧电子设备共计27万台，减少电子产品污染约43.2吨。此外，万物新生碳排放强度实现连续三年下降。2022全年温室气体排放强度（范畴一+范畴二）为0.3吨二氧化碳当量/百万元人民币，相较上一年下降18.9%。',
      date: '2023年6月20日',
      url: 'https://www.techweb.com.cn/it/2023-06-20/2929028.shtml'
    },
    {
      title: '万物新生（爱回收）2023年Q1财报：营收28.7亿元',
      description: '5月23日，万物新生（爱回收）集团发布了2023年第一季度业绩报告。财报显示，2023年第一季度，万物新生集团总收入28.7亿元，同比增长30.2%，业绩突破指引高端。本季度non-GAAP经营利润4438万元，创历史新高，连续三个季度实现运营盈利。',
      date: '2023年5月23日',
      url: 'https://tech.huanqiu.com/article/4D1cP9WQGbc'
    },
    {
      title: '万物新生（爱回收）2022年四季度及全年财报：年营收98.7亿元 non-GAAP层面实现全年盈利',
      description: '北京时间3月13日，二手消费电子产品交易和服务平台万物新生（爱回收）集团（纽交所股票代码：RERE）发布2022财年第四季度和全年业绩报告。财报显示，2022年第四季度万物新生集团总收入29.8亿元，同比增长22.4%。四季度，万物新生实现non-GAAP经营利润3457万元（经调整，不含员工股权激励费用，无形资产的摊销以及因收购产生的递延成本，递延成本、商誉及无形资产减值，下同），创下历史新高。纵观2022全年，万物新生总收入同比增长26.9%达到98.7亿元，平台全年二手商品交易量达3200万单，营收和业务规模均创新高。',
      date: '2023年3月13日',
      url: 'https://caijing.chinadaily.com.cn/a/202303/13/WS640ee8bca3102ada8b2335c6.html'
    },
    {
      title: '万物新生（爱回收）2022年三季度财报：营收25.4亿元创新高',
      description: '11月22日，万物新生（爱回收）集团发布了2022年第三季度业绩报告。财报显示，2022年第三季度，万物新生集团总收入25.4亿元，同比增长29.2%；三季度集团GMV达到95亿元，同比增长14.5%。本季度non-GAAP经营利润1077万元。三季度经营性现金流净流入1.01亿元，连续三个季度实现经营性现金流净流入。',
      date: '2022年11月22日',
      url: 'https://caijing.chinadaily.com.cn/a/202211/22/WS637c92aaa3109bd995a51711.html'
    },
    {
      title: '自动化运营体系再升级 万物新生（爱回收）东莞自动化运营中心开仓运营',
      description: '11月9日，万物新生集团东莞自动化运营中心正式开仓运营。万物新生东莞自动化运营中心由自动输送分拣体系、自动质检体系、自动仓储体系三大模块组成，采用全流程数字化管理系统进行中央控制，承担输送、分拣、质检、仓储、发货等职能。',
      date: '2022年11月9日',
      url: 'https://caijing.chinadaily.com.cn/a/202211/10/WS636caaa8a3109bd995a4f3de.html'
    },
    {
      title: '“爱分类·爱回收”淄博分拣中心正式启动',
      description: '在山东省淄博市有400多台“爱分类·爱回收”智能回收机，市民通过微信扫码或输入手机号就能投递，每天回收至少30多吨可回收物，这些再生资源被回收之后将如何处理？8月25日，“爱分类·爱回收”淄博可回收物末端分拣中心正式投入使用，这也标志着可回收物收集、中转、分拣的“点-站-场”闭环体系正式建立。',
      date: '2022年8月25日',
      url: 'https://baijiahao.baidu.com/s?id=1742114582714318269&wfr=spider&for=pc'
    },
    {
      title: '万物新生（爱回收）2022年二季度财报：营收21.5亿元 业绩超预期达成',
      description: '北京时间8月24日，万物新生（爱回收）集团（纽交所股票代码：RERE）发布了2022年第二季度业绩报告。财报显示，2022年第二季度，万物新生集团总收入21.5亿元，同比增长14.9%，业绩超预期达成；二季度集团GMV达到86亿元，同比增长10.3%。二季度经营性现金流净流入2.8亿元，实现连续两个季度经营性现金流净流入。截至二季度末，万物新生全国门店覆盖城市达241座，门店总数达1629个。',
      date: '2022年8月24日',
      url: 'https://caijing.chinadaily.com.cn/a/202208/24/WS6305ddd5a3101c3ee7ae55de.html'
    },
    {
      title: '万物新生（爱回收）杜晓忱：“线上+线下”，合力构建供应链核心竞争力',
      description: '自2013年底以来，万物新生就开始着手进行新零售供应链体系的打造，通过线上、线下结合的方式，优化成本模型，提升运营效率。疫情前，万物新生自营业务年化增长率保持在40%以上，2021年交易量达到3120万单。',
      date: '2022年8月13日',
      url: 'https://m.21jingji.com/article/20220813/e0b883ba0c7b6376f4fe8a59488efea0.html'
    },
    {
      title: '极光大数据报告 ：万物新生（爱回收）促进电子产品循环经济发展',
      description: '近日,近日极光旗下专业数据平台月狐数据发布了《2022低碳社会洞察报告》(下篇)(以下简称“报告”)。报告提到,大部分消费者对秉持绿色发展理念的企业表现出较高的好感度,未来“低碳”将愈发成为品牌价值标签。此外,公众对闲置电子产品回收需求大,但实际的回收渗透率仍较低,行业痛点亟需解决,而像万物新生(爱回收)这类优质二手3C交易与服务平台则有效促进电子产品循环经济发展。',
      date: '2021年8月5日',
      url: 'https://qiye.chinadaily.com.cn/a/202208/05/WS62ecfc1fa3101c3ee7ae295b.html'
    },
    {
      title: '万物新生（爱回收）集团发布2021年度ESG报告 2021年实现碳减排46.4万吨',
      description: '2022年6月9日，二手消费电子产品交易与服务平台万物新生（爱回收）集团发布《2021环境、社会及管治报告》（简称“ESG报告”），这也是万物新生集团上市后第二次发布相关报告。该报告首次引入气候相关财务信息披露工作组（TCFD）框架，梳理、整合、更新了20个重要ESG议题。其中，“产品治理”和“公司治理”两大议题在万物新生内外部利益相关方重视程度提升，成为新的高度重要议题。',
      date: '2022年6月9日',
      url: 'http://ex.chinadaily.com.cn/exchange/partners/82/rss/channel/cn/columns/snl9a7/stories/WS62a1a52fa3101c3ee7ad9b93.html'
    },
    {
      title: 'CIC灼识咨询发布二手3C行业数据 万物新生（爱回收）市占率达9%位居市场第一',
      description: '近日，CIC灼识咨询发布了二手3C行业数据。数据显示，以GMV口径计算，2021年市场前六名（不包含C2C平台）包括万物新生（爱回收）、转转、同城帮、回收宝、闪回收及换换回收，其中万物新生在中国二手消费电子设备交易和服务平台市场中的市占率达9%，位居市场第一。同时，以销售端交易次数口径计算，2021年万物新生市占率11%，同样位居市场第一。',
      date: '2022年6月16日',
      url: 'https://finance.ifeng.com/c/8Gt5LkBWY0t'
    },
    {
      title: '万物新生（爱回收）2022Q1财报：季度营收增长45.7%实现持续盈利',
      description: '北京时间5月24日，中国最大的二手消费电子产品交易和服务平台万物新生（爱回收）集团（纽交所股票代码：RERE）发布2022第一季度业绩报告。',
      date: '2022年5月24日',
      url: 'https://www.163.com/dy/article/H857LRDN0519QIKK.html'
    },
    {
      title: '万物新生CEO陈雪峰：持续助力循环经济发展',
      description: '近日，第四届中国国际进口博览会（以下简称进博会）在上海举行。在历届进博会上，可持续发展、环境保护作为热议话题持续受到关注，循环经济更是本届进博会的热议话题之一。本届进博会上，绿色低碳、可持续发展生产及消费理念，贯穿展区各品类细分领域。本届进博会期间，德勤公司举办了“中国应对气候变化的转折点”主题论坛，万物新生CEO陈雪峰通过直播连线，出席并发表主题演讲，分享了万物新生在促进二手3C商品流通方面的经验以及企业自身的ESG实践。',
      date: '2021年11月8日',
      url: 'https://tech.gmw.cn/2021-11/08/content_35295806.htm'
    },
    {
      title: '万物新生（爱回收）发起公益助学项目',
      description: '9月7日，由万物新生（爱回收）集团、中国扶贫基金会、成都一扇窗公益服务中心联合发起的“回收爱美好学校河南数码助学智慧校园项目”在河南淇县大洼中心小学启动。',
      date: '2021年9月10日',
      url: 'https://tech.huanqiu.com/article/44iGYC1GNq8'
    },
    {
      title: '“利润至上”的时代正在结束，企业存在的逻辑正在悄然改变',
      description: '万物新生发布了上市以来的首份ESG报告，向外界展示了公司对可持续发展的理解，以及ESG管理与实践的最新进展。',
      date: '2021年9月3日',
      url: 'https://www.163.com/dy/article/GJ01A8U0051980A4.html'
    },
    {
      title: '万物新生(爱回收)集团发布上市后首份财报：业绩高速增长 财务稳健',
      description: '北京时间8月17日，中国最大的二手消费电子产品交易和服务平台万物新生（爱回收）集团（纽交所股票代码：RERE）公布2021财年第二季度业绩报告。报告显示，万物新生集团2021年第二季度营收为18.68亿元人民币，较去年同期同比增长56.2%，全平台成交GMV为78亿元人民币，较去年同期同比增长69.6%。2021年第二季度，万物新生集团在交易规模（GMV），营收增长，以及non-GAAP运营利润三个方面的达成均超出预期目标。',
      date: '2021年8月17日',
      url: 'http://life.jrj.com.cn/2021/08/17172733267581.shtml'
    },
    {
      title: '万物新生(爱回收)集团捐赠505万元 助力河南学校灾后恢复',
      description: '7月30日消息，近日，受持续强降雨天气影响，河南省多地发生严重内涝，多所学校受灾，正常教学工作中断，教学电子设备受到不同程度的影响。对此，万物新生（爱回收）集团向中国扶贫基金会捐赠505万元，并同中国扶贫基金会、“一扇窗计划”，一起设立“回收爱·美好学校河南数码助学基金”，为受灾的中小学购置全新的电子教学设备，助力河南学校的灾后恢复。',
      date: '2021年7月30日',
      url: 'https://www.sohu.com/a/480463459_100302690'
    },
    {
      title: '万物新生（爱回收）案例入选上海市委党校重点教材',
      description: '为了更好地服务上海建设具有全球影响力的科技创新中心，加快上海创新创业经验与案例的复制推广，在上海市委组织部、上海市教卫工作党委、上海科创办等部门的直接指导下，上海市委党校（上海行政学院）毛军权教授牵头组织编写遴选出新时代在上海成长和发展起来的22个优秀民营科技企业创新创业案例，并汇集成《创新创业 活力四射——新时代上海创新型企业攻坚克难时间案例》一书。此书将作为上海市委党校重点教材。万物新生（爱回收）集团《构建新时代“互联网+环保”循环再利用体系——上海万物新生环保科技集团有限公司积极探索二手3C产品再利用新赛道》作为典型案例成功入选此教材。',
      date: '2021年7月22日',
      url: 'https://caijing.chinadaily.com.cn/a/202107/22/WS60f926b7a3101e7ce975afcf.html'
    },
    {
      title: '万物新生（爱回收）获高盛、美银、华兴“买入”评级 有35-48%上升空间',
      description: '6月18日IPO以来，万物新生集团（代码：RERE）获得包括高盛、美银、华兴等国内外知名券商的研究覆盖。其中，高盛给予万物新生“买入”评级，12个月目标价为20.80美元/ADS，有48.1%的潜在上升空间；美银给予万物新生“买入”评级，12个月目标价为20.00美元/ADS，有42.9%的潜在上升空间；华兴给予万物新 生“买入”评级，12个月目标价为19.00美元/ADS，有35.0%的潜在上升空间。',
      date: '2021年7月15日',
      url: 'http://www.techweb.com.cn/ucweb/news/id/2849067'
    },
    {
      title: '二手奢侈品交易平台「值耀」获万物新生（爱回收）5500万人民币战略投资',
      description: '近日，二手奢侈品平台值耀获5500万人民币融资，本轮融资由万物新生（爱回收）集团战略领投，早期投资人华盖资本、晨山资本、清新资本、歌斐资产跟投。万物新生集团还与「值耀」达成战略合作协议，万物新生集团将为值耀开放线上流量及线下近千家门店作为其开展二手奢侈品业务的入口及服务配套。',
      date: '2021年7月5日',
      url: 'https://36kr.com/p/1297540058909318'
    },
    {
      title: '万物新生（爱回收）正式登陆纽交所 成为“中概股ESG第一股”',
      description: '北京时间6月18日，中国最大的二手消费电子产品交易和服务平台万物新生(爱回收)集团正式登陆纽交所，成为“中概股ESG第一股”。股票代码“RERE”，定价14.00美元/ADS，募资规模2.61亿美元，连同超额配售权发行股份，市值达36亿美元。',
      date: '2021年6月18日',
      url: 'http://www.xinhuanet.com/tech/2021-06/21/c_1127582750.htm'
    },
    {
      title: '雀巢咖啡联手万物新生（爱回收）开启可塑之材回收计划',
      description: '雀巢咖啡联手万物新生（爱回收）推出的可塑之材回收计划正式启动。活动参与方式为，用户通过找到所在城市的爱分类·爱回收智能自助回收机，投递雀巢咖啡旧包装或其他可回收物，通过扫码参与即可有机会获得限量联名潮品。该计划旨在倡导可持续的生活方式，引导公众低碳环保的消费行为，助力循环经济的发展。',
      date: '2021年6月8日',
      url: 'http://finance.youth.cn/finance_gdxw/202106/t20210608_13005239.htm'
    },
    {
      title: '深度解读爱回收（万物新生）IPO招股书，财务稳健现金流良好',
      description: '二手消费电子产品交易和服务平台万物新生(爱回收)集团日前向美国SEC递交招股书,准备在纽交所上市。万物新生此次赴美上市的募集资金,将主要用于进一步提升技术能力,提供更多元化的平台服务,拓展爱回收门店网络,为拍拍平台开发新的销售渠道以及其他一般用途。据CIC(灼识咨询)报告显示,在二手3C交易和服务领域,2020年万物新生集团的交易台数和GMV均位列中国市场第一名(均为支付成交数据,下同),并且高于行业内第二至第五名的总和,是行业的绝对领先者。',
      date: '2021年6月8日',
      url: 'http://www.jjckb.cn/2021-06/08/c_139995610.htm'
    },
    {
      title: '万物新生（爱回收）携手根与芽发起净滩活动 共同参与东亚江豚保护项目',
      description: '6月8日，正值第13个“世界海洋日”之际，万物新生（爱回收）集团与公益机构北京根与芽联合在大连、东营、青岛、唐山发起净滩活动，共同参与“你好，东亚江豚”的保护项目。据悉，捡拾到的海洋垃圾将集中到爱分类·爱回收分拣中心，进行统一分拣。推动海洋垃圾的后端处置和回收再利用，的同时保护海洋动物免受垃圾的威胁。',
      date: '2021年6月8日',
      url: 'http://cn.chinadaily.com.cn/a/202106/08/WS60bf2b2ea3101e7ce9753f2d.html'
    },
    {
      title: '全新集团品牌“万物新生”发布 爱回收完成过亿美元E+轮融资',
      description: '9月22日，爱回收在北京举办“科技赋能 万物新生”品牌升级发布会，会上宣布“万物新生”将取代“爱回收”作为全新集团品牌，下辖爱回收、拍机堂、拍拍、海外业务AHSDEVICE、城市绿色产业链业务爱分类·爱回收等业务线。爱回收集团创始人兼CEO陈雪峰同时宣布，获得京东集团和国泰君安国际联合领投的超1亿美元E+轮融资，上海国和投资、清新资本、京东物流产业基金汇禾资本、毅峰资本参与跟投，泰合资本继续担任独家财务顾问。',
      date: '2020年9月23日',
      url: 'http://www.chinanews.com/business/2020/09-23/9297808.shtml'
    },
    {
      title: '李宁品牌携手爱回收、COSTA推出限定环保T恤',
      description: '2020年8月26日，李宁品牌与爱回收旗下爱分类爱回收（LOVERE）、COSTA联合推出限定环保T恤。该产品以COSTA提供的回收咖啡渣为原料，采用李宁的最新环保面料科技技术，将我们生活中常见的咖啡渣通过煅烧加工后生产出可回收的“咖啡碳纤维”环保面料，再制作成衣，从而实现了废弃物回收的再利用，三方共同倡导公众环保、健康的生活方式。',
      date: '2020年8月26日',
      url: 'http://www.xinhuanet.com/tech/2020-08/26/c_1126416287.htm'
    },
    {
      title: '爱回收与拍拍合并 二手领域进入爆发前夜',
      description: '在C轮融资开始进入爱回收后，京东又接连追投了D轮和E轮。这一次，京东更是把“亲儿子”二手交易平台“拍拍”拿了出来，与中国最大的电子产品回收平台“爱回收”进行战略合并。除了注入业务外，京东还将以资源+现金方式领投爱回收新一轮超过5亿美元的融资。',
      date: '2019年6月7日',
      url: 'https://www.163.com/dy/article/EH1CU77F0511C9DR.html'
    }
  ]
})

const US = () => ({
  seo: {
    title: 'News Coverage - ATRenew',
    description: 'ATRenew news coverage section presents high-profile media reporting on the Company.',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai'
  },
  pageTitle: '',
  breadcrumb: 'Home / News / News Coverage',
  banner: 'https://fakeimg.pl/1200x360/F9E52C?text=Hello World&font=lobster',
  more: 'Learn more →',
  news: [
    {
      title: 'ATRenew Posts Record Profit as Quality Control Automation Push Drives Efficiency',
      description: 'ATRenew on Tuesday delivered a three-month profit record, as it leveraged an automation push to enhance business efficiency while consolidating its lead in a wider-range of recycling.',
      date: 'Mar 15, 2024',
      url: 'https://pandaily.com/atrenew-posts-record-profit-as-quality-control-automation-push-drives-efficiency/'
    },
    {
      title: 'How to Reduce E-waste and Promote Repurpose in Your Business',
      description: 'Kerry Chen, founder and CEO at ATRenew talked about how entrepreneurs can make a massive impact on sustainability by employing the right practices.',
      date: 'Nov 7, 2023',
      url: 'https://www.entrepreneur.com/green-entrepreneur/how-to-reduce-e-waste-and-promote-repurpose-in-your-business/464404'
    },
    {
      title: 'Responsible Business Awards 2023',
      description: 'Here is a list of all of the companies that were recognised at the 2023 Responsible Business Awards.',
      date: 'Nov 1, 2023',
      url: 'https://events.reutersevents.com/sustainable-business/sustainability-awards/winners'
    },
    {
      title: 'How ATRenew Pioneers the Future of the Circular Economy',
      description: 'Sustainability and green initiatives have never been more popular among global enterprises than they are today. Some simply promote sustainability among their employees and customers, while others aim to build solutions that can reduce carbon emissions and waste. In the case of ATRenew, sustainability is essentially the entire business model.',
      date: 'Oct 10, 2023',
      url: 'https://www.usatoday.com/story/special/contributor-content/2023/10/10/how-atrenew-pioneers-the-future-of-the-circular-economy/71131803007/'
    },
    {
      title: "The Revolving World of Electronics: A Dialogue with ATRenew's Kerry Chen",
      description: 'The circular economy emphasizes the refurbishment and reuse of products, reducing the need for raw materials and minimizing waste. One company championing this cause in the realm of electronics is ATRenew.',
      date: 'July 20, 2023',
      url: 'https://www.techtimes.com/articles/294046/20230720/the-revolving-world-of-electronics-a-dialogue-with-atrenews-kerry-chen.htm'
    },
    {
      title: 'ATRenew Becomes a Partner of Apple’s ‘Trade-in’ Service',
      description: 'ATRenew has become a new partner for Apple’s ‘trade-in’ service in Chinese mainland market, making it the first additional trade-in service provider since the launch of this program over eight years ago.',
      date: 'June 30, 2023',
      url: 'https://pandaily.com/atrenew-becomes-a-partner-of-apples-trade-in-service/'
    },
    {
      title: 'How China’s top electronics recycling platform ATRenew is giving used gadgets a new life',
      description: 'ATRenew hopes to cash in on China’s growing pre-owned electronics market and give a boost to the circular economy.',
      date: 'June 23, 2023',
      url: 'https://www.scmp.com/business/china-business/article/3225048/how-chinas-top-electronics-recycling-platform-atrenew-giving-used-gadgets-new-life'
    },
    {
      title: 'A New Frontier for Green Investment',
      description: 'In these early days we are seeing huge growth in a number of sectors within what might be regarded as the ESG sphere, from renewables to gender equity to electric vehicles. Investors should look at the circular economy as a long-term investment.',
      date: 'Nov 25, 2022',
      url: 'https://www.esginvestor.net/a-new-frontier-for-green-investment/'
    },
    {
      title: 'Better Privacy Will Grow the Market for Pre-owned Electronics',
      description: 'The market for unused, pre-owned electronics in China is worth hundreds of billions of dollars, and steps that are being taken to improve the sector will help grow it even further.',
      date: 'Oct 17, 2022',
      url: 'https://itbusinessnet.com/2022/10/better-privacy-will-grow-the-market-for-pre-owned-electronics/'
    },
    {
      title: 'The Circular Economy as the Next Frontier for Asia’s Innovators',
      description: 'A blend of low market penetration, large market size and investors’ growing interest in ESG issues make the circular economy ripe for innovation.',
      date: 'Sep 27, 2022',
      url: 'https://e27.co/the-circular-economy-as-the-next-frontier-for-asias-innovators-20220926/'
    },
    {
      title: 'Disused Smartphones Ring Warning Note',
      description: 'These unused electronic devices, which contain toxic and harmful substances will pollute soil and groundwater if they are abandoned, posing a health risk. It is more climate- and eco-friendly if these phones are reused as second-hand products. ',
      date: 'Sep 6, 2022',
      url: 'https://www.chinadaily.com.cn/a/202209/06/WS631686cfa310fd2b29e76129_2.html'
    },
    {
      title: 'The Pre-owned Electronics Market Still Has Room to Develop and Grow',
      description: 'Kerry Chen talks about the market for second-hand electronics in China, technologies for preparing for reuse und future challenges for the industry.',
      date: 'Aug 8, 2022',
      url: 'https://www.recycling-magazine.com/2022/08/08/the-pre-owned-electronics-market-still-has-room-to-develop-and-grow/'
    },
    {
      title: 'ATRenew CEO’s Opinion: Building a circular economy business',
      description: 'Looking beyond COP26, we aspire to give a second life to all idle electronic devices.',
      date: 'Mar 31, 2022',
      url: 'https://resource-recycling.com/e-scrap/2022/03/31/in-my-opinion-building-a-circular-economy-business/'
    },
    {
      title: 'ATRenew Digs Closer To Profits With City-Level Recycling Drive',
      description: 'ATRenew reported its revenue rose 48.2% in last year’s fourth quarter, as it posted its first quarterly non-GAAP operating profit since listing in the U.S. last year. Company is boosting efficiency by doing more recycling at the city level, with a pilot localization program begun in two cities early last year now expanded to 22 cities.',
      date: 'Mar 17, 2022',
      url: 'https://seekingalpha.com/article/4496121-atrenew-digs-closer-to-profits-with-city-level-recycling-drive'
    },
    {
      title: 'Bloomberg Green: ATRenew CFO',
      description: 'Chen Chen, CFO at ATRenew, discusses the company\'s business strategy and the outlook for the \'circular economy\'. He speaks with Shery Ahn and Haidi Stroud-Watts on \'Bloomberg Daybreak: Asia\'. (Source: Bloomberg)',
      date: 'Jan 11, 2022',
      url: 'https://www.bloomberg.com/news/videos/2022-01-11/bloomberg-green-atrenew-cfo-video'
    },
    {
      title: 'ATRenew Inc.: Is There a Great Story to Tell?',
      description: 'Over the past decade, there has been a growing appetite among Chinese consumers for secondhand goods, and thus we’ve witnessed the rise of several secondhand goods platforms in the country, shaping and pushing forward the rapid expansion of the circular economy. Amongst those platforms, particularly in the electronics segment, ATRenew Inc. (NYSE: RERE), a leading technology-driven pre-owned consumer electronics transactions and services platform, has caught widespread attention from both the public and investors.',
      date: 'Nov 23, 2021',
      url: 'https://pandaily.com/atrenew-inc-is-there-a-great-story-to-tell/'
    },
    {
      title: 'AiHuiShou\'s Q2 Revenue Rises 56%, Fueled By Growing Electronics Recycling Network',
      description: 'That seems to be the message coming from electronics recycling specialist AiHuiShou International Co. Ltd., which has just conveniently released its first report on environment, social and governance, better known as ESG, alongside its maiden quarterly earnings.',
      date: 'Aug 21, 2021',
      url: 'https://www.benzinga.com/news/earnings/21/08/22606506/aihuishous-q2-revenue-rises-56-fueled-by-growing-electronics-recycling-network'
    },
    {
      title: 'AiHuiShou Seeks Gold in Growing Heaps of Used Gadgets',
      description: 'Company could be valued strongly due to big growth for its service offerings and international expansion potential',
      date: 'June 1, 2021',
      url: 'https://www.thebambooworks.com/aihuishou-seeks-gold-in-growing-heaps-of-used-gadgets/'
    },
    {
      title: 'JD.com-backed second hand goods platform AiHuiShou spots big opportunity in trade-in market',
      description: 'Chinese secondhand consumer electronics transactions and services platform AiHuiShou, which is valued at over $2.5 billion and counts e-commerce giant JD.com as its largest shareholder, is ramping up its expansion in both its home market and internationally.',
      date: 'April 5, 2021',
      url: 'https://www.dealstreetasia.com/stories/jd-backed-aihuishou-184791/'
    },
    {
      title: 'Used goods sector a growing phenomenon',
      description: 'Recycling economy becoming big hit among environmentally conscious youth, those wishing to make most of purchases',
      date: 'Feb 5, 2021',
      url: 'http://www.chinadaily.com.cn/a/202102/05/WS601cb27da31024ad0baa77ef.html'
    },
    {
      title: 'JD.com-backed recycling operator All Things Renew nets over $100m',
      description: 'Wanwu Xinsheng Group, formerly known as Aihuishou, has garnered more than $100 million in its Series E+ round of funding co-led by long-term backer JD.com and new investor Gruoai Junan International.',
      date: 'Sept 22, 2020',
      url: 'https://www.dealstreetasia.com/stories/wanwu-xinsheng-series-e-round-208126/'
    }
  ]
})

const data = {
  CN,
  US
}
export default data
