import { ReactNode, useEffect } from 'react'
import {withRouter, } from 'react-router-dom'
import { Location } from 'history'

interface Props {
  children: ReactNode
  location: Location
}
function ScrollToTop(props: Props) {
  useEffect(
    () => {
      if (document.getElementsByTagName('html').length > 0) {
        document.getElementsByTagName('html')[0].scrollIntoView({behavior: 'smooth'})
      }
    },

    [props.location.pathname]
  )
  return props.children
}

// @ts-ignore
export default withRouter(ScrollToTop)
