import React, {useMemo} from 'react'
import classNames from 'classnames'
import Section from '../../../../components/Section/Index'
import classes from './Operate.module.scss'
import {useLocale} from '../../../../locales'
import Units from './Units'

function Operate() {
  const locale = useLocale()
  const operate = useMemo(
    () => locale.pages.Business.Paijitang.operate,
    [locale.pages.Business.Paijitang.operate]
  )

  return (
    <Section.Full background='#F5F6FA'>
      <div className={classes.container}>
        <div className={classes.title}>
          { operate.title }
        </div>
        <div className={classes.main}>
          <img
            className={classNames(classes.cover, 'hidden-small')}
            src={operate.cover}
            alt={operate.title}
          />
          <div className={classes.info}>
            <div className={classes.description}>
              { operate.description }
            </div>
            <div className={classes.units}>
              <Units data={operate.units}/>
            </div>
            <div className={classes.tip}>
              {operate.tip}
            </div>
            <img
              className={classNames(classes.cover, 'hidden-large')}
              src={operate.cover}
              alt={operate.title}
            />
          </div>
        </div>
      </div>
    </Section.Full>
  )
}

export default Operate
