import React, {useMemo} from 'react'
import Section from '../../../../components/Section/Index'

import classes from './Service.module.scss'
import {useLocale} from '../../../../locales'

function Service() {
  const locale = useLocale()
  const service = useMemo(
    () => locale.pages.Business.Paijitang.service,
    [locale.pages.Business.Paijitang.service]
  )

  return (
    <Section
      name={service.sectionName}
      hideBottomLine
    >
      <div className={classes.main}>
        <img
          src={service.cover}
          className={classes.cover}
          alt=''
        />
        <div className={classes.infos}>
          {
            service.infos.map(ele => (
              <div className={classes.item}>
                <div className={classes.title}>
                  { ele.title }
                </div>
                <div className={classes.description}>
                  { ele.description }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Section>
  )
}

export default Service
