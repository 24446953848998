import React, {ReactNode, useEffect, useMemo, useRef} from 'react'
import Swiper, {SwiperRefNode} from 'react-id-swiper'
import classNames from 'classnames'
import classes from './Index.module.scss'

interface ItemProps {
  children: ReactNode
  className?: string
}

function SwiperItem(props: ItemProps) {
  return (
    <div className={classNames('swiper-slide', classes.swiperItem, props.className)}>
      {props.children}
    </div>
  )
}

type Children = ReturnType<typeof SwiperItem>

interface Props {
  onChange: (index: number) => void
  children: Array<Children>
  className?: string
}

function SwiperInstance(props: Props) {
  const {
    onChange,
    children,
    className
  } = props
  const swiperRef = useRef<SwiperRefNode>(null)
  const availableChildren = useMemo(
    () => children.filter(child => child.type === SwiperItem),
    [children]
  )
  useEffect(
    () => {
      const swiperInstance = swiperRef.current?.swiper
      if (swiperInstance) {
        swiperInstance.on('slideChange', () => {
          onChange(swiperInstance.activeIndex)
        })
      }

      return () => {
        if (swiperInstance) {
          swiperInstance.off('slideChange')
        }
      }
    },
    [
      onChange,
      availableChildren
    ])

  return (
    <div className={classes.swiper}>
      <Swiper
        ref={swiperRef}
        // loop={children.length > 1}
        autoplay={children.length > 1 ? {disableOnInteraction: false} : false}
        allowTouchMove={children.length > 1}
        containerClass={classNames(className, classes.swiperBox)}
      >
        {children}
      </Swiper>
    </div>
  )
}

SwiperInstance.Item = SwiperItem
export default SwiperInstance
