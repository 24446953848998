import React, { useMemo } from 'react'
import {useLocale} from '../../../locales'
import Section from '../../../components/Section/Index'
import IconItems from './IconItems'

import classes from './Privacy.module.scss'

export default function Privacy() {
  const locale = useLocale()
  const article = useMemo(
    () => locale.pages.ESG.product.aqc,
    [locale.pages.ESG.product.aqc]
  )

  return (
    <Section
      name={article.title}
      className={classes.Section}
      hideBottomLine
    >
      <div className={classes.Privacy}>
        <div className={classes.cover}>
          <img src={article.image.value} alt={article.title} />
        </div>
        <div className={classes.description}>
          <p>{article.content}</p>
          <div className={classes.iconItems}>
            <IconItems
              data={article.items}
              itemClassName={classes.iconItem}
              iconClassName={classes.icon}
              titleClassName={classes.title}
            />
          </div>
        </div>
      </div>
    </Section>

  )
}
