import React, {useMemo} from 'react'
import classNames from 'classnames'
import {useLocale} from '../../../../locales'
import Section from '../../../../components/Section/Index'

import classes from './Article.module.scss'

function Article() {
  const locale = useLocale()
  const article = useMemo(
    () => locale.pages.Business.Paipai.article,
    [locale.pages.Business.Paipai.article]
  )

  return (
    <Section name={article.sectionName}>
      <div className={classes.main}>
        <img
          src={article.cover}
          alt={article.sectionName}
          className={classNames('hidden-small', classes.cover)}
        />
        <div className={classes.info}>
          <div className={classes.description}>
            {article.description}
          </div>
          <img
            src={article.cover}
            alt={article.sectionName}
            className={classNames('hidden-large', classes.cover)}
          />
          <div className={classes.icons}>
            {
              article.icons.map(ele => (
                <div className={classes.iconItem}>
                  <img
                    src={ele.icon}
                    alt={ele.name}
                    className={classes.icon}
                  />
                  <span className={classes.iconName}>
                  {ele.name}
                </span>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Article
