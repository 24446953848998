import React from 'react'

import Seo from 'components/Head/Seo'
import About from './components/About'
import Banner from './components/Banner'
import Market from './components/Market'
import Difficult from './components/Difficult'
import News from './components/News'
import Performance from './components/Performance'
import PublicWelfare from './components/PublicWelfare'
import Main from './components/Main'

import { useLocale } from '../../locales'

import classes from './Index.module.scss'

function Home() {
  const locale = useLocale()
  return (
    <div className={classes.Home}>
      <Seo seo={locale.pages.Home.seo} />
      <Banner />
      <Main>
        <About />
        <Market />
        <Difficult />
        <News />
        <Performance />
        <PublicWelfare />
      </Main>
    </div>
  )
}

export default Home
