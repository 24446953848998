import React, {useMemo} from 'react'
import Section from '../../../../components/Section/Index'

import classes from './Manage.module.scss'
import {useLocale} from '../../../../locales'

function Manage() {
  const locale = useLocale()
  const manage = useMemo(
    () => locale.pages.Business.Paijitang.manage,
    [locale.pages.Business.Paijitang.manage]
  )

  return (
    <Section
      name={manage.sectionName}
    >
      <div className={classes.main}>
        <img src={manage.imageUrl} alt={manage.sectionName} />
      </div>
    </Section>
  )
}

export default Manage
