import React, {useMemo} from 'react'
import classNames from 'classnames'
import Section from './Section'
import LineSeparate from '../../../components/LineSeparate/Index'
import {useLocale} from '../../../locales'

import classes from './Market.module.scss'

function Header() {
  const locale = useLocale()
  const channel = useMemo(
    () => locale.pages.Home.channel,
    [locale.pages.Home.channel]
  )

  return (
    <div className={classes.sectionHeader}>
      <LineSeparate className={classNames('hidden-small', classes.icon)}/>
      <div className={classes.introduce}>
        <div className={classes.description}>
          {channel.header.description}
        </div>
        <LineSeparate className={classNames('hidden-large', classes.icon)}/>
        <div className={classes.keypoint}>
          {channel.header.keypoint}
        </div>
      </div>
    </div>
  )
}

function Market() {
  const locale = useLocale()
  const channel = useMemo(
    () => locale.pages.Home.channel,
    [locale.pages.Home.channel]
  )
  return (
    <Section
      name={channel.title}
      border
      header={
        <Header/>
      }
      mainClassName={classes.Section}
    >
      <div className={classes.main}>
        {
          channel.list.map(ele => (
            <div className={classes.container} key={ele.name.description}>
              <div className={classes.header}>
                <img
                  className={classes.icon}
                  src={ele.icon}
                  alt=''
                />
                <div className={classes.name}>
                  <span>{ele.name.channel}</span>
                  <span>{ele.name.description}</span>
                </div>
              </div>
              <div className={classes.body}>
                {
                  ele.list.map(item => (
                    <div className={classes.list} key={item.key}>
                      <div className={classes.listName}>
                        {item.key}
                      </div>
                      {
                        item.values
                          ? (
                            <div className={classes.values}>
                              <div className={classes.item}>
                                {
                                  item.values!.map(current => (
                                    <div key={current}>{current}</div>
                                  ))
                                }
                              </div>
                            </div>
                          )
                          : null
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
    </Section>
  )
}

export default Market
