import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import LocaleProvider, { getLocales, LocaleConfig, localeConfigProvider } from 'locales/index'
import { getValue as getScreenValue, ScreenProvider } from 'utils/screen'

import { useWindowResize } from './hooks/view'

import ScrollToTop from 'components/ScrollToTop/Index'
import AppHeader from 'components/AppHeader/Index'
import AppFooter from 'components/AppFooter/Index'
import Home from './pages/Home/Index'
import Innovation from './pages/Innovation/Index'
import ESGSocial from './pages/ESG/Social'
import Overall from './pages/ESG/Overall'
import Humanity from './pages/ESG/PeopleFirst'
import Environment from './pages/ESG/Environment'
import Product from './pages/ESG/Product'
import Reports from './pages/ESG/Reports'
import BusinesssAhs from './pages/Business/Ahs'
import BusinesssPaipai from './pages/Business/Paipai'
import BusinesssPaijitang from './pages/Business/Paijitang'
import BusinesssGlobal from './pages/Business/Global'
import Blog from './pages/News/Blog'
import Media from './pages/News/Media'
import BlogDetail from './pages/News/BlogDetail'
import NotFound from './pages/NotFound'

const PAD_BASE_WIDTH = 1360
const PC_CONTAINER_WIDTH = 1200
const MOBILE_CONTAINER_WIDTH = 768
const viewportElement = document.getElementById('viewport') as HTMLMetaElement

function App() {
  const locale = window.LANGUAGE
  const [screenValue, setScreenValue] = useState(getScreenValue())
  const [locales, setLocales] = useState<Array<LocaleConfig>>(getLocales())
  const screenType = useRef(getScreenValue().screenType)

  const resetViewportScale = useCallback(() => {
    if (!screenValue) return
    const { width } = screenValue
    if (width > MOBILE_CONTAINER_WIDTH && width < PAD_BASE_WIDTH) {
      const scale = (width / PAD_BASE_WIDTH).toFixed(5)
      viewportElement!.content = `width=${PC_CONTAINER_WIDTH}, initial-scale=${scale},user-scalable=no`
    } else {
      viewportElement!.content = `width=device-width, initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no`
    }
    if (!window.fixedScrollPosition) {
      window.fixedScrollPosition = true
      requestAnimationFrame(() => {
        window.scrollTo(0, 0)
      })
    }
  }, [screenValue])
  resetViewportScale()

  useWindowResize(() => {
    const newScreenValue = getScreenValue()
    setScreenValue(newScreenValue)

    const newScreenType = newScreenValue.screenType
    if (newScreenType !== screenType.current) {
      screenType.current = newScreenType
      setLocales(getLocales())
    }

    resetViewportScale()
  })
  useEffect(
    () => {
      window.dispatchEvent(new Event('resize'))
    },
    []
  )
  const localeConfig = useMemo(() => {
    const config = locales?.find(l => l.code === locale)
    if (config) return config
    return localeConfigProvider.zhCN()
  }, [locale, locales])

  const localeOptions = useMemo(() => locales.map(l => ({
    label: l.label,
    code: l.code as Locale
  })), [locales])

  const handleSwitchLocale = useCallback((toLocale: Locale) => {
    if (locale === toLocale) return
    const pathname = window.location.pathname.replace(locale, toLocale)
    window.location.replace(`${pathname}${window.location.search}`)
    resetViewportScale()
  }, [locale, resetViewportScale])
  return (
    <ScreenProvider value={screenValue}>
      <LocaleProvider value={{
        locales: localeOptions,
        locale,
        localeConfig,
        switchLocale: handleSwitchLocale
      }}>
        <Router basename={window.basename}>
          <ScrollToTop>
            <div>
              <AppHeader />
              <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/innovation' exact component={Innovation} />
                <Route path='/esg/responsibility' exact component={ESGSocial} />
                <Route path='/esg/overall' exact component={Overall} />
                <Route path='/esg/humanity' exact component={Humanity} />
                <Route path='/esg/environment' exact component={Environment} />
                <Route path='/esg/product' exact component={Product} />
                <Route path='/esg/reports' exact component={Reports} />
                <Route path='/news/blog' exact component={Blog} />
                <Route path='/news/blog/:articleId' exact component={BlogDetail} />
                <Route path='/news/media' exact component={Media} />
                <Route path='/business/aihuishou' exact component={BusinesssAhs} />
                <Route path='/business/paipai' exact component={BusinesssPaipai} />
                <Route path='/business/paijitang' exact component={BusinesssPaijitang} />
                <Route path='/business/global' exact component={BusinesssGlobal} />
                <Route path='/not-found' exact component={NotFound} />
                <Route>
                  <NotFound />
                </Route>
              </Switch>
              <AppFooter />
            </div>
          </ScrollToTop>
        </Router>
      </LocaleProvider>
    </ScreenProvider>
  )
}

export default App
