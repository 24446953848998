import React, {useMemo} from 'react'
import classNames from 'classnames'
import classes from './Index.module.scss'

interface Props {
  total: number
  activeIndex: number
  className?: string
}

export default function Dots(props: Props) {
  const {
    total,
    activeIndex,
    className
  } = props
  const list = useMemo(
    () => {
      return [...new Array(total)]
    },
    [total]
  )
  return (
    list.length > 1
      ? (
        <div className={classNames(classes.dots, className)}>
          {
            list.map((_, i) => (
              <div
                className={classNames(
                  classes.dot,
                  {[classes.active]: i === activeIndex})
                }
              />
            ))
          }
        </div>
      )
      : null
  )
}
