import { ossImg } from "utils/oss"
import { getValue } from "utils/view"

const CN = () => ({
  seo: {
    title: 'ESG以人为本 - 万物新生',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网',
    description: '万物新生坚持以人为本,通过完善的用人政策、员工赋能与福利体系,营造安全,健康,公平,温暖的工作环境.',
  },
  breadcrumb: '首页 / ESG / 以人为本',
  banner: getValue({
    large: ossImg('/esg/overall-banner.png'),
    small: ossImg('/esg/esg-m-banner.png'),
  }),
  humanity: {
    title: "以人为本",
    content: '万物新生视人才为企业发展的动力，重视每一位员工在工作和发展中得到能力的提升和自我价值的实现。通过制定完善的雇佣政策，设置有竞争力的薪酬体系，全面保障员工权益。我们通过开设多元培训赋能员工成长和举办丰富的员工活动，营造安全、健康、公平、温暖的工作环境，推动员工与万物新生携手共同成长。',
  },
  employees: {
    title: "员工发展",
    content:
      "万物新生以建设最优秀的团队为目的，通过对员工的培训与发展，帮助员工成长和提升公司的整体业绩。本集团分设三类培训: 新员工入职培训，内部培训和外部培训。在员工成长的不同阶段，对接多种资源，不断帮助员工持续提高知识和技能。",
    grid: [
      {
        image: ossImg('/esg/humanity-employees1.png'),
        title: "万物新生管理培训生计划",
      },
      {
        image: ossImg('/esg/humanity-employees2.png'),
        title: "万物新生运营中心培训举措",
      },
      {
        image: ossImg('/esg/humanity-employees3.png'),
        title: "万物新生线上知识平台",
      },
    ],
  },
  health: {
    title: '员工健康',
    article: {
      title: '万物新生抗“疫”行动',
      content: '在 2020 年的 COVID-19 疫情期间，万物新生积极配合中国政府和各地区部门的防疫要求和指引文件，第一时间成立疫情防控工作小组，部署了专门的防疫机制，启动了应急防疫预案。本集团全面升级了上门员工、集团办公区域及全国门店的卫生防护标准，采购 77 万余元人民币的防疫自用物资，保护每一位员工和用户的生命健康。',
      image: { value: ossImg('/esg/humanity-anti-epidemic.png') },
    },
  },
  care: {
    title: '员工工会参与',
    content: '万物新生持续完善员工工会参与体系，建立健全员工福利机制，提供解决实际问题的支持，定期举办多元丰富的团队活动，让工作更快乐、更平衡，提升员工幸福感。',
    grid: [
      {
        image: ossImg('/esg/humanity-care1.png'),
        title: "员工购房借款计划",
      },
      {
        image: ossImg('/esg/humanity-join.png'),
        title: "员工工会参与",
      },
      {
        image: ossImg('/esg/humanity-listener.png'),
        title: "倾听官项目",
      },
    ],
  },
  build: {
    title: '员工活动与团队建设',
    images: [
      { value: ossImg('/esg/humanity-activity8.png') },
      { value: ossImg('/esg/humanity-activity2.png') },
      { value: ossImg('/esg/humanity-activity3.png') },
      { value: ossImg('/esg/humanity-activity5.png') },
      { value: ossImg('/esg/humanity-activity6.png') },
      { value: ossImg('/esg/humanity-activity7.png') },
    ]
  }
})

const US = () => ({
  seo: {
    title: 'ESG Our People - ATRenew',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai',
    description: 'ATRenew respects employees\' rights and strives to provide a safe, healthy, and welcoming working environment.',
  },
  breadcrumb: 'Home / ESG / Our People',
  banner: getValue({
    large: ossImg('/esg/overall-banner.png'),
    small: ossImg('/esg/esg-m-banner.png'),
  }),
  humanity: {
    title: "Our People",
    content: "ATRenew recognizes every employee's realization of self-worth throughout his or her career. We fully protect the rights and interests of our employees and strive to provide a safe, healthy, and welcoming working environment. Through doing so, we invest in our employees’ welfare and encourage them to grow together with us.",
  },
  employees: {
    title: "Employee Development",
    content:
      "ATRenew strives to establish the finest team possible by empowering employees to grow and improve their performance through training and development. We offer three kinds of training: onboard training, internal training, and external training. A variety of materials are available to assist employees improve their skills at various levels of development.",
    grid: [
      {
        image: ossImg('/esg/humanity-employees1.png'),
        title: "Management Trainee Program",
      },
      {
        image: ossImg('/esg/humanity-employees2.png'),
        title: "Training at Operation Centers",
      },
      {
        image: ossImg('/esg/humanity-employees3.png'),
        title: "Online Knowledge Platform",
      },
    ],
  },
  health: {
    title: 'Health and Safety',
    article: {
      title: 'Mobilizing Against COVID-19',
      content: 'During the COVID-19 pandemic in 2020, we responded actively by creating a special task force, altering our working mechanisms, and initiating an emergency plan as required and instructed by the Chinese government and regional departments. For the safety and health of every employee and customer, we purchased more than RMB700,000 of pandemic prevention materials for employees. We also comprehensively escalated our health standards for employees to offer door-to-door services, as well as increased sanitation at our offices and offline stores around the country.',
      image: { value: ossImg('/esg/humanity-anti-epidemic.png') },
    },
  },
  care: {
    title: 'Employee Wellbeing',
    content: 'ATRenew cares for its employees by establishing a sound employee welfare mechanism, assisting in resolving practical difficulties and regularly organizing team-building activities. In doing so, we make work more enjoyable and balanced, improving employee overall wellbeing.',
    grid: [
      {
        image: ossImg('/esg/humanity-care1.png'),
        title: "Employee housing fund",
      },
      {
        image: ossImg('/esg/humanity-join.png'),
        title: "Trade Union",
      },
      {
        image: ossImg('/esg/humanity-listener.png'),
        title: "Listener Program",
      },
    ],
  },
  build: {
    title: 'Staff Activities and Team Building',
    images: [
      { value: ossImg('/esg/humanity-activity8.png') },
      { value: ossImg('/esg/humanity-activity2.png') },
      { value: ossImg('/esg/humanity-activity3.png') },
      { value: ossImg('/esg/humanity-activity5.png') },
      { value: ossImg('/esg/humanity-activity6.png') },
      { value: ossImg('/esg/humanity-activity7.png') },
    ]
  }
})

const data = {
  CN,
  US
}
export default data
