import React, {useMemo} from 'react'

import {useLocale} from '../../../locales'
import HighlightWord from '../../../components/HighlightWord/Index'

import classes from './Performance.module.scss'

function Performance() {
  const locale = useLocale()
  const performance = useMemo(
    () => locale.pages.Home.performance,
    [locale.pages.Home.performance]
  )

  return (
    <div className={classes.performance}>
      <div className={classes.header}>
        {performance.title}
      </div>
      <div className={classes.container}>
        <div className={classes.main}>
          {
            performance.list.map(ele => (
              <div className={classes.item} key={ele.firstLine}>
                <div className={classes.firstline}>
                  <HighlightWord
                    keyword={ele.keywords.first}
                    highlightClassName={classes.keyword}
                  >
                    { ele.firstLine }
                  </HighlightWord>
                </div>
                <div className={classes.lastline}>
                  <HighlightWord
                    keyword={ele.keywords.last}
                    highlightClassName={classes.keyword}
                  >
                    { ele.lastLine }
                  </HighlightWord>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Performance
