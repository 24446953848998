import Home from './pages/Home'
import BusinessAhs from './pages/Business/Ahs'
import BusinessPaipai from './pages/Business/Paipai'
import BusinessPaijitang from './pages/Business/Paijitang'
import BusinessGlobal from './pages/Business/Global'
import Innovation from './pages/Innovation/Index'
import ESGOverall from './pages/ESG/overall'
import ESGSocial from './pages/ESG/social'
import ESGHumanity from './pages/ESG/people'
import ESGEnvironment from './pages/ESG/environment'
import ESGProduct from './pages/ESG/product'
import ESGReports from './pages/ESG/reports'
import Blog from './pages/News/Blog'
import Media from './pages/News/Media'

function getLocale() {
  return {
    code: 'zh-cn',
    logo: require('../assets/logo-dark.png'),
    label: '中文',
    title: '万物新生',
    footer: {
      groups: [{
        title: '品牌业务',
        links: [{
          text: '爱回收',
          link: 'https://www.aihuishou.com/',
          bold: false,
          block: false,
          scrollTop: false,
        }, {
          text: '拍机堂',
          link: 'https://sj.aihuishou.com/',
          bold: false,
          block: false,
          scrollTop: false,
        }, {
          text: '拍拍',
          link: 'https://www.paipai.com/',
          bold: false,
          block: false,
          scrollTop: false,
        }, {
          text: '海外业务',
          link: 'https://www.ahsdevice.com/',
          bold: false,
          block: false,
          scrollTop: false,
        }]
      }, {
        title: '新闻动态',
        links: [
          {
            text: '企业博客',
            link: '/news/blog',
            bold: false,
            block: false,
            scrollTop: true,
          },
          {
            text: '媒体报道',
            link: '/news/media',
            bold: false,
            block: false,
            scrollTop: true,
          },
          {
            text: '技术能力',
            link: '/innovation',
            bold: true,
            block: false,
            scrollTop: true,
          },
          {
            text: '投资者关系',
            link: 'https://ir.atrenew.com/',
            bold: true,
            block: false,
            scrollTop: false,
          }
        ]
      }, {
        title: 'ESG',
        links: [{
          text: 'ESG概览',
          link: '/esg/overall',
          bold: false,
          block: false,
          scrollTop: true,
        }, {
          text: '产品服务',
          link: '/esg/product',
          bold: false,
          block: false,
          scrollTop: true,
        }, {
          text: '绿色发展',
          link: '/esg/environment',
          bold: false,
          block: false,
          scrollTop: true,
        }, {
          text: '以人为本',
          link: '/esg/humanity',
          bold: false,
          block: false,
          scrollTop: true,
        }, {
          text: '社会责任',
          link: '/esg/responsibility',
          bold: false,
          block: false,
          scrollTop: true,
        }, {
          text: 'ESG报告及政策',
          link: '/esg/reports',
          bold: false,
          block: false,
          scrollTop: true,
        }]
      }, {
        title: '联系方式',
        links: [{
          text: '总部地址：上海市杨浦区淞沪路433号6号楼12楼',
          block: true,
          bold: false,
          link: '',
          scrollTop: false,
        }, {
          text: '一般咨询：+86 21 5290-7031',
          block: true,
          bold: false,
          link: '',
          scrollTop: false,
        }, {
          text: '投资者关系：ir@atrenew.com',
          block: true,
          bold: false,
          link: '',
          scrollTop: false,
        }, {
          text: '公共关系：pr@atrenew.com',
          block: true,
          bold: false,
          link: '',
          scrollTop: false,
        }, {
          text: '品牌合作：brand@atrenew.com',
          block: true,
          bold: false,
          link: '',
          scrollTop: false,
        }]
      }],
      copyRights: [{
        text: 'Copyright 2010 - %YEAR% 上海万物新生环保科技集团有限公司',
        link: 'https://www.atrenew.com/resources/home/atrenew.com.certificate.png'
      }],
      securityLinks: [{
        text: '沪ICP备10043802号-13',
        link: 'https://beian.miit.gov.cn/'
      }, {
        icon: require('./assets/icons/icon-beian.png'),
        text: '沪公网安备 31011002006975 号',
        link: 'https://beian.mps.gov.cn/#/query/webSearch?code=31011002006975'
      }, {
        text: '违法不良信息举报电话：0519-88236605',
        link: undefined
      }, {
        text: '上海市互联网举报中心',
        link: 'http://www.shjbzx.cn'
      }, {
        text: '|',
        link: '',
      }, {
        text: '网上有害信息举报专区',
        link: 'https://www.12377.cn'
      }]
    },
    pages: {
      Business: {
        title: '品牌业务',
        Ahs: BusinessAhs.CN(),
        Paipai: BusinessPaipai.CN(),
        Paijitang: BusinessPaijitang.CN(),
        Global: BusinessGlobal.CN(),
        business: [
          {
            name: '爱回收',
            link: '/business/aihuishou'
          },
          {
            name: '拍机堂',
            link: '/business/paijitang'
          },
          {
            name: '拍拍',
            link: '/business/paipai'
          },
          {
            name: 'AHS DEVICE',
            link: '/business/global'
          }
        ],
      },
      Innovation: Innovation.CN(),
      News: {
        title: '新闻动态',
        menus: [
          {
            name: '企业博客',
            link: '/news/blog'
          },
          {
            name: '媒体报道',
            link: '/news/media'
          }
        ],
      },
      Investors: {
        title: '投资者',
        menus: [
          {
            name: 'Investor Home',
            link: 'https://ir.atrenew.com/',
          },
          {
            name: 'News Releases',
            link: 'https://ir.atrenew.com/news-releases',
          },
          {
            name: 'Financial Information ',
            link: '',
            subMenu: [
              {
                name: 'SEC Filings',
                link: 'https://ir.atrenew.com/financial-information/sec-filings',
              },
              {
                name: 'Quarterly Results',
                link: 'https://ir.atrenew.com/financial-information/quarterly-results',
              },
              {
                name: 'Annual Reports',
                link: 'https://ir.atrenew.com/financial-information/annual-reports',
              },
              {
                name: 'Analyst Coverage',
                link: 'https://ir.atrenew.com/financial-information/analyst-coverage',
              },
            ],
          },
          {
            name: 'Stock Information',
            link: 'https://ir.atrenew.com/stock-information/stock-quote',
          },
          {
            name: 'Investor Events',
            link: '',
            subMenu: [
              {
                name: 'Event Calendar',
                link: 'https://ir.atrenew.com/investor-events/event-calendar',
              },
              {
                name: 'Webcasts and Presentations',
                link: 'https://ir.atrenew.com/investor-events/webcasts-presentations',
              },
            ],
          },
          {
            name: 'Corporate Governance',
            link: '',
            subMenu: [
              {
                name: 'Letter from CEO',
                link: 'https://ir.atrenew.com/corporate-governance/letter-from-ceo',
              },
              {
                name: 'Management',
                link: 'https://ir.atrenew.com/corporate-governance/management',
              },
              {
                name: 'Board of Directors',
                link: 'https://ir.atrenew.com/corporate-governance/board-of-directors',
              },
            ],
          },
          {
            name: 'Resources',
            link: '',
            subMenu: [
              {
                name: 'Investor FAQs',
                link: 'https://ir.atrenew.com/resources/investor-faqs',
              },
              {
                name: 'Email Alerts',
                link: 'https://ir.atrenew.com/resources/email-alerts',
              },
              {
                name: 'IR Contacts',
                link: 'https://ir.atrenew.com/resources/ir-contacts',
              },
            ],
          },
        ],
      },
      ESG: {
        title: 'ESG',
        link: '/esg',
        menus: [
          {
            name: 'ESG概览',
            link: '/esg/overall'
          },
          {
            name: '产品服务',
            link: '/esg/product'
          },
          {
            name: '绿色发展',
            link: '/esg/environment'
          },
          {
            name: '以人为本',
            link: '/esg/humanity'
          },
          {
            name: '社会责任',
            link: '/esg/responsibility'
          },
          {
            name: 'ESG报告及政策',
            link: '/esg/reports'
          }
        ],
        overall: ESGOverall.CN(),
        social: ESGSocial.CN(),
        humanity: ESGHumanity.CN(),
        environment: ESGEnvironment.CN(),
        product: ESGProduct.CN(),
        reports: ESGReports.CN(),
      },
      Home: Home.CN(),
      Blog: Blog.CN(),
      Media: Media.CN()
    }
  }
}

export default getLocale
