import React, { useMemo } from 'react'
import { useLocale } from '../../locales'
import Seo from '../../components/Head/Seo'

import Article from '../../components/Article/Index'
import Breadcrumb from '../../components/Breadcrumb/Index'
import Divider from '../../components/Divider/Index'
import Units from '../Business/components/Paijitang/Units'
import Banner from './components/Banner'

import classes from './Social.module.scss'

function Index() {
  const locale = useLocale()
  const page = useMemo(
    () => locale.pages.ESG.social,
    [locale.pages.ESG.social]
  )
  return (
    <div className={classes.Index}>
      <Seo seo={locale.pages.ESG.social.seo} />
      <Banner />
      <Breadcrumb
        title={page.breadcrumb}
        className={classes.breadcrumb}
      />
      <div className={classes.sectionA}>
        <Article title={page.social.title}>
          {page.social.content}
        </Article>
        <Divider className={classes.divider} />
        <Article
          title={page.join.title}
          containerClassName={classes.article}
          imageClassName={classes.joinImg}
          images={page.join.images}
        >
          {
            page.join.content.map(item => (
              <p className={classes.content} key={item}>
                {item}
              </p>
            ))
          }
        </Article>
        <Divider className={classes.divider} />
        <strong className={classes.title}>{page.commonweal.title}</strong>
      </div>
      <div className={classes.sectionB}>
        <div className={classes.box}>
          <Article
            title={page.commonweal.donates.title}
            images={[page.commonweal.donates.image]}
            imageClassName={classes.image}
          >
            {page.commonweal.donates.content.map(
              paragraph => (<p key={paragraph}>{paragraph}</p>)
            )}
          </Article>
          <Article
            swiper
            title={page.commonweal.recycling.title}
            images={page.commonweal.recycling.images}
            imageClassName={classes.image}
            swiperClassName={classes.images}
            containerClassName={classes.commonweal}
          >
            <strong className={classes.subTitle}>
              {page.commonweal.recycling.subTitle}
            </strong>
            <p className={classes.content}>
              {page.commonweal.recycling.content}
            </p>
            <Units
              className={classes.utils}
              data={page.commonweal.recycling.units}
            />
            <span className={classes.tips}>
              {page.commonweal.recycling.tips}
            </span>
          </Article>
        </div>
      </div>
    </div>
  )
}

export default Index
