import React from 'react'
import { NavLink as ReactLink, NavLinkProps } from 'react-router-dom'

function isRelative(url: string) {
  return url && url.slice(0, 1) === '/' && url.slice(1, 2) !== '/'
}

function getRootDomain(host: string) {
  if (!host) return ''
  return host.split('.').slice(-2).join('.')
}

function isOuterLink(url: string) {
  const link = document.createElement('a')
  link.href = url

  const root = getRootDomain(process.env.REACT_APP_ORIGIN!)
  return getRootDomain(link.host) !== root
}

export interface LinkProps extends NavLinkProps {
  children?: React.ReactNode
}

function Link(props: LinkProps) {
  const {
    to,
    children,
    ...otherProps
  } = props
  if (!to) return <a {...otherProps}>{children}</a>
  if (typeof to === 'string') {
    if (isRelative(to)) {
      return (
        <ReactLink to={to}  {...otherProps}>{children}</ReactLink>
      )
    }
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={to}
        rel='nofollow noopener'
        target={isOuterLink(to) ? '_blank' : ''}
        {...otherProps}
      >
        {children}
      </a>
    )
  }
  return <ReactLink to={to}  {...otherProps}>{children}</ReactLink>
}

export default Link