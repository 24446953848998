import LocaleProvider, { useLocale, useLocaler, LocaleConfig as OriginLocaleConfig } from './context'
import zhCN from './zh-cn'
import enUS from './en-us'

export type LocaleConfig = OriginLocaleConfig

const localeConfigProvider = {
  zhCN,
  enUS,
}

export function getLocales() {
  return [
    enUS(),
    zhCN(),
  ]
}
export {
  LocaleProvider,
  useLocaler,
  useLocale,
  localeConfigProvider,
}

export default LocaleProvider