import {ossImg} from '../../../utils/oss'

const CN = () => ({
  seo: {
    title: '爱回收 - 万物新生',
    description: '爱回收:万物新生旗下二手3C产品C2B回收平台, 通过优质的换新场景获取货源,积累供应链能力.',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网'
  },
  banner: {
    logo: require('../../../assets/logo-business-ahs.png'),
    name: '',
    position: '二手3C产品C2B回收平台',
    introduction: '通过优质的换新场景获取货源，积累供应链能力'
  },
  breadcrumb: '首页/品牌业务/爱回收',
  about: {
    cover: ossImg('/business/ahs/ahs.png'),
    infos: [
      [
        {
          type: 'h1',
          text: '多场景回收保障货源'
        },
        {
          type: 'subTitle',
          text: '传统行业面临的问题：消费者缺乏值得信赖及便捷的回收渠道，对回收电子产品有隐私安全的顾虑'
        },
        {
          type: 'description',
          text: '中国的二手手机3C行业已经是一个数千亿规模的市场，但回收渠道分散，主要在线下，产业链长、回收层级多导致加价率高。用户在回收过程中经常遇到欺诈、隐私泄露，对服务缺乏信任。'
        },
      ],
      [
        {
          type: 'h3',
          text: '积累货源与二手3C供应链能力，与主流电商平台及手机品牌合作达成覆盖全国的以旧换新服务网络。'
        },
        {
          type: 'icons',
          layoutType: 'horizontal',
          list: [
            {
              icon: require('../../assets/business/Ahs/icon-group-1.1.png'),
              name: '专业',
            },
            {
              icon: require('../../assets/business/Ahs/icon-group-1.2.png'),
              name: '高效',
            },
            {
              icon: require('../../assets/business/Ahs/icon-group-1.3.png'),
              name: '安全',
            },
          ]
        },
        {
          type: 'subTitle',
          text: '回收场景：<br />爱回收门店、上门回收、官网、APP、MTA自助回收机'
        },
        {
          type: 'subTitle',
          text: '合作伙伴矩阵：<br />京东战略投资伙伴、苹果、华为、荣耀、小米、OPPO、vivo、大疆等'
        },
      ],
    ]
  },
  steps: [
    {
      icon: require('assets/business-ahs-icon-1.png'),
      name: '自助估价'
    },
    {
      icon: require('assets/business-ahs-icon-2.png'),
      name: '店员质检'
    },
    {
      icon: require('assets/business-ahs-icon-3.png'),
      name: '数据迁移'
    },
    {
      icon: require('assets/business-ahs-icon-4.png'),
      name: '隐私清除'
    },
    {
      icon: require('assets/business-ahs-icon-5.png'),
      name: '回收成功'
    },
  ],
  blocks: [
    {
      title: '门店覆盖全国',
      imagePosition: 'right',
      description: '我们相信回收需要通过面对面交付提升用户满意度，而以旧换新是十分有效的转化场景。爱回收在全国布局门店，构建用户服务能力，我们坚持从用户中来、到用户中去，提供高品质的即时服务。',
      cover: ossImg('/business/ahs/stores.jpeg'),
      info: {
        type: 'text',
        title: '',
        list: [
          {
            name: '自营门店',
            unit: '个',
            value: '698',
          },
          {
            name: '联营门店',
            unit: '个',
            value: '1,121',
          },
          {
            name: '覆盖城市',
            unit: '个',
            value: '268',
          },
        ]
      },
      note: '注：截至 2023 年 12 月 31 日'
    },
    {
      title: '服务能力，不止于旧机回收',
      subTitle: '',
      note: '',
      imagePosition: 'left',
      description: '爱回收打通线上下单+线下回收交付，一站式以旧换新场景，在线下面对面交付时，确保消费者隐私安全的同时，协助用户迁移数据，并提供多种便捷的增值服务、配件销售。让用户的换机过程轻松愉快。',
      cover: ossImg('/business/ahs/service.png'),
      info: {
        type: 'icons',
        title: '',
        services: [
          {
            icon: require('../../assets/business/Ahs/icon-group-2.1.png'),
            name: '数据迁移'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.2.png'),
            name: '隐私清除'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.3.png'),
            name: '现场分级与定价'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.4.png'),
            name: '手机意外险'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.5.png'),
            name: '手机快修'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.6.png'),
            name: '手机配件'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.7.png'),
            name: '充电宝租赁'
          },
        ]
      }
    },
    {
      title: '爱回收官网/APP',
      note: '',
      imagePosition: 'right',
      bigImage: true,
      description: '除了在京东和手机品牌官网下单，用户可以使用官方直营的回收平台，线上进行旧机估价，并选择门店回收、上门回收、快递回收任意一种方式回收旧机。',
      cover: ossImg('/business/ahs/website.png'),
    },
    {
      title: '自助回收机',
      note: '',
      imagePosition: 'left',
      description: '我们将自主研发的自助回收机铺设在第三方合作伙伴的门店中，由店员使用自助回收机质检回收，配合风控体系，平台当场预付回收款，三天内完成后端质检流程并销售出货。',
      cover: ossImg('/business/ahs/auto-recycle.png'),
      info: {
        type: 'text',
        title: '',
        list: [
          {
            name: '自助回收机台数',
            unit: '台',
            value: '2,000+',
            description: '注：截至 2023 年 12 月 31 日'
          }
        ]
      }
    },
  ],
  channel: {
    sectionName: '合作渠道',
    list: [
      {
        title: '京东一站换新',
        subTitle: '满足消费者换新需求，提供便捷的“一站式换新”即旧机抵扣+新机购买的服务。',
        description: '爱回收与京东基于多年的紧密合作，搭建一站式以旧换新，同时支持多件换新。消费者在京东购买手机3C产品时，可以在醒目的位置选择换新方案，旧机验机估价，并在用户下单时直接进行抵扣，让用户获得更经济、更便捷的购机体验。',
        cover: ossImg('/business/ahs/channel.png'),
      }
    ]
  }
})

const US = () => ({
  seo: {
    title: 'AHS Recycle - ATRenew',
    description: 'AHS Recycle - ATRenew\'s C2B recycling and trade-in platform - aggregating supply from consumers.',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai'
  },
  banner: {
    logo: require('../../../assets/logo-business-ahs.png'),
    name: 'AHS Recycle',
    position: 'C2B recycling and trade-in platform',
    introduction: 'Aggregating consumer supply sources and building our supply chain capacity'
  },
  breadcrumb: 'Home / Business / AHS Recycle',
  about: {
    cover: ossImg('/business/ahs/ahs.png'),
    infos: [
      [
        {
          type: 'h1',
          text: 'Accumulating supply across diverse consumer markets'
        },
        {
          type: 'subTitle',
          text: 'The conventional model of consumption has a glaring shortcoming: consumers need safe, reliable and convenient recycling channels.'
        },
        {
          type: 'description',
          text: 'The pre-owned consumer electronics industry in China is enormous but fragmented. Scattered recycling channels with intermediaries at every level create inefficiency throughout the value chain. Consumers are plagued by fraud and data security concerns.'
        },
      ],
      [
        {
          type: 'h3',
          text: 'Building pre-owned consumer electronics supply chain capabilities. Partnering with e-commerce platforms and smartphone brands to establish a nationwide trade-in service network.'
        },
        {
          type: 'icons',
          layoutType: 'horizontal',
          list: [
            {
              icon: require('../../assets/business/Ahs/icon-group-1.1.png'),
              name: 'Professional',
            },
            {
              icon: require('../../assets/business/Ahs/icon-group-1.2.png'),
              name: 'Efficient',
            },
            {
              icon: require('../../assets/business/Ahs/icon-group-1.3.png'),
              name: 'Safe',
            },
          ]
        },
        {
          type: 'subTitle',
          text: 'Sourcing: <br />AHS Stores, pick-up services, AHS website & app, self-service kiosks'
        },
        {
          type: 'subTitle',
          text: 'Partner network: <br />JD Group, Apple, Huawei, Honor, Xiaomi, OPPO, vivo, DJI, etc.'
        },
      ],
    ]
  },
  steps: [
    {
      icon: require('assets/business-ahs-icon-1.png'),
      name: 'Self-service evaluation'
    },
    {
      icon: require('assets/business-ahs-icon-2.png'),
      name: 'In-store inspection'
    },
    {
      icon: require('assets/business-ahs-icon-3.png'),
      name: 'Data migration'
    },
    {
      icon: require('assets/business-ahs-icon-4.png'),
      name: 'Data-wiping'
    },
    {
      icon: require('assets/business-ahs-icon-5.png'),
      name: 'Successful recycling'
    },
  ],
  blocks: [
    {
      title: 'National coverage of AHS stores',
      imagePosition: 'right',
      description: 'We believe in face-to-face delivery to ensure premium consumer experience and that trade-in is an effective scenario for recycling devices. AHS has established its core consumer service capabilities through its network of stores nationwide, offering high quality on-demand service.',
      cover: ossImg('/business/ahs/stores.jpeg'),
      info: {
        type: 'text',
        title: '',
        list: [
          {
            name: 'Self-operated AHS stores',
            unit: '',
            value: '698',
          },
          {
            name: 'Jointly-operated AHS stores',
            unit: '',
            value: '1,121',
          },
          {
            name: 'Cities covered by store network',
            unit: '',
            value: '268',
          },
        ]
      },
      note: 'Note: As of December 31, 2023'
    },
    {
      title: 'Beyond recycling pre-owned devices',
      note: '',
      subTitle: 'Services at AHS stores',
      imagePosition: 'left',
      description: 'AHS integrates online ordering + offline recycling to build its one-stop trade-in scenario. In-store staff ensure that consumers are protected by assisting with data migration and wiping. They also provide a variety of value-added services to support consumers during the trade-in process.',
      cover: ossImg('/business/ahs/service.png'),
      info: {
        type: 'icons',
        title: 'Services at AHS stores',
        services: [
          {
            icon: require('../../assets/business/Ahs/icon-group-2.1.png'),
            name: 'Data migration'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.2.png'),
            name: 'Data wiping'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.3.png'),
            name: 'Inspection, grading and pricing'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.4.png'),
            name: 'Instant repair'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.7.png'),
            name: 'Power bank rental'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.6.png'),
            name: 'Mobile device insurance'
          },
          {
            icon: require('../../assets/business/Ahs/icon-group-2.5.png'),
            name: 'Mobile device accessories'
          },
        ]
      }
    },
    {
      title: 'AHS website / APP',
      note: '',
      imagePosition: 'right',
      bigImage: true,
      description: 'In addition to placing online orders through JD.com or a manufacturer’s website, consumers can access our recycling services via AHS Recycle’s website and APP. Consumers can see preliminary pricing before choosing to recycle with AHS and orders are supported by pick-up service or express mail.',
      cover: ossImg('/business/ahs/website.png'),
    },
    {
      title: 'Self-service kiosks',
      note: '',
      imagePosition: 'left',
      description: 'Our proprietary self-service kiosks are located in third-party stores where consumers are assisted to inspect and recycle their devices. Powered by our risk management system, consumers get instant pre-payment on site, and devices are inspected and processed within three days by our operation centers.',
      cover: ossImg('/business/ahs/auto-recycle.png'),
      info: {
        type: 'text',
        title: '',
        list: [
          {
            name: 'Number of self-service kiosks nationwide',
            unit: '',
            value: '2,000+',
            description: 'Note: As of December 31, 2023'
          }
        ]
      }
    },
  ],
  channel: {
    sectionName: 'Channel partners',
    list: [
      {
        title: 'JD – One-stop Trade-in',
        subTitle: 'Convenient trade-in solution',
        description: 'Through partnership with JD Group, AHS Recycle provides one-stop trade-in and multi-device trade-in services to consumers for a more convenient and efficient electronic device shopping experience. Access details are conveniently located on the product display and check-out page.',
        cover: ossImg('/business/ahs/channel.png'),
      },
    ]
  }
})

const data = {
  CN,
  US
}
export default data
