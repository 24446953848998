import React, { ReactNode, useState, useEffect, useRef } from 'react'
import classNames from 'classnames'

import Icon from 'components/Icon/Index'
import Video from 'components/Video/Index'
import Swiper, { SwiperRefNode} from 'react-id-swiper'

import classes from './Index.module.scss'

interface ImageItem {
  name?: string
  value: string
}

interface ArticleProps {
  title: string
  video?: string
  children: ReactNode
  images?: ImageItem[]
  swiper?: boolean
  titleClassName?: string
  childClassName?: string
  imageClassName?: string
  swiperClassName?: string
  imagesClassName?: string
  containerClassName?: string
  openVideo?: () => void
}

function Index(props: ArticleProps) {
  const {
    title,
    video,
    images,
    swiper,
    children,
    titleClassName,
    childClassName,
    imageClassName,
    swiperClassName,
    imagesClassName,
    containerClassName,
    openVideo,
  } = props
  const [index, setIndex] = useState(0)
  const swiperRef = useRef<SwiperRefNode>(null)
  const [videoVisible, setVideoVisible] = useState(false)
  useEffect(() => {
    const swiperInstance = swiperRef.current?.swiper

    if (swiperInstance) {
      swiperInstance.on("slideChange", () => {
        if (images) {
          setIndex(swiperInstance.activeIndex)
        }
      })
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange")
      }
    }
  }, [setIndex, images])

  return (
    <div
      className={
        classNames(
          classes.container,
          containerClassName,
        )}>
      <div className={classes.content}>
        {
          title
            ? (
              <strong className={titleClassName || classes.title}>
                {title}
              </strong>
            )
            : null
        }
        {
          children
            ? (
              <article
                className={
                  classNames(
                    classes.children,
                    childClassName
                  )
                }
              >
                {children}
              </article>
            )
            : null
        }
      </div>
      {
        images
          ? (
            swiper
              ? (
                <div
                  className={
                    classNames(classes.swiper, swiperClassName)
                  }
                >
                  <Swiper
                    ref={swiperRef}
                    autoplay
                    // loop={images.length > 1}
                    allowTouchMove={images.length > 1}
                  >
                    {
                      images.map((ele, i) => (
                        <div
                          key={`${ele.value}_${i.toString()}`}
                          className={
                            classNames(classes.swiperItem, imageClassName)
                          }
                        >
                          <img
                            className={classes.cover}
                            src={ele.value}
                            alt=''
                          />
                        </div>
                      ))
                    }
                  </Swiper>
                  <div className={classes.dots}>
                    {
                      images.map((_, i) => (
                        <div
                          key={i.toString()}
                          className={classNames(
                            classes.dot,
                            {[classes.active]: i === index})
                          }
                        />
                      ))
                    }
                  </div>
                </div>
              )
              : (
                <div className={classNames(classes.images, imagesClassName)}>
                  {
                    images.map(image => (
                      <div
                        key={image.name}
                        className={classes.image}
                        onClick={() => video && setVideoVisible(true)}
                      >
                        <div className={imageClassName}>
                          <img src={image.value} alt='' />
                        </div>
                        {
                          image.name
                            ? (<span>{image.name}</span>)
                            : null
                        }
                        {
                          video
                            ? (
                              <div
                                className={classes.videoCover}
                                onClick={openVideo}
                              >
                                <Icon
                                  icon='Play'
                                  scale={3}
                                  color='#fff'
                                />
                              </div>
                            )
                            : null
                        }
                      </div>
                    ))
                  }
                </div>
              )
          )
          : null
      }
      {
        video
          ? (
            <Video
              visible={videoVisible}
              url={video}
              onClose={() => setVideoVisible(false)}
            />
          )
          : null
      }
    </div>
  )
}

export default Index
