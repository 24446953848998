import { ossImg } from 'utils/oss'
import { getValue } from 'utils/view'

const CN = () => ({
  seo: {
    title: 'ESG产品服务 - 万物新生',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网',
    description: '万物新生持续提供安全可靠的二手消费电子回收与交易服务,流转过程中确保用户的信息隐私安全.',
  },
  breadcrumb: '首页 / ESG / 产品服务',
  banner: getValue({
    large: ossImg('/esg/overall-banner.png'),
    small: ossImg('/esg/esg-m-banner.png'),
  }),
  service: {
    title: '产品服务',
    content: '万物新生持续打造以供应链为基础的服务能力，致力于在二手产品使用的各个环节为用户提供坚实的保障，以提升用户体验为目标，为用户提供强而有力的产品质量保障;建立安全的信息系统环境，减少网络信息系统安全风险与客户隐私泄露的风险;同时不断加强我们自身的创新能力，加强知识产权保护工作。此外，我们坚持高质高效的用户沟通及负责任营销，提供多种客户反馈渠道，及时有效地满足各类客户诉求。',
    image: { value: ossImg('/esg/product-service.png') },
    items: [
      {
        name: '回收检测',
        desc: '保障产品来源',
        image: ossImg('/esg/product-icon-recycle.png'),
      },
      {
        name: '拒绝异常机器',
        desc: '序列号不明或出厂属性异常',
        image: ossImg('/esg/product-icon-chargers.png'),
      },
      {
        name: '身份核实',
        desc: '确保用户信息匹配、消除隐私泄露',
        image: ossImg('/esg/product-icon-check.png'),
      },
    ],
  },
  quality: {
    title: '质量保证',
    content: [
      '万物新生秉承保障产品质量、对用户负责的发展理念，严格遵守相关法律法规，通过检验、销售、使用等环节的制度化、标准化质量管理活动，进一步加强对产品质量的把控力度，长期致力于保障用户及消费者的合法权益。',
      '作为互联网旧货交易平台相关国家标准制定的参与者，万物新生积极地在行业内推广产品质量标准和行业标准的制定，使非标品得以清晰质检、买家放心购买、商家轻松交易。',
    ],
    grid: [
      {
        image: ossImg('/esg/product-technology.png'),
        title: "运营中心技术创新",
      },
      {
        image: ossImg('/esg/product-jdx.png'),
        title: "数字智能验机工具——机大侠",
      },
      {
        image: ossImg('/esg/product-privacy.png'),
        title: "全程视频取证与密封措施",
      },
    ],
  },
  protection: {
    title: '信息系统安全防护',
    content: [
      '万物新生致力于通过技术、流程与管理的结合，降低企业信息系统与网络安全相关风险，切实保障本集团内部信息系统与网络安全及客户隐私安全。根据我国法律法规，建立日趋完善的信息安全与隐私安全管理体系，在网络安全、应用安全、数据安全等方面制定了相关防护机制。',
      '万物新生已在网络安全等级保护测评中获得三级等保测评，并通过了ISO 27001信息安全管理体系认证。',
    ],
    images: [
      {
        name: '信息安全管理体系认证证书',
        value: ossImg('/esg/product-certificate.png'),
      },
      {
        name: '网络安全等级保护测评-三级等保',
        value: ossImg('/esg/product-multi.png'),
      }
    ]
  },
  privacy: {
    title: '用户隐私数据保护',
    content: '万物新生在始终严格遵守国家法律法规的同时，优化内部隐私政策等相关服务协议，保护消费者知情权与选择权。在回收环节通过人工清除与技术清除的结合，切实保障用户数据及信息安全。',
    items: [
      {
        name: '线下门店',
        desc: '验机回收、隐私擦除',
        image: ossImg('/esg/product-store.png'),
      },
      {
        name: '运营中心',
        desc: '数据擦除、双重保障',
        image: ossImg('/esg/product-operation.png'),
      },
      {
        name: '百倍赔付',
        desc: '隐私安全无忧保障',
        image: ossImg('/esg/product-Indemnification.png'),
      }
    ],
  },
  aqc: {
    title: '隐私信息清除系统 — 爱清除',
    image: { value: ossImg('/esg/product-clear.png'), },
    content: '万物新生自主研发出效率更高、清除更彻底的信息清除系统 “爱清除”，自 2018 年研发立项，于2019 年投入使用。“爱清除”通过多次覆盖擦写与处理，保障了用户的信息安全。',
    items: [
      {
        name: '清除过程可追溯',
        image: ossImg('/esg/product-retroactive.png'),
      },
      {
        name: '清除结果可保证',
        image: ossImg('/esg/product-promise.png'),
      },
      {
        name: '清除程度更彻底',
        image: ossImg('/esg/product-thoroughly.png'),
      },
      {
        name: '清除效率更提升',
        image: ossImg('/esg/product-uplifted.png'),
      }
    ],
  },
})

const US = () => ({
  seo: {
    title: 'ESG Products and Services - ATRenew',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai',
    description: 'ATRenew adopts stringent privacy protection rules and duly protects the rights of consumers, users and partners.',
  },
  breadcrumb: 'Home / ESG / Products and Services',
  banner: getValue({
    large: ossImg('/esg/overall-banner.png'),
    small: ossImg('/esg/esg-m-banner.png'),
  }),
  service: {
    title: 'Products and Services',
    content: 'We are constantly expanding our supply chain-based service capabilities to provide protection for users throughout the second-hand products use cycle. We offer quality assurance to improve the user experience. We established a secure information system to reduce potential cybersecurity risks and prevent customer privacy breaches. Meanwhile, we are improving our innovation capabilities and resolutely protecting our intellectual property rights. We are committed to high-quality and efficient user communication and responsible marketing. To this end, we have made available multiple customer feedback channels to address all kinds of customer requests in a timely manner.',
    image: {
      value: getValue({
        large: ossImg('/esg/product-service.png'),
        small: ossImg('/esg/product-service.png'),
      })
    },
    items: [
      {
        name: 'Inspect devices before recycling',
        desc: 'Ensure adequate product quality.',
        image: ossImg('/esg/product-icon-recycle.png'),
      },
      {
        name: 'Reject abnormal devices',
        desc: 'Reject devices with unknown serial numbers or abnormal factory settings.',
        image: ossImg('/esg/product-icon-chargers.png'),
      },
      {
        name: 'Confirm seller’s identity',
        desc: 'Ensure items are legitimately sourced and safeguard user privacy.',
        image: ossImg('/esg/product-icon-check.png'),
      },
    ],
  },
  quality: {
    title: 'Quality Assurance',
    content: [
      'Ensuring product quality is the most direct way for us to be accountable to our customers. This is also a development principle that ATRenew has long been committed to upholding. In compliance with applicable laws and regulations, we have developed policies and standards for quality management at each stage of inspection, sale and use. With a stronger control over product quality, we are better positioned to protect the rights and interests of our users and consumers.',
      'As an active participant in the drafting of national standards for online second-hand goods trading platforms, ATRenew is actively promoting our own product quality standards within the industry and helping create industry-wide standards. Our holistic quality assurance system allows all non-standard products to be thoroughly inspected, purchased by consumers at ease and traded among peers.',
    ],
    grid: [
      {
        image: ossImg('/esg/product-technology.png'),
        title: "Tech innovation at ATRenew’s operation centers",
      },
      {
        image: ossImg('/esg/product-jdx.png'),
        title: "Device Hero (机大侠), a proprietary intelligent inspection tool",
      },
      {
        image: ossImg('/esg/product-privacy.png'),
        title: "Smart evidence taking and seal bags",
      },
    ],
  },
  protection: {
    title: 'Information System Security',
    content: [
      "We are dedicated to minimizing risks associated with information systems and cybersecurity. By combining technology, process and management, we safeguard our internal information system, cybersecurity and customer privacy. To this end, we established a comprehensive information security and privacy management system, in accordance with and subject to local laws and regulations.",
      "ATRenew has passed the Level 3 Assessment under “China's Multi-Level Protection Scheme” and has been certified by the ISO 27001 information security management system.",
    ],
    images: [
      {
        name: 'Certificate of Information Security Management System',
        value: ossImg('/esg/product-certificate.png'),
      },
      {
        name: 'Multi-Level Protection Scheme – Level 3',
        value: ossImg('/esg/product-multi.png'),
      }
    ]
  },
  privacy: {
    title: 'User Privacy and Data Protection',
    content: "Consumer personal data privacy is a top priority at ATRenew. Abiding by rules and regulations for privacy protection, we have enhanced internal policies to protect consumer rights to know and to choose. As a tangible step to safeguard customer personal information and privacy, we have adopted a stringent data policy when recycling customer devices.",
    items: [
      {
        name: 'At offline stores',
        desc: 'Inspection and privacy clearance',
        image: ossImg('/esg/product-store.png'),
      },
      {
        name: 'At operation centers',
        desc: 'Dual assurance via data wiping',
        image: ossImg('/esg/product-operation.png'),
      },
      {
        name: 'Compensation mechanism',
        desc: 'Compensate at 100 times the recycling price for data leakage due to negligence',
        image: ossImg('/esg/product-Indemnification.png'),
      }
    ],
  },
  aqc: {
    title: 'AiQingChu (爱清除), data wiping system',
    image: { value: ossImg('/esg/product-clear.png') },
    content: "To address user concerns about information security, ATRenew has independently developed AiQingChu, a system that swiftly and thoroughly erases customer data. The R&D of AiQingChu began in 2018 and was put into use a year later. This system protects users' data security during the circulation process. ",
    items: [
      {
        name: 'Traceable',
        image: ossImg('/esg/product-retroactive.png'),
      },
      {
        name: 'Insured',
        image: ossImg('/esg/product-promise.png'),
      },
      {
        name: 'Thorough',
        image: ossImg('/esg/product-thoroughly.png'),
      },
      {
        name: 'Effective',
        image: ossImg('/esg/product-uplifted.png'),
      }
    ],
  },
})

const data = {
  CN,
  US
}
export default data
