import React, { useMemo } from 'react'
import { useLocale } from '../../locales'
import Seo from '../../components/Head/Seo'

import Breadcrumb from 'components/Breadcrumb/Index'
import Article from '../../components/Article/Index'
import Divider from '../../components/Divider/Index'
import Grid from '../Innovation/components/Grid'
import IconItems from './components/IconItems'
import Privacy from './components/Privacy'
import Banner from './components/Banner'

import classes from './Product.module.scss'

function Index() {
  const locale = useLocale()
  const page = useMemo(
    () => locale.pages.ESG.product,
    [locale.pages.ESG.product]
  )
  return (
    <div className={classes.Index}>
      <Seo seo={locale.pages.ESG.product.seo} />
      <Banner />
      <Breadcrumb
        title={page.breadcrumb}
        className={classes.breadcrumb}
      />
      <div className={classes.sectionA}>
        <Article
          title={page.service.title}
          images={[page.service.image]}
          imageClassName={classes.serviceImg}
        >
          {page.service.content}
          <div className={classes.iconItems}>
            <IconItems data={page.service.items} />
          </div>
        </Article>
        <Divider className={classes.divider} />
        <Article
          title={page.quality.title}
          containerClassName={classes.container}
        >
          {
            page.quality.content.map((item, index) => (
              <p
                key={index.toString()}
                className={classes.quality}
              >
                {item}
              </p>
            ))
          }
          <Grid data={page.quality.grid} />
        </Article>
        <Article
          title={page.protection.title}
          images={page.protection.images}
          imagesClassName={classes.protectionImages}
          imageClassName={classes.protectionImage}
          containerClassName={classes.container}
        >
          {
            page.protection.content.map((item, index) => (
              <p key={index.toString()}>{item}</p>
            ))
          }
        </Article>
        <Article
          title={page.privacy.title}
          containerClassName={classes.container}
        >
          <p>{page.privacy.content}</p>
          <div className={classes.iconItems}>
            <IconItems data={page.privacy.items} />
          </div>
        </Article>
        <Privacy />
        <Divider className={classes.divider} />
      </div>
    </div>
  )
}

export default Index
