import React, { useMemo } from 'react'
import Introduce from './Introduce'
import { useLocale } from '../../../../locales'
import Section from 'components/Section/Index'
import Units from '../Paijitang/Units'

import classes from './Block.module.scss'

export default function Block() {
  const locale = useLocale()

  const blocks = useMemo(
    () => locale.pages.Business.Ahs.blocks,
    [locale.pages.Business.Ahs.blocks]
  )

  return (
    <Section>
      <div className={classes.Block}>
        <div className={classes.main}>
          {
            blocks.map((block) => (
              <Introduce data={block as any}>
                {
                  block?.info?.type === 'text'
                    ? (
                      <div className={classes.blockText}>
                        <Units
                          data={block.info.list!}
                          className={classes.blockUnits}
                          itemClassName={classes.unitItem}
                        />
                      </div>
                    )
                    : null
                }
                {block.note
                  ? (
                    <p className={classes.note}>
                      {block.note}
                    </p>
                  )
                  : null}
                {
                  block?.info?.type === 'icons'
                    ? (
                      <div className={classes.blockIconBox}>
                        <div className={classes.blockTitle}>
                          {block.info.title}
                        </div>
                        <div className={classes.blockIcons}>
                          {
                            block.info.services!.map(ele => (
                              <div className={classes.IconBox}>
                                <img src={ele.icon} alt={ele.name} />
                                <span className={classes.name}>{ele.name}</span>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    )
                    : null
                }
              </Introduce>
            ))
          }
        </div>
      </div>
    </Section>
  )
}
