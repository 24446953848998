import React, { useMemo } from 'react'
import { useLocale } from '../../locales'
import Seo from '../../components/Head/Seo'

import Banner from './components/Banner'
import Breadcrumb from '../../components/Breadcrumb/Index'

import classes from './Reports.module.scss'
import classNames from 'classnames'

function Index() {
  const locale = useLocale()
  const page = useMemo(
    () => locale.pages.ESG.reports,
    [locale.pages.ESG.reports]
  )
  return (
    <div className={classes.Index}>
      <Seo seo={locale.pages.ESG.reports.seo} />
      <Banner />
      <Breadcrumb
        title={page.breadcrumb}
        className={classes.breadcrumb}
      />
      <div className={classes.tableData}>
        <div className={classNames(classes.tableRow, classes.tableHead)}>
          <p className={classes.tableLeft}>{page.tableHead.classes}</p>
          <div className={classes.tableRight}>
            <p className={classes.tableCell}>{page.tableHead.content}</p>
          </div>
        </div>
        {page.tableData.map((data) => {
          return (
            <div key={data.classes} className={classes.tableRow}>
              <p className={classes.tableLeft}>{data.classes}</p>
              <div className={classes.tableRight}>
                {
                  data.content.map((item) => {
                    return (
                      <p className={classes.tableCell} key={item.name}>
                        <a href={item.url} target='_blank' className={classNames(item.url ? classes.tableLink : '')} rel='noreferrer'>
                          {item.name}
                        </a>
                      </p>
                    )
                  })
                }
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Index
