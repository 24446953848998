import React, {useCallback, useMemo, useState} from 'react'
import Icon from 'components/Icon/Index'
import SwiperInstance from 'components/Swiper/Index'
import {useLocale} from 'locales'

import classes from './Banner.module.scss'

interface VideoProps {
  url: string
  visible: boolean
  onClose: () => void
}

function Video(props: VideoProps) {
  const {
    url,
    visible
  } = props

  const onClose = useCallback((event) => {
      event.preventDefault()
      event.stopPropagation()
      props.onClose()
    },
    [props]
  )
  const stop = useCallback((event) => {
      event.preventDefault()
      event.stopPropagation()
    },
    []
  )

  if (!visible) return null
  return (
    <div className={classes.container} onClick={onClose}>
      <div className={classes.videoBox} onClick={stop}>
        <video src={url} controls autoPlay/>
        <div
          className={classes.close}
          onClick={onClose}
        >
          <Icon
            icon='Close'
            scale={1}
            color='#fff'
          />
        </div>
      </div>
    </div>
  )
}

function Banner() {
  const locale = useLocale()
  const [swiperIndex, setSwiperIndex] = useState(0)
  const [videoVisible, setVideoVisible] = useState(false)
  const banners = useMemo(
    () => locale.pages.Home.banners,
    [locale.pages.Home.banners]
  )
  const swiperItem = useMemo(() => banners[swiperIndex], [banners, swiperIndex])
  const toggleVideoModal = useCallback(
    () => {
      setVideoVisible(!videoVisible)
    },
    [videoVisible]
  )

  return (
    <div
      className={classes.Banner}
      style={
        {background: swiperItem.background}
      }
    >
      <div className={classes.wrapper}>
        <SwiperInstance onChange={(index) => setSwiperIndex(index)}>
          {
            banners.map(banner => (
              <SwiperInstance.Item key={banner.imageUrl}>
                <div className={classes.slideItem}>
                  <img
                    src={banner.imageUrl}
                    alt={locale.pages.Home.title}
                  />
                  <div
                    className={classes.action}
                    onClick={() => toggleVideoModal()}
                  >
                    <span className={classes.text}>
                      { banner.buttonText }
                    </span>
                    <Icon icon='Play' scale={0.8} color='#fff'/>
                  </div>
                </div>
              </SwiperInstance.Item>
            ))
          }
        </SwiperInstance>
        <Video
          visible={videoVisible}
          url={swiperItem.videoUrl}
          onClose={() => setVideoVisible(false)}
        />
      </div>
    </div>
  )
}

export default Banner
