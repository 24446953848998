import React, { useMemo } from 'react'

function Index(
  props: {
    keyword?: string
    children?: string
    color?: string
    bold?: boolean
    firstOnly?: boolean
    highlightClassName?: string
  }
) {
  const {
    color: highlightColor,
    bold,
    keyword,
    children,
    firstOnly,
    highlightClassName
  } = props

  const texts = useMemo<Array<{
    text: string
    highlight?: boolean
  }>>(() => {
    if (!children) return []
    if (!keyword) return [{ text: children }]
    if (keyword === children) return [{
      text: children,
      bold,
      highlight: true
    }]
    const originalKeywords = keyword.split(' ')
    const keywords = keyword
      .replace(/\+/, '\\+')
      .replace(/\*/, '\\*')
      .replace(/\(/, '\\(')
      .replace(/\)/, '\\)')
      .replace(/\[/, '\\[')
      .replace(/\]/, '\\]')
      .replace(/\|/, '\\|')
      .replace(/\./, '\\.')

    const regex = new RegExp(
      `(${keywords})`,
      'ig'
    )
    let replaced = false
    const parts = children.split(regex)
      .map((p) => {
        if (!firstOnly || !replaced) {
          if (originalKeywords.indexOf(p) >= 0) {
            replaced = true
            return {
              text: p,
              highlight: true,
              bold: bold
            }
          }
        }
        return { text: p }
      })
      .filter(p => Boolean(p.text))
    return parts
  }, [bold, children, firstOnly, keyword])

  if (!texts.length) return null
  return (
    <>
      {
        texts.map(text => (
          <span
            key={text.text}
            className={
              (highlightClassName && text.highlight)
                ? highlightClassName
                : ''
            }
            style={
              highlightClassName
                ? {
                  color: text.highlight ? highlightColor : '',
                  fontWeight: text.highlight ? 'bold' : undefined
                }
                : {}
            }
          >
            {text.text}
          </span>
        ))
      }
    </>
  )
}

export default Index
