import React, {useMemo} from 'react'
import {useLocale} from '../../locales'
import Section from 'components/Section/Index'
import Banner from './components/Banner'
import About from './components/About'
import Breadcrumb from 'components/Breadcrumb/Index'
import Article from './components/Paipai/Article'
import Comparison from './components/Paipai/Comparison'
import Seo from '../../components/Head/Seo'

import classes from './Paipai.module.scss'

export default function Paipai() {
  const locale = useLocale()
  const paipai = useMemo(
    () => locale.pages.Business.Paipai,
    [locale.pages.Business.Paipai]
  )

  return (
    <div className={classes.Paipai}>
      <Seo seo={paipai.seo} />
      <Banner
        {...paipai.banner}
        background='var(--theme-gradient)'
      />
      <Breadcrumb title={paipai.breadcrumb}/>
      <About data={paipai.about as any}/>
      <Section.Full background='var(--theme-gradient)'>
        <div className={classes.services}>
          {
            paipai.steps.map(ele => (
              <div className={classes.serviceItem}>
                <p className={classes.title}>{ele.title}</p>
                <p className={classes.subTitle}>{ele.subTitle}</p>
              </div>
            ))
          }
        </div>
      </Section.Full>
      <Article/>
      <Comparison/>
      <Section
        name={paipai.service.sectionName}
        hideBottomLine
      >
        <div className={classes.main}>
          <div className={classes.info}>
            <div className={classes.title}>
              {paipai.service.title}
            </div>
            {
              paipai.service.description.map(item => (
                <span className={classes.description}>
                  {item}
                </span>
              ))
            }
          </div>
        </div>
      </Section>
      <Section.Full>
        <div className={classes.advantages}>
          {
            paipai.advantages.map(ele => (
              <div className={classes.advantageItem}>
                <img src={ele.icon} alt={ele.title}/>
                <div className={classes.box}>
                  <p className={classes.title}>
                    {ele.title}
                  </p>
                  {
                    ele.subTitle.map(item => (
                      <p className={classes.subTitle}>
                        {item}
                      </p>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </Section.Full>
    </div>
  )
}
