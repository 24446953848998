import React, { Fragment, useMemo } from 'react'
import { useLocale } from '../../../locales'
import Section from '../../../components/Section/Index'

import classes from './About.module.scss'
import { default as iconPdf } from '../../../assets/icon-pdf.png'

export default function About() {
  const locale = useLocale()
  const about = useMemo(
    () => locale.pages.ESG.overall.about,
    [locale.pages.ESG.overall.about]
  )

  return (
    <Section
      name={about.sectionName}
      extra={(
        <div className={classes.badges}>
          {
            about.sectionBadge.map(badge => (
              <img
                key={badge.image}
                className={classes.badge}
                src={badge.image}
                alt={badge.title}
              />
            ))
          }
        </div>
      )}
    >
      <div className={classes.About}>
        {
          about.sections.map(section => (
            <div className={classes.section}>
              {
                section.description
                  ? (
                    <div
                      className={classes.description}
                      dangerouslySetInnerHTML={
                        { __html: section.description }
                      }
                    />
                  )
                  : null
              }
              {
                section.subTitle
                  ? (
                    <div className={classes.subTitle}>
                      {section.subTitle}
                    </div>
                  )
                  : null
              }
            </div>
          ))
        }
        {
          about.links.map(link => (
            <Fragment key={link.name}>
              <a
                className={classes.link}
                href={link.url}
                target='_blank'
                rel='noreferrer'
              >
                <span>{link.name}</span>
                <img
                  className={classes.icon}
                  src={iconPdf}
                  alt={link.name}
                />
              </a>
              <br />
            </Fragment>)
          )
        }
      </div>
    </Section>
  )
}
