import React, { useMemo } from 'react'
import classNames from 'classnames'

import { useLocale } from '../../locales'
import Seo from '../../components/Head/Seo'

import Divider from '../../components/Divider/Index'
import Article from '../../components/Article/Index'
import Breadcrumb from 'components/Breadcrumb/Index'

import Gird from './components/Grid'
import Series from './components/Series'

import classes from './Index.module.scss'

function Index() {
  const locale = useLocale()
  const page = useMemo(
    () => locale.pages.Innovation,
    [locale.pages.Innovation]
  )
  return (
    <div className={classes.Index}>
      <Seo seo={locale.pages.Innovation.seo} />
      <div className={classes.banner}>
        <img src={page.banner} alt='' />
      </div>
      <Breadcrumb
        title={page.breadcrumb}
        className={classes.breadcrumb}
      />
      <div className={classes.body}>
        <Article
          title={page.innovation.title}
          images={page.innovation.images}
          imageClassName={classes.image}
          video={page.innovation.video}
        >
          {page.innovation.content}
        </Article>
        <Divider className={classNames(classes.divider, classes.bottomGap)} />
        <Series title={page.news.title} series={page.news.data} />
        <Divider className={classes.divider} />
        <strong className={classes.title}>{page.matrix.title}</strong>
        <p className={classes.content}>
          {page.matrix.content}
        </p>
        <Gird className={classes.grid} data={page.matrix.grid} />
        <Divider className={classes.divider} />
        <Article
          title={page.algorithm.title}
          containerClassName={classes.algorithm}
          images={[page.algorithm.image]}
          imageClassName={classes.algorithmImg}
        >
          {page.algorithm.content}
        </Article>
        <Divider className={classes.divider} />
        <strong className={classes.title}>{page.inspection.title}</strong>
        {
          page.inspection.articles.map(article => (
            <Article
              key={article.title}
              containerClassName={classes.inspection}
              title={article.title}
              titleClassName={classes.subTitle}
              images={[article.image]}
              imageClassName={classes.image}
            >
              {article.content}
            </Article>
          ))
        }
        <Divider className={classes.divider} />
      </div>
    </div>
  )
}

export default Index