import React, {useMemo, useState} from 'react'
import {useLocale} from '../../../locales'
import Section from '../../../components/Section/Index'
import SwiperInstance from '../../../components/Swiper/Index'
import Dots from '../../../components/Dots/Index'

import classes from './Benefit.module.scss'

function Benefit() {
  const [swiperIndex, setSwiperIndex] = useState(0)
  const locale = useLocale()
  const communication = useMemo(
    () => locale.pages.ESG.overall.communication,
    [locale.pages.ESG.overall.communication]
  )

  return (
    <Section
      name={communication.sectionName}
      hideBottomLine
      className={classes.Section}
    >
      <div className={classes.main}>
        <div className={classes.info}>
          <div className={classes.description}>
            {communication.description}
          </div>
        </div>
        <div className={classes.right}>
          <SwiperInstance
            className={classes.swiper}
            onChange={(index) => setSwiperIndex(index)}
          >
            {
              communication.covers.map((ele) => (
                <SwiperInstance.Item key={ele}>
                  <img
                    className={classes.cover}
                    alt={communication.sectionName}
                    src={ele}
                  />
                </SwiperInstance.Item>
              ))
            }
          </SwiperInstance>
          <div className={classes.dots}>
            <Dots
              total={communication.covers.length}
              activeIndex={swiperIndex}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Benefit
