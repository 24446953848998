import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useLocale } from '../../../locales'
import Section from './Section'
import Tabs from './Tabs'

import classes from './Difficult.module.scss'

function Difficult() {
  const locale = useLocale()

  const difficult = useMemo(
    () => locale.pages.Home.difficult,
    [locale.pages.Home.difficult]
  )

  return (
    <Section
      name={difficult.title}
      border
      mainClassName={classes.Section}
    >
      <Tabs
        defaultActiveKey={difficult.tabs[0].name}
      >
        {
          difficult.tabs.map(ele => (
            <Tabs.Item title={ele.name} code={ele.name}>
              <div className={classes.tabContainer}>
                <div className={classes.info}>
                  <div className={classes.description}>
                    {ele.description}
                  </div>
                  <div className={classes.logo}>
                    <img src={ele.product.logo} alt='logo' />
                  </div>
                  <div className={classes.introduce}>
                    {ele.product.introduce}
                  </div>
                  <div className={classes.list}>
                    {
                      ele.product.services.map(item => (
                        <div className={classes.item}>
                          <img className={classes.icon} src={item.icon} alt='' />
                          <span>{item.text}</span>
                        </div>
                      ))
                    }
                  </div>
                  <Link
                    className={classes.more}
                    to={ele.more.link}
                  >
                    {ele.more.text}
                  </Link>
                </div>

                <img
                  className={classes.cover}
                  src={ele.imgUrl}
                  alt={ele.name}
                />
              </div>
            </Tabs.Item>
          ))
        }
      </Tabs>
    </Section>
  )
}

export default Difficult
