import React from 'react'
import classNames from 'classnames'

import classes from './Units.module.scss'

interface ValueItem {
  name: string
  value: string
}
interface UnitItem {
  name: string
  unit: string
  value: string | ValueItem[]
}

interface Props {
  className?: string
  itemClassName?: string
  data: UnitItem[]
}

function Units(props: Props) {
  const {
    className,
    itemClassName,
    data
  } = props
  return (
    <div className={classNames(classes.units, className)}>
      {
        data.map(ele => (
          <>
            {/* pc端结构 */}
            <div
              key={`large_${ele.name}`}
              className={
                classNames(
                  'hidden-small',
                  classes.item,
                  itemClassName
                )
              }
            >
              <div className={classes.name}>
                <span>{ele.name}</span>
              </div>
              <div
                className={
                  classNames(
                    classes.value,
                    {[classes.values]: typeof ele.value !== 'string'}
                  )
                }
              >
                {
                  typeof ele.value === 'string'
                    ? (
                      <>
                        {ele.value} <span className={classes.unit}>{ele.unit || ''}</span>
                      </>
                    )
                    : ele.value.map(item => (
                      <div className={classes['value-item']}>
                        {item.name} -- <strong> {item.value} </strong>
                        <span className={classes.unit}>{ele.unit}</span>
                      </div>
                    ))
                }
              </div>
            </div>
            {/* 移动端结构 */}
            <div
              key={`small_${ele.name}`}
              className={
                classNames(
                  'hidden-large',
                  classes.item,
                  itemClassName
                )
              }
            >
              {
                typeof ele.value === 'string'
                  ? (
                    <>
                      <div className={classes.name}>
                        <span>{ele.name}</span>
                      </div>
                      <div className={classes.value}>
                        {ele.value}
                        <span>{ele.unit}</span>
                      </div>
                    </>
                  )
                  : (
                    <>
                      <div className={classes.name}>
                        <span>{ele.name}</span>
                      </div>
                      <div
                        className={classNames(classes.value, classes.values)}
                      >
                        {
                          ele.value.map(item => (
                            <div className={classes['value-item']}>
                              {item.name} -- <span> {item.value} </span>
                              {ele.unit}
                            </div>
                          ))
                        }
                      </div>
                    </>
                  ) 
              }
            </div>
          </>
        ))
      }
    </div>
  )
}

export default Units
