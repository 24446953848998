import { ossImg } from "utils/oss"
import { getValue } from "utils/view"

const CN = () => ({
  seo: {
    title: '技术能力 - 万物新生',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网',
    description: '万物新生打造自动化质检运营能力.包括自动化质检,智能定价算法,智能验机,隐私清除系统等创新技术,赋能商家.',
  },
  breadcrumb: '首页 / 技术能力',
  title: '技术能力',
  banner: getValue({
    large: ossImg('/innovation/innovation-banner1.png'),
    small: ossImg('/innovation/innovation-m-banner.png'),
  }),
  innovation: {
    title: '技术实力',
    content: `2017年，万物新生集团启动了自动化运营体系的建设，在常州打造了“非标二手电子产品自动化输送、质检、分拣和存储系统”，改变了过往依赖密集型人工质检操作导致的效率较低、成本较高的行业状态。`,
    images: [{ value: ossImg('/innovation/innovation-arm.png') }],
    video: getValue({
      large: ossImg('/innovation/innovation-pc-zh.mp4'),
      small: ossImg('/innovation/innovation-m-zh.mp4'),
    })
  },
  news: {
    title: '技术动态',
    data: [{
      title: '万物新生东莞自动化运营中心',
      createdOn: '2022年11月9日',
      content: [
        '万物新生集团东莞自动化运营中心正式开仓运营。万物新生东莞自动化运营中心由自动输送分拣体系、自动质检体系、自动仓储体系三大模块组成，采用全流程数字化管理系统进行中央控制，承担输送、分拣、质检、仓储、发货等职能。'
      ],
      image: ossImg('/innovation/dongguan-operation-center.jpg'),
      video: ossImg('/innovation/dongguan-operation-center-cn.mp4'),
    }, {
      title: '拍照盒子3.0',
      createdOn: '2022年3月21日',
      content: [
        '针对非标二手3C电子产品外观人工检测质检速度较慢、精准度相对较低的痛点，万物新生集团近期自主研发了手机外观智能检测设备“拍照盒子3.0”。“拍照盒子3.0”融入AI算法，仅需20秒即可精准定位并检测出一台手机30余种不同类型的外观缺陷，准确率达到99%以上，质检时间相比人工减少90%。'
      ],
      image: ossImg('/innovation/news/1-cn.png'),
      video: ossImg('/innovation/photograph-box-cn.mp4'),
    }]
  },
  matrix: {
    title: '先进的后端自动化处置能力',
    content: '采用全流程数字化管理手段和全新的自动化设备，在输送、分拣、质检、存储等环节，进一步实现降本增效，助力二手3C行业向智能化、系统化、规模化、低碳化运营升级。',
    grid: [
      {
        image: ossImg('/innovation/innovation-box.png'),
        title: '“拍照盒子”系统',
        content: '基于计算机视觉、针对手机细微划痕自动检测',
      },
      {
        image: ossImg('/innovation/innovation-007.png'),
        title: '“007”系统',
        content: '硬件产品功能自动插线和检测',
      },
      {
        image: ossImg('/innovation/innovation-ray.png'),
        title: '“X-Ray”系统',
        content: '不拆机即可拍照识别手机部件是否有更换和拆修',
      },
      {
        image: ossImg('/innovation/innovation-automatic.png'),
        title: '拍照魔方',
        content: '多角度商品高清图片自动拍摄',
      },
      {
        image: ossImg('/innovation/innovation-mirror.png'),
        title: '拍照魔镜',
        content: '高精度识别屏幕老化、坏点和其他瑕疵',
      },
      {
        image: ossImg('/innovation/innovation-agv-veichle.png'),
        title: 'AGV无人运输车',
        content: '实现货物自动搬运和装卸',
      },
      {
        image: ossImg('/innovation/innovation-belt.png'),
        title: '自动化传送带',
      },
      {
        image: ossImg('/innovation/innovation-arm.png'),
        title: '分拣机械臂',
      },
      {
        image: ossImg('/innovation/innovation-save.png'),
        title: '存储系统',
      },
    ],
  },
  algorithm: {
    title: '大数据智能定价算法',
    content: '万物新生基于交易大数据积累开发了智能价格引擎，解决B2B平台SKU众多、价格实时波动、复杂性高的问题，提升了平台定价的准确率和时效性，充分匹配买家卖家报价，提升了商家在平台的交易体验和交易效率。',
    image: { value: ossImg('/innovation/innovation-algorithm.png'), },
  },
  inspection: {
    title: '赋能前端质检',
    articles: [
      {
        title: '智能验机工具 一 机大侠',
        content: '万物新生集团通过自主研发，推出为广泛中小商家提供的新型便携式验机工具”机大侠“。设备大小与移动电源相近，帮助小商家检查手机部件是否更换、手机功能、电池寿命及二手设备的诸多关键功能，数据回传后提供即时报价，帮助商家实现高效的质检与定价，促进回收交易。',
        image: { value: ossImg('/innovation/innovation-jdx.png') },
      },
      {
        title: '自助回收机手机回收界的“ATM”',
        content: '用户可根据设备提示流程，将手机放入MTA自助回收机中进行回收，操作便捷，2分钟内可以完成质检与估价。此外，用户能够即时拿到回收款。这些自助回收机分布于合作的手机品牌经销商门店。',
        image: { value: ossImg('/innovation/innovation-atm3.png') },
      },
      {
        title: '数据与隐私保护自主研发“爱清除”',
        content: '针对用户隐私易泄露的行业痛点，万物新生自主研发隐私清除系统一爱清除，通过多次数据擦写、覆盖，杜绝在流通过程中发生用户数据被恶意恢复的情况，达到有效避免用户隐私泄露的目的。',
        image: { value: ossImg('/innovation/innovation-aqc.png') },
      },
    ],
  },
})

const US = () => ({
  seo: {
    title: 'Technology - ATRenew',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai',
    description: 'ATRenew develops proprietary technologies to operate in scale and empower small merchants.',
  },
  breadcrumb: 'Home / Technology',
  title: 'Technology',
  banner: getValue({
    large: ossImg('/innovation/innovation-banner1.png'),
    small: ossImg('/innovation/innovation-m-banner.png'),
  }),
  innovation: {
    title: 'Proprietary Technologies',
    content: `In 2017, ATRenew began construction of an automated operation system and completed an automated transportation, quality inspection, sorting and storage system of non-standardized pre-owned electronic products in Changzhou. This innovation has completely redefined our industry, traditionally plagued by low efficiency and high cost due to labor-intensive manual quality inspection.`,
    images: [{ value: ossImg('/innovation/innovation-arm.png') }],
    video: getValue({
      large: ossImg('/innovation/innovation-pc-en.mp4'),
      small: ossImg('/innovation/innovation-m-en.mp4'),
    })
  },
  news: {
    title: 'Technology Updates',
    data: [{
      title: 'Dongguan Automation Operation Center of ATRenew',
      createdOn: 'Oct 9, 2022',
      content: [
        'Dongguan Automation Operation Center of ATRenew is officially put into use. The center is composed of three systems: automated transporting system, quality inspection system and warehousing system. It innovatively adopts digital management kits and undertakes the tasks of transportation, sorting, quality inspection, storage and delivery. ',
      ],
      image: ossImg('/innovation/dongguan-operation-center.jpg'),
      video: ossImg('/innovation/dongguan-operation-center-en.mp4'),
    }, {
      title: 'Camera Box 3.0',
      createdOn: 'Mar 21, 2022',
      content: [
        'Manual inspection, the traditional quality check method, lacks efficiency and accuracy. To solve this problem, ATRenew independently upgrades its proprietarily developed intelligent mobile phone appearance inspection device to “Camera Box 3.0”.',
        'The Camera Box 3.0 is integrated with an artificial intelligence algorithm. It only takes 20 seconds to precisely detect more than 30 types of appearance defects on a mobile phone, with an accuracy rate of over 99%. The quality inspection time is reduced by 90% compared with manual inspection.'
      ],
      image: ossImg('/innovation/news/1-en.png'),
      video: ossImg('/innovation/photograph-box-en.mp4'),
    }]
  },
  matrix: {
    title: 'Advanced Back-end Processing Capabilities',
    content: 'Digital management kits and brand-new automated equipment are applied to further reduce costs and increase efficiency in transportation, sorting, quality inspection, storage, and other links, and to transform the industry into an intelligent, systematic, large-scale and low-carbon one.',
    grid: [
      {
        image: ossImg('/innovation/innovation-box.png'),
        title: 'Camera Box module for appearance inspection',
      },
      {
        image: ossImg('/innovation/innovation-007.png'),
        title: '007 module for function inspection',
      },
      {
        image: ossImg('/innovation/innovation-ray.png'),
        title: 'X-Ray module for dismantling and repairing inspection',
      },
      {
        image: ossImg('/innovation/innovation-automatic.png'),
        title: 'Camera Rubik\'s Cube module for sales photo shoot',
      },
      {
        image: ossImg('/innovation/innovation-mirror.png'),
        title: 'Camera Magic Mirror module for screen inspection',
      },
      {
        image: ossImg('/innovation/innovation-agv-veichle.png'),
        title: 'AGV (automated guided vehicle) for automatically transporting, loading and unloading products',
      },
      {
        image: ossImg('/innovation/innovation-belt.png'),
        title: 'Automated shunting system',
      },
      {
        image: ossImg('/innovation/innovation-arm.png'),
        title: 'Sorting system',
      },
      {
        image: ossImg('/innovation/innovation-save.png'),
        title: 'Storage system',
      },
    ],
  },
  algorithm: {
    title: 'Data-driven Pricing Algorithm',
    content: 'Our smart pricing system, backed by big data and AI algorithms, ensures fair pricing for device recycling and transactions on our platform. Pricing is automatically determined by our central database, based on device physical condition, market demand, depreciation, and other factors that impact retail value. Leveraging these technological capabilities, we create industry benchmarks for pricing, consumer experience, and higher-efficiency transactions.',
    image: { value: ossImg('/innovation/innovation-algorithm.png') },
  },
  inspection: {
    title: 'Inspection Technologies',
    articles: [
      {
        title: 'Device Hero (机大侠), an intelligent inspection tool',
        content: 'Device Hero is our proprietary inspection terminal. Roughly the size of a power bank, Device Hero helps small merchants inspect devices and determine functionality, battery life, the need for parts replacement and many other key features of pre-owned devices. Device Hero automatically begins device inspection once plugged in. After inspection, it transfers the results back to our big data platform for pricing and quality assessment.',
        image: { value: ossImg('/innovation/innovation-jdx.png') },
      },
      {
        title: 'Self-service mobile phone recycling kiosks',
        content: 'Consumers place their mobile phones into the MTA, self-service recycling kiosk, by simply following the user instructions. Within two minutes, the device is inspected and a fair sale price is displayed on the kiosk. If consumers are satisfied with the price and confirm their desire to recycle the device, they receive payment instantly. These kiosks are installed throughout our AHS store network and at various other locations, including major phone retailers throughout the country.',
        image: { value: ossImg('/innovation/innovation-atm3.png') },
      },
      {
        title: 'AiQingChu (爱清除), our proprietary data wiping technology',
        content: "To address user concerns about information security, we independently developed AiQingChu, a system that swiftly and thoroughly erases user data. Repeated overwriting and wiping of the recycled mobile phones, tablets and laptops, prevents user data leakage and mitigates the risk of malicious recovery of user data during the circulation process.",
        image: { value: ossImg('/innovation/innovation-aqc.png') },
      },
    ],
  },
})

const data = {
  CN,
  US
}
export default data
