import React, { Fragment, ReactElement, useMemo } from 'react'

import { isLarge } from 'utils/view'

import classes from './Index.module.scss'


function Large(props: {
  children: React.ReactNode
}) {
  const { children } = props
  return <Fragment>{children}</Fragment>
}

function Small(props: {
  children: React.ReactNode
}) {
  const { children } = props
  return (
    <div className={classes.small}>{children}</div>
  )
}

type EnvChild = ReturnType<typeof Large> | ReturnType<typeof Small>
export interface EnvProps {
  children?: EnvChild | Array<EnvChild>
}

function Responsive(props: EnvProps) {
  const children = useMemo(() => {
    if (Array.isArray(props.children)) {
      return props.children
    }
    return [props.children]
  }, [props.children])
  const availableChildren = useMemo(() => {
    if (isLarge()) {
      return children.filter(
        (child) => {
          if (!child || child === null) return false
          return (child as ReactElement).type === Large
        }
      )
    }
    return children.filter((child) => {
      if (!child || child === null) return false
      return (child as ReactElement).type === Small
    })
  }, [children])
  return (
    <Fragment>
      {availableChildren}
    </Fragment>
  )
}

Responsive.Large = Large
Responsive.Small = Small

export default Responsive
