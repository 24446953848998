import React, { Fragment, useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import { useLocale } from 'locales'
import Main from 'components/Main/Index'
import Link from 'components/NavButton/Link'

import classes from './Index.module.scss'
import classNames from 'classnames'


function Footer() {
  const locale = useLocale()

  const copyRightsVisible = useMemo(
    () => Boolean(locale.footer.copyRights.length
      || locale.footer.securityLinks.length),
    [locale.footer.copyRights.length, locale.footer.securityLinks.length]
  )

  return (
    <div className={classes.box}>
      <Main>
        <div className={classes.friendLink}>
          <div className={classes.logo}>
            <NavLink to='/'>
              <img src={locale.logo} alt={locale.title} />
            </NavLink>
          </div>
          <div className={classes.groups}>
            {locale.footer.groups.map(group => (
              <div key={group.title} className={classes.group}>
                <div className={classes.groupTitle}>
                  <span>
                    {group.title}
                  </span>
                </div>
                <div className={classes.groupLinks}>
                  {group.links.map((link) => (
                    <Link
                      className={
                        classNames(classes.groupItem, {
                          [classes.groupTitle]: link.bold,
                          [classes.groupLink]: !link.bold,
                          [classes.block]: !link.bold && link.block,
                          [classes.paragraph]: link.text.length > 30,
                        })
                      }
                      key={link.text}
                      to={link.link}
                      onClick={() => link.scrollTop && window.scrollTo(0, 0)}
                    >
                      {link.text}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Main>
      {copyRightsVisible
          ? (
            <div className={classes.copyRights}>
              <p>
                {locale.footer.copyRights.map(link => (
                  <Link
                    to={link.link}
                    className={classes.copyRightLink}
                    key={link.text}
                  >
                    {link.text.replace('%YEAR%', new Date().getFullYear().toString())}
                  </Link>
                ))}
                {locale.footer.securityLinks.map(link => (
                  <Link
                    to={link.link!}
                    className={classes.copyRightLink}
                    key={link.text}
                  >
                    {
                      link.icon
                      ? (
                        <Fragment>
                          <img className={classes.icon} src={link.icon} alt='' />
                          &nbsp;
                        </Fragment>
                      )
                      : null
                    }
                    {link.text.replace('%YEAR%', new Date().getFullYear().toString())}
                  </Link>
                ))}
              </p>
            </div>
          )
          : null
        }
    </div>
  )
}

export default Footer
