import {ossImg} from '../../../utils/oss'
import {getValue} from '../../../utils/view'

const CN = () => ({
  seo: {
    title: '海外业务 - 万物新生',
    description: 'AHS Device:万物新生国际版B2B交易平台, 面向全球企业和商家的国际化业务,质检与平台赋能,输出技术解决方案.',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网'
  },
  banner: {
    logo: require('../../../assets/logo-business-device.png'),
    name: '',
    position: '打造国际版B2B交易平台',
    introduction: '面向全球企业和商家的国际化业务，质检与平台赋能，输出技术解决方案'
  },
  breadcrumb: '首页/品牌业务/海外业务',
  about: {
    cover: ossImg('/business/device/global.png'),
    infos: [
      [
        {
          type: 'h1',
          text: '将二手消费电子供应链能力复制到海外'
        },
        {
          type: 'subTitle',
          text: '二手手机3C是一个全球流通的生意，但海外市场的大宗、低频的贸易模式较传统，运营质检成本高'
        },
        {
          type: 'description',
          text: '一部二手手机可以在全球范围内流通多次，从发达国家和地区流向发展中国家和地区。我们用在中国积累的技术能力，尤其是自动化检测技术，去赋能已经存在多年的海外产业链，在全球范围内，实现更加高效的循环和流通，最大限度延长电子产品的生命周期。'
        }
      ],
      [
        {
          type: 'h3',
          text: 'AHS Device是万物新生集团的国际化业务，办公室设在中国香港'
        },
        {
          type: 'subTitle',
          text: '业务覆盖中国香港，东南亚、拉丁美洲、非洲等地区。未来将持续输出在国内积累的创新技术，复制平台能力，赋能海外商家，让全球的二手电子产品更高效流转，延长生命周期，在更广泛的全球市场实现可持续发展。'
        }
      ]
    ]
  },
  process: {
    sectionName: '立足香港，链接深圳供应链和欧洲销售渠道',
    cover: getValue({
      large: ossImg('/business/device/link-cn.png'),
      small: ossImg('/business/device/link-cn-m.png'),
    }),
  },
  regions: {
    sectionName: '布局全球',
    cover: require('../../../assets/glob-map.png'),
    areas: [
      {
        areaName: '亚洲',
        cities: ['香港', '日本', '韩国', '印度尼西亚', '阿联酋', '印度', '马来西亚', '新加坡', '菲律宾', '缅甸']
      },
      {
        areaName: '欧洲',
        cities: ['瑞典', '德国', '俄罗斯', '英国']
      },
      {
        areaName: '北美',
        cities: ['美国']
      },
      {
        areaName: '南美',
        cities: ['巴西']
      }
    ]
  }
})

const US = () => ({
  seo: {
    title: 'AHS Device - ATRenew',
    description: 'AHS Device - ATRenew\'s international B2B trading platform - take industry technologies and infrastructure global.',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai'
  },
  banner: {
    logo: require('../../../assets/logo-business-device.png'),
    name: '',
    position: 'An international B2B trading platform',
    introduction: 'Export technology and infrastructure for global merchants and enterprises'
  },
  breadcrumb: 'Home / Business / AHS Device',
  about: {
    cover: ossImg('/business/device/global.png'),
    infos: [
      [
        {
          type: 'h1',
          text: 'Empowering global partners with ATRenew’s pre-owned consumer electronics supply chain capabilities'
        },
        {
          type: 'subTitle',
          text: 'Pre-owned consumer electronics transaction is a global enterprise.'
        },
        {
          type: 'description',
          text: 'We seek to increase the global circulation of pre-owned devices and to empower the existing value chain overseas by leveraging our advanced technologies, chiefly our automatic testing process. We believe that technology will drive increases in global circulation efficiency and extend the lifecycle of pre-owned products, benefitting the world economy and environmental sustainability.'
        }
      ],
      [
        {
          type: 'h3',
          text: 'AHS Device is the international business arm of ATRenew.'
        },
        {
          type: 'subTitle',
          text: 'With AHS Device Hong Kong office, our international reach extends to Hong Kong, Southeast Asia, Latin America, Africa and other regions. As we grow, we will continue to export our domestic technological innovations, replicate platform capabilities and empower overseas business partners to drive a more efficient circulation of second-hand electronic products around the world. This will extend second-hand device life cycles, contributing to a more sustainable development of the industry worldwide.'
        }
      ]
    ]
  },
  process: {
    sectionName: 'Established in Hong Kong, AHS Device connects distribution channels in Europe with the supply chain in Shenzhen',
    cover: getValue({
      large: ossImg('/business/device/link-en.png'),
      small: ossImg('/business/device/link-en-m.png'),
    }),
  },
  regions: {
    sectionName: 'Global blueprint',
    cover: require('../../../assets/glob-map.png'),
    areas: [
      {
        areaName: 'Asia',
        cities: ['Hong Kong SAR', 'Japan', 'Korea', 'Indonesia', 'United Arab Emirates', 'India', 'Malaysia', 'Singapore', 'Philippines', 'Myanmar']
      },
      {
        areaName: 'Europe',
        cities: ['Sweden', 'Germany', 'Russia', 'United Kingdom']
      },
      {
        areaName: 'North America',
        cities: ['United States']
      },
      {
        areaName: 'South America',
        cities: ['Brazil']
      }
    ]
  }
})

const data = {
  CN,
  US
}
export default data
