import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useLocale} from '../../../locales'
import Section from './Section'
import Icon from 'components/Icon/Index'

import classes from './News.module.scss'
import {isSmall, scrollIntoView} from '../../../utils/view'

function News() {
  const locale = useLocale()
  const [openedItem, setOpenedItem] = useState<typeof news.list[0] | null>(null)
  const news = useMemo(
    () => locale.pages.Home.news,
    [locale.pages.Home.news]
  )
  const toggle = useCallback(
    (item, index) => {
      if (item.title === openedItem?.title) {
        setOpenedItem(null)
        return
      }
      setOpenedItem(item)
      if (isSmall()) {
        scrollIntoView(`news_${index}`)
      }
    },
    [openedItem?.title]
  )
  useEffect(
    () => {
      if (news.list.length) setOpenedItem(news.list[0])
    },
    [news.list]
  )
  return (
    <Section
      name={news.title}
      border
      mainClassName={classes.mainContainer}
    >
      <div className={classes.main}>
        {
          news.list.map((ele, i) => (
            <div
              className={classes.container}
              key={ele.title}
              id={`news_${i}`}
            >
              <div
                className={classes.header}
                onClick={() => toggle(ele, i)}
              >
                <div className={classes.title}>
                  {ele.title}
                </div>
                <div className={classes.iconBox}>
                  <Icon icon={openedItem?.title === ele.title ? 'Reduce' : 'Add'} scale={0.8}/>
                </div>
              </div>
              {
                openedItem?.title === ele.title
                  ? (
                    <div className={classes.panelBox}>
                      <div className={classes.panel}>
                        <div className={classes.sections}>
                          {
                            ele.sections.map(item => (
                              <div
                                className={classes.section}
                                key={item.header}
                              >
                                <div className={classes.headerText}>
                                  {item.header}
                                </div>
                                <div className={classes.description}>
                                  {item.description}
                                </div>
                              </div>
                            ))
                          }
                        </div>
                        <img
                          className={classes.cover}
                          src={ele.cover}
                          alt={ele.title}
                        />
                      </div>
                    </div>
                  )
                  : null
              }
            </div>
          ))
        }
      </div>
    </Section>
  )
}

export default News
