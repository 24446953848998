import React, { ReactNode } from 'react'
import classNames from 'classnames'

import classes from './Grid.module.scss'

interface GridItem {
  image?: string
  title?: ReactNode
  content?: ReactNode
}

interface GridProps {
  className?: string
  data?: GridItem[]
  titleClassName?: string
  contentClassName?: string
}

function Index(props: GridProps) {
  const {
    className,
    titleClassName,
    contentClassName,
    data
  } = props

  if (!data || !data.length) return null
  return (
    <div className={classNames(classes.grid, className)}>
      {
        data.map((item, index) => (
        <div key={index.toString()}>
          {
            item.image
              ? (
                <div className={classes.image}>
                  <img src={item.image} alt='' />
                </div>
              )
              : null
          }
          {
            item.title
              ? (<span className={titleClassName}>{item.title}</span>)
              : null
          }
          {
            item.content
              ? (<p className={contentClassName}>{item.content}</p>)
              : null
          }
        </div>
        ))
      }
    </div>
  )
}

export default Index