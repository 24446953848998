import React from 'react'

import Article, { ArticleModel } from './Article'
import classes from './Series.module.scss'

function Series(props: {
  title: string
  series: Array<ArticleModel>
}) {
  return (
    <div className={classes.Series}>
      <p className={classes.title}>
        {props.title}
      </p>
      <div className={classes.articles}>
        {
          props.series.map(article => (
            <Article key={article.title} data={article}></Article>
          ))
        }
      </div>
    </div>
  )
}

export default Series