import React, {useMemo} from 'react'
import {useLocale} from '../../locales'
import Banner from './components/Banner'
import About from './components/About'
import Breadcrumb from 'components/Breadcrumb/Index'
import Operate from './components/Paijitang/Operate'
import Automation from './components/Paijitang/Automation'
import Service from './components/Paijitang/Service'
import Business from './components/Paijitang/Business'
import Swiper from './components/Paijitang/Swiper'
import Manage from './components/Paijitang/Manage'
import Seo from '../../components/Head/Seo'

import classes from './Paijitang.module.scss'

export default function Paijitang() {
  const locale = useLocale()
  const paijitang = useMemo(
    () => locale.pages.Business.Paijitang,
    [locale.pages.Business.Paijitang]
  )

  return (
    <div className={classes.Paijitang}>
      <Seo seo={paijitang.seo} />
      <Banner
        { ...paijitang.banner }
        background='var(--theme-gradient)'
      />
      <Breadcrumb title={paijitang.breadcrumb}/>
      <About data={paijitang.about as any} />
      <Operate />
      <Automation />
      <Service />
      <Manage />
      <Business />
      <Swiper />
    </div>
  )
}
