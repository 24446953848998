import React, {useMemo} from 'react'
import {useLocale} from '../../../../locales'

import classes from './Comparison.module.scss'
import Section from '../../../../components/Section/Index'

function Comparison() {
  const locale = useLocale()
  const comparison = useMemo(
    () => locale.pages.Business.Paipai.comparison,
    [locale.pages.Business.Paipai.comparison]
  )

  return (
    <Section.Full
      background='var(--theme-gradient)'
    >
      <div className={classes.main}>
        <div className={classes.left}>
          <div className={classes.title}>
            {comparison.left.title}
          </div>
          <div className={classes.subTitle}>
            {comparison.left.subTitle}
          </div>
          <div className={classes.icons}>
            {
              comparison.left.icons.map(ele => (
                <div className={classes.icon}
                >
                  <img
                    src={ele}
                    alt={ele}
                  />
                </div>
              ))
            }
          </div>
        </div>
        <img
          className={classes.separate}
          src={comparison.separate}
          alt=''
        />
        <div className={classes.right}>
          <div className={classes.title}>
            {comparison.right.title}
          </div>
          <div className={classes.subTitle}>
            {comparison.right.subTitle}
          </div>
          <div className={classes.icons}>
            {
              comparison.right.icons.map(ele => (
                <div className={classes.icon}
                >
                  <img
                    src={ele}
                    alt={ele}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Section.Full>
  )
}

export default Comparison
