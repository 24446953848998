import { ossImg } from 'utils/oss'
import { getValue } from 'utils/view'

const CN = () => ({
  seo: {
    title: 'ESG社会责任 - 万物新生',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网',
    description: '万物新生热心公益,秉承负责任的态度与利益相关方开展业务,通过对生态系统的影响力,与合作伙伴携手同行,共同创造社会和环境价值.',
  },
  breadcrumb: '首页 / ESG / 社会责任',
  banner: getValue({
    large: ossImg('/esg/overall-banner.png'),
    small: ossImg('/esg/esg-m-banner.png'),
  }),
  social: {
    title: '社会责任',
    content:
      '万物新生高度关注可持续性和社会责任，秉承负责任的态度与利益相关方开展业务，通过对生态系统的影响力，与合作伙伴携手同行，共同创造社会和环境价值。',
  },
  join: {
    title: '行业参与',
    content: [
      '作为中国旧货行业协会副会长单位、上海市信息服务业行业协会的理事单位和上海市再生资源回收利用行业协会会员，万物新生发挥其行业领跑者的独特优势和洞察，积极参与行业、团体和协会的交流合作。',
      '万物新生集团旗下子公司与中国旧货业协会、行业企业和委员会，共同起草部分互联网旧货交易平台的建设和管理要求、二手电子产品的检测鉴定标准等文件。',
    ],
    images: [
      { value: ossImg('/esg/responsibility-join1.png') },
      { value: ossImg('/esg/responsibility-join2.png') }
    ],
  },
  commonweal: {
    title: '社会公益',
    donates: {
      title: '抗疫行动，防疫物资捐赠',
      content:
        [
          '2020年第一季度，COVID-19新冠肺炎疫情在全国爆发。万物新生守望相助，本集团员工积极参与公益捐赠，员工与CEO捐款超50万元。集团采买紧缺物资并定向向湖北省大冶市新建医院、第二人民医院和金牛镇金牛医院进行捐赠。',
          '2022年4月，上海疫情复发，万物新生向上海各区累计捐赠近80吨蔬菜和生活物资，同时为杨浦区捐赠价值40万元的二手手机作为核酸检测信息采集使用的防疫物资，为复旦大学捐赠20万元用于购置可循环使用的保鲜饭盒，为同济大学捐赠价值11.5万元的防护服、手套、护目镜等医疗物资。此外，集团还向居家办公的员工提供两批生活物资礼包，切实解决员工买菜难题。'
        ],
      image: { value: ossImg('/esg/responsibility-gift.png') },
    },
    recycling: {
      title: '回收爱，乡村儿童数码助学计划',
      subTitle:
        '回收爱公益项目于 2018 年诞生，是最具万物新生特色的长期公益项目，为社会的可持续化发展助力。',
      content:
        '回收爱乡村儿童数码助学计划由万物新生与一扇窗计划公益组织共同发起，通过将闲置不用的电子设备定向捐助，以在线教育助力山村儿童开阔视野，与城市儿童站在同一起跑线上，并延长电子设备的使用寿命。',
      images: [
        { value: ossImg('/esg/responsibility-hsa1.png') },
        { value: ossImg('/esg/responsibility-hsa2.png') },
        { value: ossImg('/esg/responsibility-hsa3.png') },
        { value: ossImg('/esg/responsibility-hsa4.png') },
      ],
      units: [
        {
          name: '已支持学校',
          unit: '所',
          value: '56',
        },
        {
          name: '完成授课',
          unit: '小时',
          value: '2,068',
        },
        {
          name: '上课学生',
          unit: '人',
          value: '10,770',
        },
      ],
      tips: '注：数据截至 2022 年 12 月 31 日',
    },
  },
})

const US = () => ({
  seo: {
    title: 'ESG Social Responsibilities - ATRenew',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai',
    description: 'ATRenew interacts responsibly with its stakeholders, and taking concrete actions to shape a responsible ecosystem.',
  },
  breadcrumb: 'Home / ESG / Social Responsibility',
  banner: getValue({
    large: ossImg('/esg/overall-banner.png'),
    small: ossImg('/esg/esg-m-banner.png'),
  }),
  social: {
    title: 'Social Responsibility',
    content:
      'ATRenew interacts responsibly with its stakeholders, growing together as partners within the greater ecosystem. We develop management rules, assessment criteria and also deliver targeted training to empower our platform merchants. We are aware of the importance of sustainability and social responsibility, and we are committed to taking concrete action to help shape a responsible ecosystem and generate social and environmental value.',
  },
  join: {
    title: 'Industry Involvement',
    content: [
      'ATRenew serves as Vice-chair of the China Resale Goods Trading Association, a council member of the Shanghai Information Services Association and as a member of the Shanghai Resource Recycling Association. Harnessing our unique advantages and insights as an industry leader, we actively engage in exchanges and cooperation within our sector, groups, and associations.',
      'Guided by CRGTA, a wholly-owned subsidiary of ATRenew, we joined peer enterprises and industrial committees in drafting four standards for second-hand electronics inspection and identification. The four group standards contain the basic requirements for testing and instructions for setting technical parameters and grades.',
    ],
    images: [
      { value: ossImg('/esg/responsibility-join1.png') },
      { value: ossImg('/esg/responsibility-join2.png') },
    ],
  },
  commonweal: {
    title: 'Social Welfare',
    donates: {
      title: 'COVID-19 Response and Donations',
      content:
        [
          "In the first quarter of 2020, the COVID-19 pandemic outbreak in China was an unprecedented challenge. Uniting to face this challenge, aid donations from our employees and CEO exceeded RMB500,000. In addition, the Company promptly purchased materials in short supply and shipped them to the Second People's Hospital in Daye City and Jinniu Hospital in Hubei Province.",
          "In April, in response to the COVID resurgence, ATRenew donated a total of nearly 80 metric tons of vegetables and groceries to various districts in Shanghai, a batch of pre-owned phones worth RMB400,000 in total to Yangpu District as information collection devices in support of PCR tests, RMB200,000 in cash to purchase recyclable lunch boxes to Fudan University, and medical supplies such as protective clothing, gloves, and goggles worth of RMB115,000 to Tongji University. In addition, ATRenew also delivered two batches of daily necessities to employees working from home.",
        ],
      image: { value: ossImg('/esg/responsibility-gift.png') },
    },
    recycling: {
      title: 'AHS Charity and Love · Digital Education Aid Program for Rural Children',
      subTitle:
        'Founded in 2018, our charity program “AHS Charity and Love” contributes to the sustainable development of society.',
      content:
        'The “AHS Charity and Love · Digital Education Aid Program for Rural Children” was jointly initiated by ATRenew and Pad for Hope, a charity organization. Through our program, ATRenew repurposes idle electronic devices and extends their life cycle. The program helps children in rural villages broaden their horizons through online education using pre-owned electronic devices. This education helps give them opportunities traditionally only available to children living in cities.',
      images: [
        { value: ossImg('/esg/responsibility-hsa1.png') },
        { value: ossImg('/esg/responsibility-hsa2.png') },
        { value: ossImg('/esg/responsibility-hsa3.png') },
        { value: ossImg('/esg/responsibility-hsa4.png') },
      ],
      units: [
        {
          name: 'Schools supported',
          unit: '',
          value: '56',
        },
        {
          name: 'Total teaching hours',
          unit: '',
          value: '2,068',
        },
        {
          name: 'Students served',
          unit: '',
          value: '10,770',
        },
      ],
      tips: 'For the year ended 31 December, 2022.',
    },
  },
})

const data = {
  CN,
  US
}
export default data
