import React from 'react'

import classes from './Banner.module.scss'

interface Props {
  logo: string
  name: string
  position: string
  introduction: string
  background: string
}

function Banner(props: Props) {
  const {
    logo,
    position,
    name,
    introduction,
    background
  } = props

  return (
    <div
      className={classes.Banner}
      style={
        {background}
      }
    >
      <div className={classes.main}>
        <div className={classes.logoBox}>
          <img
            src={logo}
            alt='ahs'
            className={classes.logo}
          />
          <p className={classes.name}>{ name }</p>
        </div>
        <div className={classes.slogan}>
          <div className={classes.title}>
            {position}
          </div>
          <div className={classes.text}>
            {introduction}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
