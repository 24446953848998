import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import { LocaleConfig, useLocale, useLocaler } from 'locales'
import Main from 'components/Main/Index'
import Icon from 'components/Icon/Index'
import Link from 'components/NavButton/Link'
import useScreen from 'utils/screen'
import { ScreenType } from 'utils/view'

import classes from './Index.module.scss'

type MenuLocal = 'both' | Locale
type Menu = {
  text: string
  link: string
  locale?: MenuLocal
  thirdLink?: boolean
  subMenus?: Array<Menu>
}

function getMenus(locale: LocaleConfig) {
  const menus: Array<Menu> = [{
    text: locale.pages.Home.title,
    link: '/',
  }, {
    text: locale.pages.Business.title,
    link: '',
    subMenus: locale.pages.Business.business
      .map((b) => ({
        link: b.link,
        text: b.name,
      })),
  }, {
    text: locale.pages.News.title,
    link: '',
    subMenus: locale.pages.News.menus
      .map(b => ({
        link: b.link,
        text: b.name,
      }))
  }, {
    text: locale.pages.Innovation.title,
    link: '/innovation',
  }, {
    text: locale.pages.Investors.title,
    link: '',
    subMenus: locale.pages.Investors.menus
      .map(b => ({
        link: b.link,
        text: b.name,
        subMenus: b.subMenu?.map(c => ({
          link: c.link,
          text: c.name,
        })),
      }))
  }, {
    text: locale.pages.ESG.title,
    link: '',
    subMenus: locale.pages.ESG.menus
      .map(b => ({
        link: b.link,
        text: b.name,
      }))
  }]

  return menus.filter(m => !m.locale || m.locale === 'both' || m.locale === locale.code)
}

interface MenuComponentProps {
  data: Menu[]
  screenType?: ScreenType
  hasParent?: boolean
  closeMenu?: () => void
}

function MenuComponent(props: MenuComponentProps) {
  const {
    data,
    screenType,
    hasParent,
    closeMenu,
  } = props

  const [selects, setSelects] = useState<string[]>([])

  const onClickMenu = useCallback((item: Menu) => {
    setSelects(current => current.includes(item.text) ? [] : [item.text])
  }, [])

  if (typeof screenType !== 'number') return null

  return (
    <>
      {
        data.map((menu, menuIndex) => {
          const isMain = Boolean(menu.subMenus?.length)
          const divider = menuIndex + 1 < data.length && !hasParent
          const openMenu = selects.includes(menu.text)
          if (isMain) {
            return (
              <>
                {/* pc 结构 */}
                <div
                  key={menu.text}
                  className={classNames('hidden-small', classes.subMenuItem)}
                >
                  <label className={classes.name}>
                    {menu.text}
                  </label>
                  <MenuComponent
                    data={menu.subMenus!}
                    screenType={screenType}
                    hasParent
                  />
                </div>
                {/* m 结构 */}
                <nav
                  key={menu.text}
                  className={classNames('hidden-large', classes.menus)}
                >
                  <div
                    key={menu.text}
                    className={
                      classNames(
                        classes.menu,
                        { [classes.divider]: divider },
                      )
                    }
                  >
                    <div
                      className={classes.title}
                      onClick={() => onClickMenu(menu)}
                    >
                      <span>{hasParent ? '· ' : ''}{menu.text}</span>
                      <div
                        className={
                          classNames(
                            classes.arrow,
                            { [classes.inverts]: openMenu })
                        }
                      >▾</div>
                    </div>
                    {
                      openMenu && (
                        <MenuComponent
                          key={menu.text}
                          data={menu.subMenus!}
                          screenType={screenType}
                          closeMenu={closeMenu}
                          hasParent
                        />
                      )
                    }
                  </div>
                </nav>
              </>
            )
          } else {
            return (
              <>
                {/* pc 结构 */}
                <div
                  key={menu.text}
                  className={
                    classNames(
                      'hidden-small',
                      classes.subMenu,
                      { [classes.alone]: !hasParent },
                    )
                  }
                >
                  <Link
                    activeClassName={classes.active}
                    to={menu.link}
                    exact
                  >
                    · {menu.text}
                  </Link>
                </div>
                {/* 移动端 结构 */}
                {
                  hasParent
                    ? (
                      <div
                        key={menu.text}
                        className={classNames('hidden-large', classes.subMenus)}
                      >
                        <Link
                          activeClassName={classes.active}
                          to={menu.link}
                          key={menu.text}
                          onClick={() => {
                            if (!menu.thirdLink && closeMenu) {
                              closeMenu()
                              window.scrollTo(0, 0)
                            }
                          }}
                          exact
                        >
                          <span>· {menu.text}</span>
                        </Link>
                      </div>
                    )
                    : (
                      <div
                        key={menu.text}
                        className={classNames('hidden-large', classes.menus)}
                      >
                        <div
                          className={
                            classNames(classes.menu, classes.divider)
                          }
                        >
                          <Link
                            activeClassName={classes.active}
                            className={classes.title}
                            to={menu.link}
                            key={menu.text}
                            onClick={() => {
                              if (!menu.thirdLink && closeMenu) {
                                closeMenu()
                                window.scrollTo(0, 0)
                              }
                            }}
                            exact
                          >
                            <span>{menu.text}</span>
                          </Link>
                        </div>
                      </div>
                    )
                }
              </>
            )
          }
        })
      }
    </>
  )
}

function Index() {
  const [isOpen, setIsOpen] = useState(false)
  const locale = useLocale()
  const localer = useLocaler()
  const location = useLocation()
  const locales = useMemo(() => localer.locales, [localer.locales])
  const menus = useMemo(() => getMenus(locale), [locale])
  const { screenType } = useScreen()

  const hideSubMenu = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement
    const isParentMenu = (target as HTMLInputElement).name === 'menus'
      || (target.nextSibling as HTMLInputElement)?.name === 'menus'
    if (isParentMenu) return
    const indicators = Array.prototype.slice.call(
      document.getElementsByClassName(classes.indicator)
    )
    indicators.forEach((input: HTMLInputElement) => {
      input.checked = false
    })
  }, [])

  useEffect(() => {
    document.addEventListener('click', hideSubMenu, false)

    return () => {
      document.removeEventListener('click', hideSubMenu)
    }
  }, [hideSubMenu])

  const onPressMenu = useCallback(() => {
    setIsOpen(current => !current)
  }, [])

  const linksOfLocale = useMemo(() => {
    const urls = localer.locales.reduce<Record<string, string>>((map, l) => {
      map[l.code] = `/${l.code}${location.pathname}`
      return map
    }, {})
    return urls
  }, [localer.locales, location.pathname])

  return (
    <div className={classes.box}>
      <Main className={classes.AppHeader}>
        <div className={classes.logo}>
          <Link to='/'>
            <img src={locale.logo} alt={locale.title} />
          </Link>
          <Icon
            className={classes.menuIcon}
            icon={isOpen ? 'Close' : 'Menu'}
            color='#000'
            onClick={onPressMenu}
          />
        </div>
        <nav
          className={
            classNames('hidden-small', classes.menus)
          }
        >
          {
            menus.map(menu => (
              <div key={menu.text} className={classes.menu}>
                {
                  menu.subMenus?.length
                    ? (
                      <div className={classes.menuBox}>
                        <Link
                          className={classes.activeIndicator}
                          activeClassName={classes.active}
                          to={menu.link}
                        >
                          {menu.text}
                        </Link>
                        <label
                          className={
                            classNames(classes.name, classes.menuTitle)
                          }
                        >
                          {menu.text} ▾
                        </label>
                        <div className={classes.subMenus}>
                          <MenuComponent
                            data={menu.subMenus}
                            screenType={screenType}
                          />
                        </div>
                      </div>
                    )
                    : (
                      <Link
                        className={
                          classNames(
                            classes.title,
                            { [classes.inactive]: menu.thirdLink },
                          )
                        }
                        activeClassName={classes.active}
                        to={menu.link}
                        exact
                      >
                        <span>{menu.text}</span>
                      </Link>
                    )
                }
              </div>
            ))
          }
        </nav>
        <div
          className={
            classNames('hidden-large', classes.menusBox,
              { [classes.open]: isOpen })
          }
        >
          <MenuComponent
            data={menus}
            screenType={screenType}
            closeMenu={() => setIsOpen(false)}
          />
        </div>
        <div
          className={
            classNames(
              classes.localer,
              { [classes.hide]: !isOpen }
            )
          }
        >
          {
            locales.map(l => (
              <a
                key={l.code}
                className={classNames(
                  classes.locale,
                  { [classes.active]: l.code === locale.code }
                )}
                href={linksOfLocale[l.code]}
              >
                {l.label}
              </a>
            ))
          }
        </div>
      </Main>
    </div >
  )
}

export default Index
