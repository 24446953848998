import React, {useMemo, useState} from 'react'
import classNames from 'classnames'
import {useLocale} from '../../../../locales'
import Section from '../../../../components/Section/Index'
import Dots from '../../../../components/Dots/Index'
import SwiperInstance from '../../../../components/Swiper/Index'
import {isSmall} from '../../../../utils/view'

import classes from './Swiper.module.scss'

function SwiperContainer() {
  const [swiperIndex, setSwiperIndex] = useState(0)
  const locale = useLocale()
  const swipers = useMemo(
    () => locale.pages.Business.Paijitang.swipers,
    [locale.pages.Business.Paijitang.swipers]
  )

  return (
    <Section.Full background={isSmall() ? '#F5F6FA' : '#fff'}>
      <SwiperInstance onChange={(i) => setSwiperIndex(i)}>
        {
          swipers.map((ele, index) => (
            <SwiperInstance.Item>
              <div className={classes.main}>
                <div className={classes.info}>
                  <div className={classes.title}>
                    {ele.title}
                  </div>
                  <div className={classes.subTitle}>
                    {ele.subTitle}
                  </div>
                  <div className={classes.description}>
                    {ele.list.map(item => (
                      <div className={classes.item}>
                        <div className={classes.name}>
                          {item.name}
                        </div>
                        <div className={classes.values}>
                          {
                            item.values.map(curr => (
                              <div className={classes.value}>
                                {curr}
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                  <Dots
                    total={swipers.length}
                    activeIndex={index}
                    className='hidden-small'
                  />
                </div>
                <img
                  className={classes.cover}
                  src={ele.cover}
                  alt={ele.title}
                />
              </div>
            </SwiperInstance.Item>
          ))
        }
      </SwiperInstance>
      <div className={classNames(classes.swiperDots, 'hidden-large')}>
        <Dots total={swipers.length} activeIndex={swiperIndex}/>
      </div>
    </Section.Full>
  )
}

export default SwiperContainer
