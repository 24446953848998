import React, {useMemo} from 'react'
import {default as iconPdf} from '../../../assets/icon-pdf.png'
import {useLocale} from '../../../locales'
import classes from './About.module.scss'

function About() {
  const locale = useLocale()
  const about = useMemo(
    () => locale.pages.Home.about,
    [locale.pages.Home.about]
  )

  return (
    <div className={classes.About}>
      <div className={classes.info}>
        <div className={classes.title}>
          {about.title}
        </div>
        <div className={classes.description}>
          {about.description}
        </div>
        {
          about?.link?.url
            ? (
              <a className={classes.link} href={about.link.url}>
                <span>{about.link.name}</span>
                <img
                  className={classes.icon}
                  src={iconPdf}
                  alt={about.link.name}
                />
              </a>
            )
            : null
        }
      </div>
      <div className={classes.list}>
        {
          about?.list?.map(ele => (
            <div className={classes.item} key={ele.name}>
              <img
                className={classes.icon}
                src={ele.icon}
                alt={ele.name}
              />
              <div className={classes.box}>
                <div className={classes.name}>
                  {ele.name}
                </div>
                <div className={classes.subTitle}>
                  {ele.subTitle}
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default About
