import React, { useMemo } from 'react'
import Helmet from 'react-helmet'

import { useLocale } from '../../locales'

function Seo(props: {
  seo: {
    title?: string
    keywords?: string
    description?: string
  }
}) {
  const { seo } = props
  const locale = useLocale()
  const title = useMemo(
    () => seo.title ? `${seo.title}` : locale.title,
    [seo, locale]
  )
  return (
    <Helmet>
      <title>{title}</title>
      {seo.keywords ? (<meta name='keywords' content={seo.keywords} />) : null}
      {seo.description ? (<meta name='description' content={seo.description} />) : null}
    </Helmet>
  )
}

export default Seo