import React from 'react'
import classnames from 'classnames'
import Section from 'components/Section/Index'
import LineSeparate from '../../../components/LineSeparate/Index'

import classes from './About.module.scss'

interface H1 {
  type: 'h1'
  text: string
}

interface H3 {
  hidePrefixIcon: any
  type: 'h3'
  text: string
}

interface Description {
  type: 'description'
  text: string
}

interface Subtitle {
  type: 'subTitle'
  text: string
}

interface Icons {
  type: 'icons'
  layoutType: string
  list: List[]
}

export interface List {
  icon: string
  name: string
}


interface Props {
  data: {
    cover: string
    infos: Array<Array<H1 | H3 | Description | Subtitle | Icons>>
  }
}

export default function About(props: Props) {
  const {data} = props

  return (
    <Section className={classes.main}>
      <div className={classes.About}>
        <div className={classes.info}>
          {
            data.infos.map((item) => (
              <div className={classes.section}>
                {
                  item.map((ele) => {
                    switch (ele.type) {
                      case 'h1': {
                        return (
                          <div className={classes.h1}>
                            {ele.text}
                          </div>
                        )
                      }
                      case 'h3': {
                        return (
                          <div className={classes.h3}>
                            {
                              ele?.hidePrefixIcon
                                ? null
                                : <LineSeparate className={classes.icon}/>
                            }

                            {ele.text}
                          </div>
                        )
                      }
                      case 'description': {
                        return (
                          <div className={classes.description}>
                            {ele.text}
                          </div>
                        )
                      }
                      case 'subTitle': {
                        return (
                          <div
                            className={classes.subTitle}
                            dangerouslySetInnerHTML={
                              {__html: ele.text}
                            }
                          />
                        )
                      }
                      case 'icons': {
                        return (
                          <div className={classnames(
                            classes.icons,
                            classes[ele.layoutType]
                          )}
                          >
                            {
                              ele.list.map(curr => (
                                <div className={classes.item}>
                                  <img src={curr.icon} alt={curr.name}/>
                                  <span className={classes.name}>
                                {curr.name}
                              </span>
                                </div>
                              ))
                            }
                          </div>
                        )
                      }
                      default: {
                        return null
                      }
                    }
                  })
                }
              </div>
            ))
          }
        </div>
        <img
          src={data.cover}
          alt={data.cover}
          className={classes.cover}
        />
      </div>
    </Section>
  )
}
