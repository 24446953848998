import React, { useMemo } from 'react'
import classNames from 'classnames'
import Section from '../../../../components/Section/Index'

import classes from './Automation.module.scss'
import {useLocale} from '../../../../locales'
import Units from './Units'

function Automation() {
  const locale = useLocale()
  const automation = useMemo(
    () => locale.pages.Business.Paijitang.automation,
    [locale.pages.Business.Paijitang.automation]
  )

  return (
    <Section
      name={automation.sectionName}
      hideBottomLine
    >
      <div className={classNames(classes.main, classes.Automation)}>
        <div className={classes.left}>
          { automation.description }
        </div>
        <div className={classes.right}>
          <Units
            data={automation.units}
            className={classes.units}
            itemClassName={classes.unitItem}
          />
          <p className={classes.notes}>
            {automation.notes}
          </p>
        </div>
      </div>
    </Section>
  )
}

export default Automation
