import React from 'react'
import classNames from 'classnames'

import classes from './Units.module.scss'

interface ValueItem {
  name: string
  value: string
}
interface UnitItem {
  name: string
  unit: string
  value: string | ValueItem[]
  description?: string
  sup?: string
}

interface Props {
  className?: string
  itemClassName?: string
  data: UnitItem[]
}

function Units(props: Props) {
  const {
    className,
    itemClassName,
    data
  } = props
  return (
    <div className={classNames(classes.units, className)}>
      {
        data.map(ele => (
          <div
            className={classNames(classes.item, itemClassName)}
            key={ele.name}
          >
            <div className={classNames(classes.name, 'hidden-small')}>
              <span>{ele.name}</span>
            </div>
            <div
              className={
                classNames(
                  'hidden-small',
                  classes.value,
                  { [classes.values]: typeof ele.value !== 'string' }
                )
              }
            >
              {
                typeof ele.value === 'string'
                  ? (
                    <>
                      {ele.value} <sup>{ele.sup}</sup><span className={classes.unit}>{ele.unit || ''}</span>
                    </>
                  )
                  : ele.value.map(item => (
                    <div className={classes['value-item']}>
                      {item.name} -- <strong> {item.value} </strong>
                      <span className={classes.unit}>{ele.unit}</span>
                    </div>
                  ))
              }
            </div>
            <div
              className={
                classNames(
                  'hidden-small',
                  classes.description,
                )
              }
            >
              {ele.description}
            </div>
            {
              typeof ele.value === 'string'
                ? (
                  <>
                    <div className={classNames(classes.name, 'hidden-large')}>
                      <span>{ele.name}</span>
                    </div>
                    <div className={classNames(classes.value, 'hidden-large')}>
                      {ele.value}
                      <sup>{ele.sup}</sup>
                      <span>{ele.unit}</span>
                    </div>
                    <div
                      className={
                        classNames(
                          'hidden-large',
                          classes.description,
                        )
                      }
                    >
                      {ele.description}
                    </div>
                  </>
                )
                : (
                  <>
                    <div className={classNames(classes.name, 'hidden-large')}>
                      <span>{ele.name}</span>
                    </div>
                    <div
                      className={classNames(
                        classes.value,
                        classes.values,
                        'hidden-large'
                      )}
                    >
                      {
                        ele.value.map(item => (
                          <div className={classes['value-item']}>
                            {item.name} -- <span> {item.value} </span>
                            <sup>{ele.sup}</sup>
                            {ele.unit}
                          </div>
                        ))
                      }
                    </div>
                    <div
                      className={
                        classNames(
                          'hidden-large',
                          classes.description,
                        )
                      }
                    >
                      {ele.description}
                    </div>
                  </>
                )
            }
          </div>
        ))
      }
    </div>
  )
}

export default Units
