import React, {useMemo} from 'react'
import classNames from 'classnames'

import ArrowLeft from 'assets/arrow-left.png'
import ArrowRight from 'assets/arrow-right.png'

import classes from './Index.module.scss'

interface Props {
  onChange: (pageIndex: number) => void
  size: number
  total: number
  current: number
}

export default function PaginatedItems(props: Props) {
  const {
    total,
    size,
    current,
    onChange
  } = props
  const pageCount = useMemo(() => Math.ceil(total / size), [total, size])
  const pages = useMemo(
    () => [...new Array(pageCount)],
    [pageCount]
  )

  return (
    <div className={classes.paginate}>
      <div className={classes.list}>
        {
          current > 0
            ? (
              <div
                className={classes.item}
                onClick={() => onChange(current - 1)}
              >
                <img src={ArrowLeft} alt='arrow left'/>
              </div>
            )
            : null
        }
        {
          pages.map((ele, i) => (
            <div
              className={classNames(
                classes.item,
                {[classes.active]: current === i}
              )}
              onClick={() => onChange(i)}
            >
              {i + 1}
            </div>
          ))
        }

        {
          current + 1 < pageCount
            ? (
              <div
                className={classes.item}
                onClick={() => onChange(current + 1)}
              >
                <img src={ArrowRight} alt='arrow right'/>
              </div>
            )
            : null
        }
      </div>
    </div>
  )
}
