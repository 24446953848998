import React, { useMemo } from 'react'
import {useLocale} from '../../../locales'
import Section from '../../../components/Section/Index'

import classes from './ESG.module.scss'

export default function ESG() {
  const locale = useLocale()
  const system = useMemo(
    () => locale.pages.ESG.overall.system,
    [locale.pages.ESG.overall.system]
  )
  return (
    <Section name={system.sectionName}>
      <div className={classes.ESG}>
        <div className={classes.description}>
          { system.description }
        </div>
        <div className={classes.cover}>
          <img src={ system.cover } alt={system.sectionName} />
        </div>
        <div className={classes.section}>
          <div className={classes.title}>
            { system.section.title }
          </div>
          {
            system.section.list.map(ele => (
              <div className={classes.section}>
                { ele }
              </div>
            ))
          }
          <div className={classes.footer}>
            {
              system.section.footer.map(footer => (
                <div className={classes.part}>
                  {footer.content.map(content => (
                    <p key={content}>
                      {content}
                    </p>
                  ))}
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Section>
  )
}
