import React, { useCallback } from 'react'
import classNames from 'classnames'

import icons from './icons'

import classes from './Index.module.scss'

export type IconType = keyof typeof icons | 'ArrowUp'

const IconClass: Record<IconType, string> = {
  Close: classes.Close,
  Menu: classes.Menu,
  Play: classes.Play,
  ArrowDown: classes.ArrowDown,
  ArrowUp: classes.ArrowUp,
  Add: classes.Add,
  Reduce: classes.Reduce,
}

export interface IconProps {
  color?: string
  /**
   * 0.5 - 3
   */
  scale?: number
  icon: IconType
  className?: string
  onClick?: (event: React.MouseEvent) => void
}

function Index(props: IconProps) {
  const {
    color, //
    scale = 1,
    icon,
    className,
    onClick,
  } = props
  const formattedScale = Math.min(3, Math.max(0.5, scale))
  const stopPropagation = useCallback(
    (event: React.MouseEvent) => {
      if (onClick) {
        event.stopPropagation()
        onClick(event)
      }
    },
    [onClick],
  )
  return (
    <span
      className={classNames(classes.Icon, IconClass[icon], className)}
      style={{
        color,
        width: `${formattedScale * 1}em`,
        height: `${formattedScale * 1}em`,
      }}
      onClick={stopPropagation}
    />
  )
}

export default Index
