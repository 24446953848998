import { ossImg } from '../../utils/oss'
import { getValue } from '../../utils/view'

const CN = () => ({
  title: '首页',
  seo: {
    title: '万物新生集团 - 数字化循环经济引领者',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网',
    description: '万物新生集团是中国领先的电子产品循环经济企业，京东集团投资，纽交所上市。始终致力于“让闲置不用,都物尽其用”，为二手行业创造价值。',
  },
  banners: [
    {
      background: 'linear-gradient(180deg, #FDF557 0%, #F9E52C 100%)',
      buttonText: '观看介绍影片',
      imageUrl: getValue({
        small: ossImg('/home/banner-m-1.png'),
        large: ossImg('/home/banner-pc-cn-1.png'),
      }),
      videoUrl: getValue({
        small: ossImg('/home/home-poster-cn-2.mp4'),
        large: ossImg('/home/home-poster-cn-2.mp4'),
      })
    }
  ],
  about: {
    title: '关于万物新生集团',
    description: '万物新生成立于2011年，定位为“互联网+环保”类型的循环经济企业。万物新生集团旗下4大业务线包含：爱回收、拍机堂、拍拍、海外业务AHS Device。万物新生集团秉承“让闲置不用，都物尽其用”的使命，致力于打造ESG (即“环境、社会和治理”) 样本企业，将社会责任融入到商业实践中。',
    link: {
      name: '',
      url: ''
    },
    list: [
      {
        icon: require('../assets/home/en-us/mission.png'),
        name: '使命',
        subTitle: '让闲置不用，都物尽其用'
      },
      {
        icon: require('../assets/home/en-us/wish.png'),
        name: '愿景',
        subTitle: '成为全球二手行业的创新者和引领者'
      },
      {
        icon: require('../assets/home/en-us/position.png'),
        name: '定位',
        subTitle: '以二手供应链为基础的技术和服务型公司'
      }
    ]
  },
  channel: {
    title: '聚焦二手电子消费品市场',
    header: {
      description: '截至2021年底，国内处于流通状态的消费电子设备数量累计达到约27亿台，回收渗透率为4.2%（数据来源：灼识咨询）。二手行业存在高度分散、交易层级多、缺少标准等问题，需要通过长时间的技术投入和能力积累来解决。',
      keypoint: '以供应链能力为根基，拉通产业端到端覆盖，万物新生集团率先制定行业标准，打造行业基础设施。'
    },
    list: [
      {
        icon: require('../../assets/channel-icon-1.png'),
        name: {
          channel: '供给端：',
          description: '聚集分散的回收货源',
        },
        list: [
          {
            key: '用户端回收 — 爱回收',
            values: [
              '爱回收自营门店官网',
              '品牌厂商 与 电商合作伙伴'
            ]
          },
          {
            key: '商家端回收',
            values: [
              '二手商品中间商 与 数十万线下3C零售商',
            ]
          }
        ]
      },
      {
        icon: require('../../assets/channel-icon-2.png'),
        name: {
          channel: '处理端：',
          description: '标准化质检、定级、定价',
        },
        list: [
          { key: '区域级大型运营中心 8 个', },
          { key: '质检等级 36 个', },
          {
            key: '',
            values: [
              '数据截至 2023 年 12 月 31 日',
            ]
          }
        ]
      },
      {
        icon: require('../../assets/channel-icon-3.png'),
        name: {
          channel: '需求端：',
          description: '高效率的订单履约',
        },
        list: [
          {
            key: 'C端用户 / 优品货源 — 拍拍',
            values: [
              '京东商城 与 拍拍严选',
              '创新渠道，抖音、快手、爱回收严选门店',
            ]
          },
          {
            key: 'B端中小商家 / 普通货源 — 拍机堂',
            values: [
              '经销商、个体零售店、增值维修商、出口商',
            ]
          }
        ]
      }
    ]
  },
  difficult: {
    title: '十年探索，解决二手行业四大痛点',
    tabs: [
      {
        name: '缺乏安全便捷的 <br/> 回收渠道',
        description: '中国的二手手机3C行业已经是一个数千亿规模的市场，但回收渠道分散（主要在线下），产业链长、回收层级多导致加价率高。用户在回收过程中经常遇到欺诈、隐私泄露，对服务缺乏信任。',
        imgUrl: ossImg('/home/business-ahs.png'),
        product: {
          logo: require('../../assets/logo-ahs.png'),
          introduce: '爱回收，二手3C产品C2B回收平台 — 通过优质的换新场景获取货源，积累供应链能力。',
          services: [
            {
              icon: require('../../assets/ahs-advantage-1.png'),
              text: '便捷的回收渠道'
            },
            {
              icon: require('../../assets/ahs-advantage-2.png'),
              text: '消费者信赖的品牌'
            },
            {
              icon: require('../../assets/ahs-advantage-3.png'),
              text: '严格的隐私清除'
            }
          ]
        },
        more: {
          text: '了解更多',
          link: '/business/aihuishou'
        }
      },
      {
        name: '缺乏质检与定价的 <br/>统一行业标准',
        description: '中国有广泛的线下二手3C交易市场，第三方手机零售门店通过以旧换新收到大量二手手机，产业链中小商家有巨大的流通需求。但由于市场分散、交易层级多、缺乏行业标准，导致交易周转效率低，并且以次充好、欺诈等行为屡见不鲜。',
        imgUrl: ossImg('/home/business-paijitang.png'),
        product: {
          logo: require('../../assets/logo-paijitang.png'),
          introduce: '拍机堂，二手3C产品B2B交易平台 — 打造行业交易和服务的基础设施',
          services: [
            {
              icon: require('../../assets/pjt-advantage-1.png'),
              text: '定义行业标准'
            },
            {
              icon: require('../../assets/pjt-advantage-2.png'),
              text: '建立交易规范'
            },
            {
              icon: require('../../assets/pjt-advantage-3.png'),
              text: '提升买卖双方匹配效率'
            }
          ]
        },
        more: {
          text: '了解更多',
          link: '/business/paijitang'
        }
      },
      {
        name: '缺少可信赖的 <br/>二手商品购买渠道',
        description: '中国广大的中低线城市、在乎性价比的人群，对有质量保障的二手电子产品有旺盛的需求。万物新生借助积累的中心化质检定价能力，实现在回收获得一手货源后让优质的二手电子产品直达个人消费者，并提供完善的质量售后保障。',
        imgUrl: ossImg('/home/business-paipai.png'),
        product: {
          logo: require('../../assets/logo-paipai.png'),
          introduce: '拍拍，二手优品B2C零售平台 - 扩展到toC零售消费者，提供卓越的品质保障',
          services: [
            {
              icon: require('../../assets/paipai-advantage-1.png'),
              text: '优质的二手商品'
            },
            {
              icon: require('../../assets/paipai-advantage-2.png'),
              text: '专业质检，1年质保'
            },
            {
              icon: require('../../assets/paipai-advantage-3.png'),
              text: '7天无理由退货'
            }
          ]
        },
        more: {
          text: '了解更多',
          link: '/business/paipai'
        }
      },
      {
        name: '缺少自动化、高效率、<br/>高精度的质检技术',
        description: '在过去，行业参与者普遍依赖传统的人工质检，质检精准度低、运营成本高、货品周转效率低，无法实现规模效应。',
        imgUrl: ossImg('/home/business-youjian.png'),
        product: {
          logo: require('../../assets/logo-youjian.png'),
          introduce: '自研创新技术 — 自动质检能力持续升级',
          services: [
            {
              icon: require('../../assets/youjian-advantage-1.png'),
              text: '自研创新技术'
            },
            {
              icon: require('../../assets/youjian-advantage-2.png'),
              text: '标准化质检、定级、定价'
            },
            {
              icon: require('../../assets/youjian-advantage-3.png'),
              text: 'Matrix 3.0自动化能力再升级'
            }
          ]
        },
        more: {
          text: '了解更多',
          link: '/innovation'
        }
      }
    ]
  },
  news: {
    title: '发展历程',
    list: [
      {
        cover: ossImg('/home/2023.png'),
        title: '2023年，成为苹果官方“以旧换新”服务商，“多品类”与“合规翻新”战略快速推进',
        sections: [
          {
            header: '',
            description: '6月，爱回收正式登陆苹果中国内地的线上官网与线下官方旗舰店体系，成为苹果在中国内地第二家“以旧换新”服务商。'
          },
          {
            header: '',
            description: '同年，万物新生持续发力“多品类回收”和“合规翻新”战略。多品类战略的实行进一步夯实了回收场景对用户的吸引力，促进更多闲置商品的二次流通。“合规翻新”战略助力实现更完整的价值链和更大的增值利润空间。2023年以来，万物新生在全国主要仓内增加了合规翻新的运营能力。'
          }
        ]
      },
      {
        cover: ossImg('/home/2022.png'),
        title: '2022年，多品类业务收效，自动化能力升级',
        sections: [
          {
            header: '',
            description: '2022年，万物新生从2022年二季度开始重点推进“多品类”战略，即在核心的手机3C回收业务之外，在门店内增设影像器材、箱包、腕表、黄金、名酒、鞋服等品类的回收，同时提供以旧换新等增值服务，更好地满足用户的多种回收需求，并不断提升门店坪效。'
          },
          {
            header: '',
            description: '同年，万物新生集团规模最大、自动化程度最高的运营中心——华南（东莞）自动化运营中心正式投入使用。万物新生东莞自动化运营中心由自动输送分拣体系、自动质检体系、自动仓储体系三大模块组成，采用全流程数字化管理系统进行中央控制，承担输送、分拣、质检、仓储、发货等职能。'
          }
        ]
      },
      {
        cover: ossImg('/home/2021.png'),
        title: '2021年，确定海外平台战略',
        sections: [
          {
            header: '2021年万物新生正式确定以技术能力输出和产品输出为核心的出海战略。',
            description: '目前，海外业务AHS Device覆盖中国内地及中国香港、美国、日本、印度等世界主流二手数码自由贸易市场，投资了南美最大的手机回收公司Trocafone。'
          }
        ]
      },
      {
        cover: ossImg('/home/2020.png'),
        title: '2020年，集团品牌升级为“万物新生” 一体化平台打通',
        sections: [
          {
            header: '2020年9月22日，爱回收宣布“万物新生”将取代“爱回收”作为全新集团品牌，下辖爱回收、拍机堂、拍拍、海外业务AHS DEVICE。',
            description: '万物新生集团一体化平台实现打通。在供给端，公司有包括爱回收和京东带来的优质且稳定的货源；在处理端，万物新生具有自动化、智能化的质检定级定价能力，让产品质量更有保障；在销售端，B2B平台拍机堂让产品实现高速流转，平均周转周期在3天内（行业平均时间为10天），B2C平台拍拍让商品直达C端，减少中间环节，提高利润空间。'
          }
        ]
      },
      {
        cover: ossImg('/home/2019.png'),
        title: '2019年，与拍拍合并，打通C2B2C全产业链',
        sections: [
          {
            header: '2019年6月，“拍拍”并入“爱回收”集团。双方将借助此次合并优势互补，打通“C2B+B2B+B2C”全产业链，成为二手3C行业内率先打通全产业链端到端的闭环的企业。',
            description: ''
          },
        ]
      },
      {
        cover: ossImg('/home/2017.png'),
        title: '2017年，开始投入自动化研发，拍机堂正式上线',
        sections: [
          {
            header: '2017年，爱回收启动了对自动化运营体系的建设，并在常州打造了“非标二手电子产品自动化输送、质检、分拣和存储系统”。',
            description: '针对二手3C产品使用后非标准化的特点，万物新生集团自主研发了基于计算机视觉、针对手机细微划痕自动检测的“拍照盒子”系统；针对硬件产品功能自动插线和检测的“007”系统，以及不拆机即可拍照识别手机部件是否有更换和拆修的“X-Ray”系统，配合自动化流水线和机械立库，整条系统自动化率达90%，手机质检精准度达99%。改变了过往依赖密集型人工质检操作导致的效率较低、成本较高的行业状态。'
          },
          {
            header: '',
            description: '同年，拍机堂正式上线。爱回收将标准体系开放，为行业赋能，全面提升二手产业流通效率。拍机堂拥有爱回收多年积累的以质检分拣、定级定价为核心的供应链能力，并将这种能力开放给数十万中小商家。这也标志着爱回收从回收和以旧换新服务提供商拓展为具有平台服务能力的综合性平台。'
          }
        ]
      },
      {
        cover: ossImg('/home/2015.png'),
        title: '2015年，开创以旧换新合作',
        sections: [
          {
            header: '2015年，爱回收开始与京东等电商平台、小米等消费电子品牌开展以旧换新合作，旧机回收直接抵扣新机款，用户只需要支付差价即可购买新机。',
            description: ''
          }
        ]
      },
      {
        cover: ossImg('/home/2014.png'),
        title: '2014年，首次布局线下门店',
        sections: [
          {
            header: '2014年，爱回收陆续在各大城市的主要商场和购物中心布局线下门店。同年，爱回收获世界银行旗下IFC 800万美元B轮融资。',
            description: ''
          }
        ]
      },
      {
        cover: ossImg('/home/2011.png'),
        title: '2011年，首创二手3C回收模式',
        sections: [
          {
            header: '2011年，爱回收正式上线，爱回收专注于手机、笔记本等电子数码产品的回收业务。采用统一定价模式，将回收价格透明化。',
            description: ''
          }
        ]
      }
    ]
  },
  performance: {
    title: '2023 年业绩再创新高',
    list: [
      {
        firstLine: '集团总营收 129.7 亿元',
        lastLine: '同比增长 31.4%',
        keywords: {
          first: '129.7',
          last: ''
        }
      },
      {
        firstLine: '2023年全平台二手商品交易量 3,230 万台',
        lastLine: '突破历史新高',
        keywords: {
          first: '3,230',
          last: ''
        }
      }
    ]
  },
  publicWelfare: [
    {
      logo: require('../../assets/logo-hsa.png'),
      title: '美好学校河南数码助学爱心基金',
      description: '2021年7月，河南暴雨洪灾，给当地带来极大的损失，大量河南中小学校舍中的电子教学设备因泡水报废。万物新生集团捐赠505万元，和中国扶贫基金会共同成立“回收爱·美好学校河南数码助学基金”，为受灾中小学进行损坏电子教学设备的重新购置，让孩子们的教育不中断。',
      cover: ossImg('/home/publicWelfare-3.png'),
      more: {
        text: '了解更多',
        link: '/esg/responsibility'
      }
    },
    {
      logo: require('../../assets/logo-hsa.png'),
      title: '山区乡村儿童数字教育计划',
      description: '爱回收会在所有回收的设备中，筛选出部分适合山村儿童学习使用的设备进行捐赠，让孩子们通过这些数码产品，获得突破地域限制的优质网络教育资源，帮助他们看到更宽广的世界。截至2023年末，该计划累计支援60所学校搭建数字化教学平台，累计完成授课3032节，为11770名山村儿童提供更丰富的教育资源。',
      cover: ossImg('/home/publicWelfare-1.png'),
      more: {
        text: '了解更多',
        link: '/esg/responsibility'
      }
    },
    {
      logo: require('../../assets/logo-hsa.png'),
      title: '抗击疫情公益捐赠',
      description: '在2020 年COVID-19 新冠肺炎疫情全国性爆发之初，万物新生集团成立应急救援小组，迅速购置救援物质，守望相助。采买紧缺物资并定向向湖北省大冶市新建医院、第二人民医院和金牛镇金牛医院进行捐赠。总计捐赠一次性医用口罩12万个，84消毒水2000桶，医用检测手套15箱，全防护眼罩200副，一次性使用医用隔离衣1000套，N95口罩4万个。',
      cover: ossImg('/home/publicWelfare-2.png'),
      more: {
        text: '了解更多',
        link: '/esg/responsibility'
      }
    },
    {
      logo: require('../../assets/logo-hsa.png'),
      title: '故宫文化进山村',
      description: '万物新生集团携手故宫，将中国古典文化作品制成动画，通过线上课程带给山村儿童，让孩子们跨越时间和地域的阻隔，了解中国文化的源远流长。',
      cover: ossImg('/home/publicWelfare-4.png'),
      more: {
        text: '了解更多',
        link: '/esg/responsibility'
      }
    }
  ]
})

const US = () => ({
  title: 'Home',
  seo: {
    title: 'ATRenew Inc. - Official Site',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai',
    description: 'ATRenew is a leading technology-driven pre-owned consumer electronics transactions and services platform in China.',
  },
  banners: [
    {
      background: 'linear-gradient(180deg, #FDF557 0%, #F9E52C 100%)',
      buttonText: 'Watch the  video',
      imageUrl: getValue({
        small: ossImg('/home/banner-m-en.png'),
        large: ossImg('/home/banner-pc-en-1.png'),
      }),
      videoUrl: getValue({
        small: ossImg('/home/home-poster-en-2.mp4'),
        large: ossImg('/home/home-poster-en-2.mp4'),
      })
    }
  ],
  about: {
    title: 'Who are we?',
    description: 'Since its inception in 2011, ATRenew has positioned itself as a leader in the “Internet + environmental protection” circular economy. Our four key offerings are AHS Recycle, PJT Marketplace, Paipai Marketplace and AHS Device. Our mission to give a second life to all idle goods reflects our commitment to build an enterprise with ESG embedded in its genes. ',
    link: {
      name: '',
      url: ''
    },
    list: [
      {
        icon: require('../assets/home/en-us/mission.png'),
        name: 'Mission',
        subTitle: 'To give a second life to all idle goods'
      },
      {
        icon: require('../assets/home/en-us/wish.png'),
        name: 'Vision',
        subTitle: 'To   transform  pre-owned  consumer electronics transactions and services globally by  leveraging  technology  '
      },
      {
        icon: require('../assets/home/en-us/position.png'),
        name: 'Value Proposition',
        subTitle: 'An open platform driven by supply chain capabilities and technology'
      }
    ]
  },
  channel: {
    title: 'Pre-owned consumer electronics transactions and services',
    header: {
      description: 'According to a CIC report, by the end of 2021, the total number of devices in use for consumer electronics was 2.7 billion units. The overall penetration rate for pre-owned consumer electronics in China reached 4.2%.  ',
      keypoint: 'We embrace these opportunities in everything we do at  ATRenew. Our foundational supply chain capabilities  have enabled  us to set industry standards and establish  the infrastructure for the circular economy of pre-owned consumer electronics in China.'
    },
    list: [
      {
        icon: require('../../assets/channel-icon-1.png'),
        name: {
          channel: 'Aggregated  &  diverse supply',
          description: '',
        },
        list: [
          {
            key: 'Consumer sources – AHS Recycle',
            values: [
              'AHS store network, website, and app',
              'Brand and e-commerce partners  '
            ]
          },
          {
            key: 'Merchant sources',
            values: [
              'Third-party merchants     Electronics retailers  ',
            ]
          }
        ]
      },
      {
        icon: require('../../assets/channel-icon-2.png'),
        name: {
          channel: 'Standardized inspection, grading, and pricing',
          description: '',
        },
        list: [
          { key: '8 centralized operation centers' },
          { key: '36 grading tiers', },
          {
            key: '',
            values: [
              'As of December 31, 2023',
            ]
          }
        ]
      },
      {
        icon: require('../../assets/channel-icon-3.png'),
        name: {
          channel: 'Efficient demand fulfillment',
          description: '',
        },
        list: [
          {
            key: 'Paipai Marketplace offers premium pre-owned devices to consumers',
            values: [
              'JD.com and Paipai Selection ',
              'New distribution channels',
            ]
          },
          {
            key: 'PJT Marketplace is a B2B platform for pre-owned consumer electronics',
            values: [
              'Resellers,mom-and-pop stores,value-added service providers,exporters',
            ]
          }
        ]
      }
    ]
  },
  difficult: {
    title: 'Addressing four key challenges over a 10-year period',
    tabs: [
      {
        name: 'Lack of reliable and convenient recycling channels',
        description: 'China’s  pre-owned consumer electronics industry  is enormous and  fragmented. Scattered recycling channels, multiple intermediaries and high mark-up have contributed to fraud, weak data security and lack of  trust.',
        imgUrl: ossImg('/home/business-ahs.png'),
        product: {
          logo: require('../../assets/logo-ahs.png'),
          introduce: 'AHS Recycle  –  our C2B recycling and trade-in platform - aggregates supply from multiple consumer sources and accumulates supply chain capabilities,  improving convenience and consumer trust.  ',
          services: [
            {
              icon: require('../../assets/ahs-advantage-1.png'),
              text: 'Convenient access'
            },
            {
              icon: require('../../assets/ahs-advantage-2.png'),
              text: 'Trusted brand'
            },
            {
              icon: require('../../assets/ahs-advantage-3.png'),
              text: 'Stringent data policy'
            }
          ]
        },
        more: {
          text: 'Learn more',
          link: '/business/aihuishou'
        }
      },
      {
        name: 'Lack of industry standards for quality inspection and pricing',
        description: 'Given the massive pre-owned consumer electronics market,  retailers and  ,  small-to- and medium business owners in China  face  circulation  challenges.  A lack of standardization within the system leads to inefficient transactions and fraud.  ',
        imgUrl: ossImg('/home/business-paijitang.png'),
        product: {
          logo: require('../../assets/logo-paijitang.png'),
          introduce: 'PJT Marketplace  –  our B2B trading platform for pre-owned consumer electronics – establishes standardized  infrastructure for transactions and services,  creating a healthier  market environment.  ',
          services: [
            {
              icon: require('../../assets/pjt-advantage-1.png'),
              text: 'Defines industry standards'
            },
            {
              icon: require('../../assets/pjt-advantage-2.png'),
              text: 'Sets rules for transactions'
            },
            {
              icon: require('../../assets/pjt-advantage-3.png'),
              text: 'Improves transaction efficiency'
            }
          ]
        },
        more: {
          text: 'Learn more',
          link: '/business/paijitang'
        }
      },
      {
        name: 'Lack of trustworthy purchasing channels',
        description: 'Demand for quality and affordable pre-owned devices in  China is high.  ATRenew  leverages its proprietary inspection, grading and pricing technologies, as well as our quality guarantee and warranty,  to create trustworthy purchasing channels.  ',
        imgUrl: ossImg('/home/business-paipai.png'),
        product: {
          logo: require('../../assets/logo-paipai.png'),
          introduce: 'Paipai  Marketplace – our B2C retail platform for premium pre-owned consumer products – represents an extension of  retail businesses with  added premium quality assurance, improving customer trust and engagement.',
          services: [
            {
              icon: require('../../assets/paipai-advantage-1.png'),
              text: 'Premium pre-owned goods'
            },
            {
              icon: require('../../assets/paipai-advantage-2.png'),
              text: 'Professional inspection & 1-year warranty'
            },
            {
              icon: require('../../assets/paipai-advantage-3.png'),
              text: '7-day free return'
            }
          ]
        },
        more: {
          text: 'Learn more',
          link: '/business/paipai'
        }
      },
      {
        name: 'Low circulation efficiency due to a lack of automated quality inspection technology',
        description: 'Traditional manual quality inspections  are  inaccurate,  expensive,  inefficient,  and lack scalability.  ',
        imgUrl: ossImg('/home/business-youjian.png'),
        product: {
          logo: require('../../assets/logo-youjian.png'),
          introduce: 'Our proprietary technologies  –  automated inspection technology  -  reduces these inefficiencies and enables scalability.    ',
          services: [
            {
              icon: require('../../assets/youjian-advantage-1.png'),
              text: 'Innovative technologies'
            },
            {
              icon: require('../../assets/youjian-advantage-2.png'),
              text: 'Standardized inspection, grading and pricing'
            },
            {
              icon: require('../../assets/youjian-advantage-3.png'),
              text: 'Matrix 3.0, the upgraded automated technology'
            }
          ]
        },
        more: {
          text: 'Learn more',
          link: '/innovation'
        }
      }
    ]
  },
  news: {
    title: 'Our history',
    list: [
      {
        cover: ossImg('/home/2023.png') as any,
        title: "In 2023, we became an official partner of Apple's 'Trade-in' service and promoted multi-category recycling and compliant refurbishment strategies.",
        sections: [
          {
            header: '',
            description: "In June 2023, ATRenew became an official partner of Apple's 'Trade-in' service in Chinese mainland market, offering convenient recycling and low-cost upgrades for brand-new iPhones. The service is now available online and offline at official stores. "
          },
          {
            header: '',
            description: 'Additionally, our implementation of a multi-category strategy enhances the appeal of recycling scenarios to users, promoting the secondary circulation of more idle goods. Through a compliant refurbishment strategy, we have achieved a complete value chain and increased profit margins. We have expanded operational capabilities for compliant refurbishment in major warehouses nationwide since 2023.'
          },
        ]
      },
      {
        cover: ossImg('/home/2022.png') as any,
        title: 'In 2022, we explored multi-category recycling business and upgraded automation capabilities.',
        sections: [
          {
            header: '',
            description: 'In 2022, ATRenew accelerated the multi-category strategy since the second quarter of 2022. In addition to the core pre-owned electronic devices recycling business, ATRenew reused the system and capabilities of AHS stores to add new categories, including pre-owned photography and video equipment, luxury bags, watches, gold, high-value wine, footwear and clothing. Meanwhile, we better meet a more diverse demand of users by providing value-added services such as trade-in and further improve stores\' unit economics.'
          },
          {
            header: '',
            description: 'That same year, South China (Dongguan) Automation Operation Center, the largest and most advanced operation center of ATRenew, was officially put into use. The center is composed of three systems: automated transporting system, quality inspection system and warehousing system. It innovatively adopts digital management kits and undertakes the tasks of transportation, sorting, quality inspection, storage and delivery. '
          },
        ]
      },
      {
        cover: require('../../assets/news-1.png'),
        title: 'In 2021, we established our global platform strategy.',
        sections: [
          {
            header: 'In 2021, we set technological capability and product output as the core of our global platform strategy.  ',
            description: 'AHS Device  connects us with  global mainstream markets of pre-owned consumer electronics.  Furthering our reach, AHS Device invested in  Trocafone, the largest mobile phone recycling company in South America.  '
          }
        ]
      },
      {
        cover: require('../../assets/news-2.png'),
        title: 'In 2020, we completed  the open platform integration  and rebranded as “ATRenew”.',
        sections: [
          {
            header: 'On September 22, envisioning a future  of  “All Things Renew” we integrated  our  four core business offerings under “ATRenew.”  ATRenew  secures its supply from AHS Recycle and JD.com, then leverages its proprietary technologies to process and distribute  goods through either PJT Marketplace or  Paipai  Marketplace.',
            description: 'ATRenew  increases  transaction efficiency and margins for merchants and retailers  while also  increasing  consumer trust and engagement through our warranty and quality standards.'
          }
        ]
      },
      {
        cover: require('../../assets/news-3.png'),
        title: 'In 2019, we acquired  Paipai  Marketplace from JD Group.',
        sections: [
          {
            header: 'Following the merger, we formed an end-to-end value chain covering “C2B + B2B + B2C” offerings by extending our reach to individual shoppers.',
            description: ''
          }
        ]
      },
      {
        cover: require('../../assets/news-4.png'),
        title: 'In 2017, we began  investing in technology and launched PJT Marketplace.',
        sections: [
          {
            header: 'Our technological strength is defined by our sourcing,  inspection, grading and pricing technologies. These technologies have  been pioneered through in-house R&D since 2017. Our  automated exterior and hardware inspection, as well as grading and AI-backed pricing system,  were first innovated in our operation centers.',
            description: 'In late 2017, we launched PJT Marketplace, where our technology  enables small merchants and retailers to bid for pre-owned devices.'
          }
        ]
      },
      {
        cover: require('../../assets/news-5.png'),
        title: 'In 2015, we established  strategic partnerships.',
        sections: [
          {
            header: 'We partnered with e-commerce platforms including JD.com and consumer electronics brands like Xiaomi to attract trade-in customers to our offline stores.',
            description: ''
          }
        ]
      },
      {
        cover: require('../../assets/news-6.png'),
        title: 'In 2014, we established our offline presence.',
        sections: [
          {
            header: 'We opened self-operated AHS stores in popular shopping malls. We later opened a series of  jointly-operated AHS-branded partner  stores in select cities to further enhance our brand awareness, as well as our offline network and service capabilities.',
            description: ''
          }
        ]
      },
      {
        cover: require('../../assets/news-7.png'),
        title: 'In 2011, we began recycling pre-owned consumer electronics.',
        sections: [
          {
            header: 'Our operations  commenced as we procured pre-owned phones and other consumer electronics from consumers through AHS Recycle.',
            description: ''
          }
        ]
      }
    ]
  },
  performance: {
    title: 'Highlights in full year 2023',
    list: [
      {
        firstLine: 'Net revenues RMB 12.97 billion',
        lastLine: '+31.4% YoY',
        keywords: {
          first: '12.97',
          last: ''
        }
      },
      {
        firstLine: 'Transaction volume 32.3 million',
        lastLine: 'a new record high',
        keywords: {
          first: '32.3',
          last: ''
        }
      }
    ]
  },
  publicWelfare: [
    {
      logo: require('../../assets/logo-hsa.png'),
      title: 'AHS Digital Education Fund delivers support to schools in Henan province',
      description: 'In July 2021, schools and students in Henan province suffered from storms and floods. ATRenew established the “AHS Charity & Love · Digital Education Fund” in joint efforts with China Foundation for Poverty Alleviation and donated RMB5.05 million. In September, ATRenew delivered electronic teaching equipment in an orderly manner to replace the damaged equipment.',
      cover: ossImg('/home/publicWelfare-3.png'),
      more: {
        text: 'Learn more',
        link: '/esg/responsibility'
      }
    },
    {
      logo: require('../../assets/logo-hsa.png'),
      title: 'AHS Digital Education Program for children in rural areas',
      description: 'AHS continues to donate selected pre-owned consumer electronic devices to children in mountain villages, in order to support online education with high-quality resources that breakthrough geographical restrictions. As of December 31, 2023, AHS Digital Education Program funded a total of 60 schools, where 3032 sessions accumulatively were given to 11770 students.',
      cover: ossImg('/home/publicWelfare-1.png'),
      more: {
        text: 'Learn more',
        link: '/esg/responsibility'
      }
    },
    {
      logo: require('../../assets/logo-hsa.png'),
      title: 'Responses and donations to fight against the COVID-19 outbreak',
      description: 'At the beginning of the COVID-19 pandemic outbreak in China in 2020, ATRenew took immediate action to establish the anti-pandemic working group. ATRenew promptly purchased materials that were under short supply and shipped them to a newly-built hospital in Daye City, the Second People\'s Hospital and the Jinniu Hospital in Jinniu Town of Hubei Province. In total, ATRenew donated 120,000 disposable medical masks, 2,000 barrels of 84 disinfectants, 15 boxes of medical gloves, 200 pairs of protective goggles, 1,000 disposable quarantine gowns, and 40,000 N95 facial masks.',
      cover: ossImg('/home/publicWelfare-2.png'),
      more: {
        text: 'Learn more',
        link: '/esg/responsibility'
      }
    },
    {
      logo: require('../../assets/logo-hsa.png'),
      title: 'AHS Charity & Love’s partnership with the Palace Museum',
      description: 'In collaboration with the Beijing Palace Museum, ATRenew enlivened cultural relics of the Forbidden City by reproducing them into animations, to present historical stories and Chinese traditional culture to children in mountain villages. These animations bridge the gap for the children to vividly learn about Chinese history and cultural inheritance.',
      cover: ossImg('/home/publicWelfare-4.png'),
      more: {
        text: 'Learn more',
        link: '/esg/responsibility'
      }
    }
  ]
})

const data = {
  CN,
  US
}
export default data
