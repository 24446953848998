import React, {useCallback, useEffect, useMemo, useState} from 'react'
import { NavLink as Link } from 'react-router-dom'
import Breadcrumb from 'components/Breadcrumb/Index'
import Icon from 'components/Icon/Index'

import {useLocale} from '../../locales'
import classes from './Blog.module.scss'
import Seo from '../../components/Head/Seo'
import { isSmall, scrollIntoView } from 'utils/view'
import Section from 'pages/Home/components/Section'

export default function Blog() {
  const locale = useLocale()
  const [openedItem, setOpenedItem] = useState<typeof news[0] | null>(null)
  const blog = useMemo(
    () => locale.pages.Blog,
    [locale.pages.Blog]
  )
  const news = useMemo(() => blog.news, [blog.news])

  const toggle = useCallback(
    (item, index) => {
      if (item.year === openedItem?.year) {
        setOpenedItem(null)
        return
      }
      setOpenedItem(item)
      if (isSmall()) {
        scrollIntoView(`news_${index}`)
      }
    },
    [openedItem?.year]
  )

  useEffect(() => {
    if(news.length > 0) {
      setOpenedItem(news[0])
    }
  }, [news])

  return (
    <div className={classes.Blog}>
      <Seo seo={blog.seo} />
      <Breadcrumb title={blog.breadcrumb}/>
      <div className={classes.main}>
        <Section
          name={blog.pageTitle}
          border
          mainClassName={classes.mainContainer}
        >
          <div className={classes.list}>
            {
              news.map((ele, i) => (
                <div
                  className={classes.container}
                  key={ele.year}
                  id={`news_${i}`}
                >
                  <div
                    className={classes.header}
                    onClick={() => toggle(ele, i)}
                  >
                    <div className={classes.title}>
                      {ele.year}
                    </div>
                    <div className={classes.iconBox}>
                      <Icon icon={openedItem?.year === ele.year ? 'Reduce' : 'Add'} scale={0.8}/>
                    </div>
                  </div>
                  {
                    openedItem?.year === ele.year
                      ? (
                        <div className={classes.panelBox}>
                          <div className={classes.panel}>
                            <div className={classes.list}>
                              {
                                ele.list.map(item => (
                                  <div className={classes.item}>
                                    <div className={classes.title}>
                                      <Link to={item.url}>
                                        {item.title}
                                      </Link>
                                    </div>
                                    <div className={classes.description}>
                                      {item.description}
                                    </div>
                                    <div className={classes.date}>
                                      {item.date}
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      )
                      : null
                  }
                </div>
              ))
            }
          </div>
        </Section>
      </div>
    </div>
  )
}
