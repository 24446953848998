import React, {ReactNode} from 'react'
import classnames from 'classnames'

import classes from './Introduce.module.scss'

interface Props {
  data: {
    title: string
    description: string
    cover: string
    imagePosition: 'left' | 'right'
    bigImage: boolean | undefined
  }
  children: ReactNode
}

function Introduce(props: Props) {
  const {data, children} = props
  return (
    <div className={classes.Introduce}>
      <div className={classes.main}>
        <div className={classes.title}>
          {data.title}
        </div>
        <div className={classnames(
          classes.container,
          {[classes.reverse]: data.imagePosition === 'left'}
        )}>
          <div className={classnames(
            classes.detail,
            {[classes.bigImage]: data.bigImage}
          )}>
            <div className={classes.description}>
              {data.description}
            </div>
            {children}
          </div>
          <div className={classnames(
            classes.cover,
            {[classes.big]: data.bigImage}
          )}>
            <img
              src={data.cover}
              alt={data.title}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Introduce
