import React from 'react'
import classNames from 'classnames'
import Separate from 'assets/icon-dot.png'

import classes from './Index.module.scss'


function LineSeparate(props: {
  className?: string
}) {
  const { className } = props
  return (
    <div className={classNames(classes.Main, className)}>
      <img src={Separate} alt='LineSeparate' />
    </div>
  )
}

export default LineSeparate
