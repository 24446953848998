import React, {useMemo} from 'react'
import {useLocale} from '../../locales'
import Seo from '../../components/Head/Seo'

import Breadcrumb from 'components/Breadcrumb/Index'
import Banner from './components/Banner'
import About from './components/About'
import ESG from './components/ESG'
import Benefit from './components/Benefit'
import Issue from './components/Issue'

import classes from './Overall.module.scss'
export default function Overall() {
  const locale = useLocale()
  const overall = useMemo(
    () => locale.pages.ESG.overall,
    [locale.pages.ESG.overall]
  )

  return (
    <div className={classes.Ahs}>
      <Seo seo={locale.pages.ESG.overall.seo} />
      <Banner />
      <Breadcrumb title={overall.breadcrumb} />
      <About />
      <ESG />
      <Benefit />
      <Issue />
    </div>
  )
}
