import React from 'react'
import Main from 'components/Main/Index'

import classes from './Index.module.scss'
import classNames from 'classnames'

interface Props {
  title: string
  className?: string
}
export default function Index(props: Props) {
  const { className } = props
  return (
    <Main>
      <div className={classNames(classes.Breadcrumb, className, 'hidden-small')}>
        {props.title}
      </div>
    </Main>
  )
}
