import React, { ReactNode } from 'react'

import classes from './Main.module.scss'

interface Props {
  children: ReactNode
}
function Main(props: Props) {

  return (
    <div className={classes.Main}>
      { props.children }
    </div>
  )
}

export default Main
