
import React from 'react'

import Icon from 'components/Icon/Index'

import classes from './Index.module.scss'

interface VideoProps {
  url: string
  visible: boolean
  onClose: () => void
}

function Video(props: VideoProps) {
  const {
    url,
    visible,
    onClose
  } = props

  if (!visible) return null
  return (
    <div className={classes.video}>
      <div className={classes.videoBox}>
        <video src={url} controls autoPlay />
        <div className={classes.close}>
          <Icon
            icon='Close'
            onClick={onClose}
            scale={1}
            color='#fff'
          />
        </div>
      </div>
    </div>
  )
}

export default Video