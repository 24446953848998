import React, {useMemo, useState} from 'react'
import {useLocale} from '../../../locales'
import { Link } from 'react-router-dom'
import SwiperInstance from '../../../components/Swiper/Index'
import Dots from '../../../components/Dots/Index'

import classes from './PublicWelfare.module.scss'

function PublicWelfare() {
  const locale = useLocale()
  const [ swiperIndex, setSwiperIndex ] = useState(0)
  const publicWelfare = useMemo(
    () => locale.pages.Home.publicWelfare,
    [locale.pages.Home.publicWelfare]
  )
  return (
    <div className={classes.PublicWelfare}>
      <SwiperInstance
        onChange={(index) => setSwiperIndex(index)}
      >
        {
          publicWelfare.map((ele) => (
            <div className={classes.main} key={ele.title}>
              <div className={classes.info}>
                <img
                  className={classes.logo}
                  src={ele.logo}
                  alt={ele.title}
                />
                <div className={classes.title}>
                  {ele.title}
                </div>
                <div className={classes.description}>
                  {ele.description}
                </div>
                <div className={classes.dots}>
                  <Dots total={publicWelfare.length} activeIndex={swiperIndex}/>
                </div>
                <Link
                  className={classes.more}
                  to={ele.more.link}
                >
                  {ele.more.text}
                </Link>
              </div>
              <img
                className={classes.cover}
                src={ele.cover}
                alt={ele.title}
              />
            </div>
          ))
        }
      </SwiperInstance>
    </div>
  )
}

export default PublicWelfare
