import React, { ReactNode } from 'react'
import classNames from 'classnames'

import classes from './Index.module.scss'

interface SectionProps {
  name?: string
  extra?: ReactNode
  border?: boolean
  hideBottomLine?: boolean
  header?: ReactNode
  children?: ReactNode
  className?: string
  mainClassName?: string
}

function Section(props: SectionProps) {
  const {
    name = '',
    extra,
    header,
    border = false,
    children,
    className = '',
    mainClassName = '',
    hideBottomLine = false
  } = props

  return (
    <div className={classNames(
      classes.Section,
      { [classes.showBottomLine]: !hideBottomLine },
      className,
    )}>
      {
        name || extra
          ? (
            <div className={classes.name}>
              {name
                ? (
                  <p className={classes.nameContent}>
                    {name}
                  </p>
                )
                : null}
              {extra
                ? (
                  <div className={classes.nameExtra}>
                    {extra}
                  </div>
                )
                : null}
            </div>
          )
          : null
      }
      <div className={classNames(
        classes.main,
        mainClassName,
        { [classes.border]: border }
      )}>
        <div>
          {header}
        </div>
        {children}
      </div>
    </div>
  )
}

interface FullProps {
  background?: string
  name?: string
  children?: ReactNode
}

function Full(props: FullProps) {
  const {
    background,
    name,
    children,
  } = props
  return (
    <div className={classes.sectionFull}>
      {
        name
          ? (
            <div className={classes.name}>
              {name}
            </div>
          )
          : null
      }
      <div
        className={classes.main}
        style={
          { background }
        }
      >
        <div className={classes.container}>
          {children}
        </div>
      </div>
    </div>
  )
}

Section.Full = Full

export default Section

