const pcMaxWidth = 1200
const mobileMaxWidth = 768

// eslint-disable-next-line no-shadow
export enum ScreenType {
  Large = 0,
  Small = 1
}

export function logicWidth() {
  return document.documentElement.clientWidth || document.body.clientWidth
}

export function isLarge() {
  return logicWidth() >= mobileMaxWidth
}

export function isSmall() {
  return logicWidth() <= mobileMaxWidth
}

const scale = 100

export function Px2rem(px?: number) {
  if (!px) return ''
  return `${(px / scale).toFixed(2)}rem`
}

export function getContentWidth() {
  if (isSmall()) {
    return logicWidth()
  }
  return logicWidth() > pcMaxWidth ? pcMaxWidth : logicWidth()
}

/**
 * 根据设备分辨率取值
 * @param values
 * @returns T
 */
export function getValue<T = string>(values: {
  /** 大尺寸设备(PC模式) 的值 */
  large: T
  /** 小尺寸设备(H5模式) 的值 */
  small: T
}) {
  if (isSmall()) {
    return values.small
  }
  return values.large
}

export function scrollIntoView(elementId: string) {
  const element = document.getElementById(elementId)
  if (!element) return
  setTimeout(
    () => element.scrollIntoView({ behavior: 'smooth' }),
    70
  )
}
