import React, { useMemo } from 'react'
import classnames from 'classnames'

import classes from './Business.module.scss'
import {useLocale} from '../../../../locales'

const separate = require('../../../../locales/assets/business/Paijitang/icon-group-2.1.png')
function Business() {
  const locale = useLocale()
  const business = useMemo(
    () => locale.pages.Business.Paijitang.business,
    [locale.pages.Business.Paijitang.business]
  )

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {business.sectionName}
      </div>
      <div className={classnames(classes.business)}>
        <div className={classes.main}>
          <div className={classes.left}>
            <div className={classes.channel}>
              {business.left.channel}
            </div>
            {
              business.left.sections.map(ele => (
                <div className={classes.section}>
                  <div className={classes.name}>
                    { ele.title }
                  </div>
                  <div className={classes.description}>
                    {ele.description}
                  </div>
                </div>
              ))
            }
          </div>
          <img
            className={classes.separate}
            src={separate}
            alt=''
          />
          <div className={classes.right}>
            <div className={classes.channel}>
              {business.right.channel}
            </div>
            {
              business.right.sections.map(ele => (
                <div className={classes.section}>
                  <div className={classes.name}>
                    { ele.title }
                  </div>
                  <div className={classes.description}>
                    {ele.description}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>

  )
}

export default Business
