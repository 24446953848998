import { useEffect, useMemo } from 'react'
import { debounce } from 'underscore'

import useScreen from 'utils/screen'
import { getContentWidth } from 'utils/view'

export function useWindowResize(callback: () => void) {
  const handler = useMemo(() => debounce(() => {
    callback()
  }, 160), [callback])

  useEffect(() => {
    window.addEventListener('resize', handler, false)
    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [handler])
}

export function useMainWidth() {
  const screen = useScreen()
  if (screen.isSmall) {
    return screen.width
  }
  return getContentWidth()
}